import React, { FC, ReactNode } from 'react';

import { Main } from './styles';
import { Color } from '~/styles';

interface IProps {
  title?: string;
  label: string;
  children?: ReactNode;
}

const InfoBox: FC<IProps> = ({ title, label, children }) => {
  return (
    <Main>
      <div>
        <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="44" height="44" rx="8" fill={Color.blueIntense} />
          <path
            d="M22 18.6667V18.6678M22 22V26.4444M32 22C32 27.5228 27.5228 32 22 32C16.4772 32 12 27.5228 12 22C12 16.4772 16.4772 12 22 12C27.5228 12 32 16.4772 32 22Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      </div>
      <div>
        <p>{title || 'Lembre-se'}</p>
        <p>
          {label}
          {children && children}
        </p>
      </div>
    </Main>
  );
};

export default InfoBox;
