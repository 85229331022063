/* eslint-disable consistent-return */
/* eslint-disable no-alert */
/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';

import configsAppJsonImport from '~/configs/app.config';

import { Input } from '~/components/Input';
import { IState } from '~/store/modules/types';
import formatter from '~/utils/formatter';
import Navigation from '~/utils/Navigation';
import {
  Main,
  ContainerTermsStyled,
  TextTerm,
  Wrapper,
  NavWrap,
  TextInfo,
} from './styles';
import {
  getCloseErrorEnrollmentsUsersValidate,
  getEnrollmentsUsersRequestClear,
  getEnrollmentsUsersValidateRequest,
} from '~/store/modules/enrollmentsUsers/actions';
import { Card } from '~/common/Card';
import { checkCnp } from '~/utils/validation';
import { Button, ButtonLink, MessageBox } from '~/common';
import { Link } from 'react-router-dom';
import { RouteName } from '~/routes/Routes.name';
import { history } from '~/store';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '~/styles';
import { Margin } from '~/components/Margin';
import Checkbox from '~/components/CheckboxIcon';
import { showRecaptchaV2 } from '~/utils/showRecaptcha';
import { Recaptcha } from '~/components/Recaptcha';

const configsAppJson = configsAppJsonImport as any;

export interface IDataRegister {
  clientType?: 'client' | 'notClient';
  cnp?: string;
  phone?: string;
  email?: string;
  systemTerms?: boolean;
  productTerms?: boolean;
  method?: 'sms' | 'email';
  token?: string;
  name?: string;
  documentType?: 'CPF' | 'CNPJ';
  telephone?: string;
}

export const RegisterClient: React.FC = () => {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dataRegister = history.location.state as IDataRegister;

  const loading = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingValidateRegister,
  );
  const error = useSelector(
    (item: IState) => item.enrollmentsUsers.errorValidateRegister,
  );

  const [cnp, setCnp] = useState<string>(
    dataRegister && dataRegister?.cnp ? dataRegister.cnp : '',
  );
  const [cnpError, setCnpError] = useState<string>('');
  const [phone, setPhone] = useState<string>(
    dataRegister && dataRegister?.phone ? dataRegister.phone : '',
  );
  const [phoneError, setPhoneError] = useState<string>('');
  const [email, setEmail] = useState<string>(
    dataRegister && dataRegister?.email ? dataRegister.email : '',
  );
  const [errorEmail, setErrorEmail] = useState<string>('');

  const [systemTerms, setSystemTerms] = useState<boolean>(
    dataRegister && dataRegister?.systemTerms ? dataRegister.systemTerms : false,
  );
  const [productTerms, setProductTerms] = useState<boolean>(
    dataRegister && dataRegister?.productTerms ? dataRegister.productTerms : false,
  );
  const [enableSubmit, setEnableSubmit] = useState<boolean>(
    configsAppJson?.NODE_ENV !== 'PRD',
  );
  const [disabled, setDisabled] = useState<boolean>(true);

  const [userExists, setUserExists] = useState<boolean>(false);
  const [recaptchaTokenV3, setRecaptchaTokenV3] = useState<string | null>(null);
  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState<string | null>(null);
  const showRecaptcha = showRecaptchaV2(error || undefined);

  const clearError = () => {
    dispatch(getCloseErrorEnrollmentsUsersValidate());
  };

  useEffect(() => {
    setUserExists(false);
    dispatch(getEnrollmentsUsersRequestClear());
    return () => clearError();
  }, []);

  useEffect(() => {
    if (
      cnp &&
      !cnpError &&
      !errorEmail &&
      systemTerms &&
      productTerms &&
      enableSubmit &&
      !phoneError &&
      phone
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    cnp,
    cnpError,
    phone,
    errorEmail,
    email,
    systemTerms,
    productTerms,
    enableSubmit,
    phoneError,
  ]);

  const validatePhone = () => {
    if (!phone) return setPhoneError('Celular inválido');

    if (phone.length < 15) {
      return setPhoneError('Celular inválido');
    }

    setErrorEmail('');
    return setPhoneError('');
  };

  useEffect(() => {
    if (error && error.message === 'USER_ALREADY_EXISTS') {
      setUserExists(true);
    } else {
      setUserExists(false);
    }
  }, [error]);

  return (
    <Main>
      <Recaptcha
        setRecaptchaTokenV3={setRecaptchaTokenV3}
        setRecaptchaTokenV2={setRecaptchaTokenV2}
        showRecaptchaV2={showRecaptcha}
        onClose={clearError}
      />
      <Card
        title="CRIAR SEU CADASTRO"
        label="Acesse nossos serviços de forma fácil e tenha uma experiência personalizada!"
      >
        <Wrapper>
          {userExists && (
            <>
              <MessageBox title="Atenção!" variant="warning" icon="warning">
                <Margin height={4} />
                Olá cliente! Identificamos que já possui cadastro,{' '}
                <Link to="/login">faça seu login!</Link>
              </MessageBox>
              <Margin height={24} />
            </>
          )}
          <Input
            value={cnp}
            onChange={(ev) => {
              setCnp(formatter.cpfCnpjMask(ev.target.value));

              if (!ev.target.value) return setCnpError('');

              const cnpIsvalid = checkCnp(ev.target.value);

              if (!cnpIsvalid) return setCnpError('CPF ou CNPJ inválido.');

              return setCnpError('');
            }}
            label="CPF ou CNPJ*"
            name="cnp"
            error={!!cnpError}
            errorText={cnpError}
            errorPosition="bottom"
            styleLabel={{ fontSize: '19px', fontWeight: 500 }}
          />
          <Margin height={24} />
          <Input
            value={phone}
            onChange={(ev) => setPhone(formatter.formatarPhone(ev.target.value))}
            onBlur={validatePhone}
            label="Celular*"
            name="cnp"
            error={!!phoneError}
            errorText={phoneError}
            errorPosition="bottom"
            styleLabel={{ fontSize: '19px', fontWeight: 500 }}
          />
          <Margin height={24} />
          <Input
            value={email}
            onChange={(ev) => {
              if (ev.target.value) {
                if (formatter.rejexEmail(ev.target.value)) {
                  setErrorEmail('');
                  setEmail(ev.target.value);
                } else {
                  setErrorEmail('Email inválido');
                  setEmail(ev.target.value);
                }
              } else {
                setEmail('');
                setErrorEmail('');
              }
            }}
            label="Email"
            name="email"
            error={!!errorEmail}
            errorText={errorEmail}
            errorPosition="bottom"
            styleLabel={{ fontSize: '19px', fontWeight: 500 }}
          />
          <Margin height={24} />

          <ContainerTermsStyled>
            <Checkbox
              checked={systemTerms}
              onChange={(e) => {
                setSystemTerms(e);
              }}
              style={{ marginTop: '2px' }}
            />
            <Margin width={10} />
            <TextTerm>
              Li o{' '}
              <a href="/trm1.pdf" target="_blank" rel="noreferrer">
                Termo de Adesão
              </a>{' '}
              para utilização dos sistemas do Grupo Iguá
            </TextTerm>
          </ContainerTermsStyled>

          <Margin height={10} />

          <ContainerTermsStyled>
            <Checkbox
              checked={productTerms}
              onChange={(e) => {
                setProductTerms(e);
              }}
              style={{ marginTop: '2px' }}
            />
            <Margin width={10} />
            <TextTerm>
              Li o{' '}
              <a href="/trm2.pdf" target="_blank" rel="noreferrer">
                Termo de Adesão
              </a>{' '}
              para oferecimento de produtos e serviços
            </TextTerm>
          </ContainerTermsStyled>

          {configsAppJson?.NODE_ENV === 'PRD' && (
            <>
              <Margin height={24} />
              <div
                style={{
                  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                  margin: 'auto',
                  width: 'min-content',
                }}
              >
                <ReCAPTCHA
                  sitekey={
                    configsAppJson?.GOOGLE_RECAPTCHA[configsAppJson?.NODE_ENV]
                  }
                  onErrored={() => setEnableSubmit(false)}
                  onExpired={() => setEnableSubmit(false)}
                  onChange={(j) => setEnableSubmit(!!j)}
                  size="normal"
                />
              </div>
            </>
          )}
          <Margin height={isMobile ? 12 : 24} />

          <NavWrap>
            <Button
              variant="primary"
              disabled={disabled}
              onClick={() => {
                if (loading || disabled) return;
                if (!phone && !email) {
                  const messageErrorOptional =
                    'Preencha ao menos um dos dados de contato ';

                  setPhoneError(messageErrorOptional);
                  setErrorEmail(messageErrorOptional);
                  return;
                }

                dispatch(
                  getEnrollmentsUsersValidateRequest(
                    {
                      cnp,
                      recaptchaTokenV3: recaptchaTokenV3 || '',
                      recaptchaTokenV2: recaptchaTokenV2 || '',
                    },
                    {
                      cnp,
                      phone,
                      email,
                      systemTerms: true,
                      productTerms: true,
                      clientType: dataRegister?.clientType || '',
                    },
                  ),
                );
              }}
              loading={loading}
            >
              Continuar
            </Button>
            <ButtonLink
              type="button"
              color="gray"
              onClick={() => Navigation.navigate(RouteName.REGISTERPROFILE, { cnp })}
            >
              Voltar
            </ButtonLink>
          </NavWrap>
        </Wrapper>
      </Card>
    </Main>
  );
};
