/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
import { call, put, select } from 'redux-saga/effects';
import {
  createSupplyPossibilityConsultationCase,
  createSupplyPossibilityConsultationCharacteristics,
  updateSupplyPossibilityConsultationCase,
  getSupplyPossibilityConsultationCase,
  downloadSlipService,
  getListCases,
  getListSlips,
  getListStatuses,
  getOperationsService,
  getSlip,
} from '~/services/viability';
import { RouteName } from '~/routes/Routes.name';
import Navigation from '~/utils/Navigation';
import { IFieldObject } from '../dpadpeDataMenager/types';
import { IState } from '../types';

import {
  registerCaseSuccess,
  registerCaseFailure,
  listCasesSuccess,
  listCasesFailure,
  IDataCase,
  setSelectedCaseSuccess,
  listOperationsSucess,
  listOperationsFailure,
  listSlipsSuccess,
  listSlipsFailure,
  getSlipsSuccess,
  getSlipsFailure,
  downloadSlipSuccess,
  downloadSlipFailure,
  listStatusesSuccess,
  listStatusesFailure,
} from './actions';
import {
  IOperations,
  IResponseGetSlips,
  IResponseListCases,
  IResponseListSlips,
  IResponseListStatuses,
} from './types';
import formatter from '~/utils/formatter';
import onShareFile from '~/utils/onShareFile';

export function* listCases(action: any): any {
  try {
    const { cpae, oc, fcp, page, limit, protocol, status } = action.payload;
    const request: IResponseListCases[] = yield call(getListCases, {
      cpae,
      oc,
      fcp,
      page,
      limit,
      protocol,
      status,
    });
    yield put(listCasesSuccess(request));
  } catch (err: any) {
    console.log(err);
    yield put(listCasesFailure());
  }
}

export function* listSlips(action: any): any {
  try {
    const { cancelled, pending, paid, page, limit, protocol } = action.payload;
    const request: IResponseListSlips[] = yield call(getListSlips, {
      cancelled,
      pending,
      paid,
      page,
      limit,
      protocol,
    });
    yield put(listSlipsSuccess(request));
  } catch (err: any) {
    console.log(err);
    yield put(listSlipsFailure());
  }
}

export function* getSlips(action: any): any {
  try {
    const { slipId } = action.payload;
    const request: IResponseGetSlips = yield call(getSlip, { slipId });
    yield put(getSlipsSuccess(request));
  } catch (err: any) {
    console.log(err);
    yield put(getSlipsFailure());
  }
}

export function* downloadSlip(action: any): any {
  try {
    const { slipId } = action.payload;
    const response = yield call(downloadSlipService, { slipId });

    const anchorElement = document.createElement('a');

    document.body.appendChild(anchorElement);

    anchorElement.style.display = 'none';

    const blob = new Blob([response], {
      type: 'application/pdf',
    });

    const url = window.URL.createObjectURL(blob);

    anchorElement.href = url;
    anchorElement.download = `Boleto-${slipId}`;
    anchorElement.click();

    window.URL.revokeObjectURL(url);

    yield put(downloadSlipSuccess());
  } catch (err: any) {
    yield put(downloadSlipFailure());
  }
}

export function* getCases(action: any): any {
  try {
    const { id } = action.payload;
    const request: IDataCase = yield call(getSupplyPossibilityConsultationCase, id);
    yield put(setSelectedCaseSuccess(request));
  } catch (err: any) {
    console.log(err);
    yield put(listCasesFailure());
  }
}

export function* getOperations(): any {
  try {
    const request = yield call(getOperationsService);
    const data = request.map((e: IOperations) => {
      const processTypes = e.processTypes.map((e) => ({ ...e, value: e.name }));
      return { ...e, value: e.name, processTypes };
    });
    yield put(listOperationsSucess(data));
  } catch (err: any) {
    console.log(err);
    yield put(
      listOperationsFailure({
        message: err?.message,
        status: err?.status,
      }),
    );
  }
}

export function* registerViability(action: any): any {
  const { data } = action.payload;

  const initialCharacter = {
    lots_quantity: '',
    lot_area: '',
    number_unities_by_lot: '',
    number_unities_by_building: '',
    number_stores: '',
    number_commercial_rooms: '',
    quantity_edifications: '',
    built_area: '',
    number_rooms_unity: '',
    dependecies: '',
    quantity_apartments_unity: '',
    type: '',
    quantity: '',
    number_rooms: '',
    quantity_apartments: '',
    business_structure: '',
    industrial_process_predicted_discharge: '',
    industrial_process_predicted_consume: '',
    number_employees_day: '',
    business_structure_enterprise: '',
    number_consumers_day: '',
  };

  try {
    const caseValues = yield select(
      (state: IState) => state.dpadpeDataMenager.caseValues,
    );

    const valuesObject = yield select(
      (state: IState) => state.dpadpeDataMenager.ValuesObject,
    );

    const type = yield select((state: IState) => state.dpadpeDataMenager.type);

    const requestCase = yield call(createSupplyPossibilityConsultationCase, {
      ...data,
    });

    const array: Array<any> = [];

    valuesObject.map((character: any) => {
      let object = {};

      if (type === 'object') {
        character.map((characterItem: IFieldObject) => {
          const key = characterItem.key.replace(/[0-9]/g, '');
          object = {
            ...object,
            [key]: characterItem.value,
          };
        });

        return array.push({
          ...initialCharacter,
          ...object,
          name: caseValues.characteristic_type,
          characteristic_type: caseValues.characteristic_type,
        });
      }
      character.fields.map((characterItem: any) => {
        characterItem.map((characterItemObject: any) => {
          const key = characterItemObject.key.replace(/[0-9]/g, '');
          return (object = {
            ...object,
            [key]: characterItemObject.value,
          });
        });

        return array.push({
          ...initialCharacter,
          ...object,
          name: caseValues.characteristic_type,
          characteristic_type: caseValues.characteristic_type,
        });
      });

      return array;
    });

    yield call(
      createSupplyPossibilityConsultationCharacteristics,
      array,
      requestCase.case_id,
    );

    yield put(registerCaseSuccess());

    Navigation.navigate(RouteName.ENTERPRISE_VIABILITY);
  } catch (err: any) {
    yield put(
      registerCaseFailure({
        message: err?.message,
        status: err?.status,
      }),
    );
  }
}

export function* updateViability(action: any): any {
  const { data } = action.payload;

  try {
    const dataCase = {
      data: {
        enterprise: {
          owner: data?.enterprise?.owner || '',
          email: data?.enterprise?.email || '',
          cpf_cnpj: formatter.formatarNumber(data?.enterprise?.cpf_cnpj) || '',
          cep: formatter.formatarNumber(data?.enterprise?.cep) || '',
          street: data?.enterprise?.street || '',
          neighborhood: data?.enterprise?.neighborhood || '',
          number: data?.enterprise?.number || '',
          complement: data?.enterprise?.complement || '',
          city: data?.enterprise?.city || '',
          state: data?.enterprise?.state || '',
        },
        interested: {
          name: data?.interested?.name || '',
          qualification: data?.interested?.qualification || '',
          address: data?.interested?.address || '',
          email: data?.interested?.email || '',
          phone: formatter.formatarNumber(data?.interested?.phone) || '',
        },
      },
      files: {
        identification_document_file: data?.files?.identification_document_file,
        location_plan_file: data?.files?.location_plan_file,
        situation_plan_file: data?.files?.situation_plan_file,
      },
    };

    const toggles = yield select((item: IState) => item.togglesEnrollment.data);

    const requestCase = yield call(
      updateSupplyPossibilityConsultationCase,
      { ...data },
      data.case_id,
    );

    yield put(registerCaseSuccess());

    Navigation.navigate(RouteName.ENTERPRISE_VIABILITY);
  } catch (err: any) {
    yield put(
      registerCaseFailure({
        message: err?.message,
        status: err?.status,
      }),
    );
  }
}

export function* listStatuses(): any {
  try {
    const response: IResponseListStatuses[] = yield call(getListStatuses);
    yield put(listStatusesSuccess(response));
  } catch (err: any) {
    console.log(err);
    yield put(listStatusesFailure());
  }
}
