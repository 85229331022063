/* eslint-disable prefer-const */
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '~/components/Input';
import { InputSelect } from '~/components/InputSelect';
import { Margin } from '~/components/Margin';
import {
  clearDataCreateCustomerObligation,
  clearDataValidateCustomerObligation,
  getErrorCloseValidateCustomerObligationRequest,
  getValidateCustomerObligationRequest,
  postCreateCustomerObligationRequest,
  postErrorCloseCreateCustomerObligationRequest,
} from '~/store/modules/customerObligation/actions';
import { IState } from '~/store/modules/types';
import formatter from '~/utils/formatter';
import StatusInfo from '../../components/StatusInfo';

import { BoxInfos, BoxInputs, Main } from './styles';
import { ContainerButton, Subtitle } from '../../styles';
import { Button } from '~/components/ButtonV3';
import { Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

interface IProps {
  setActualStep: (e: 1 | 2 | 3 | 4 | 5) => void;
}

const ProcessIdentification: FC<IProps> = ({ setActualStep }) => {
  const dispatch = useDispatch();

  const caseOC = useSelector((item: IState) => item.customerObligation.dataItem);
  const data = useSelector((item: IState) => item.customerObligation.data);
  const idCreate = useSelector((item: IState) => item.customerObligation.idCreate);
  const errorValidate = useSelector((item: IState) => item.customerObligation.error);
  const errorCreate = useSelector(
    (item: IState) => item.customerObligation.errorCreate,
  );
  const loadingCreate = useSelector(
    (item: IState) => item.customerObligation.loadingCreate,
  );
  const loadingValidate = useSelector(
    (item: IState) => item.customerObligation.loading,
  );
  const isRevision = caseOC?.attachments.find(
    (attachment) => attachment.review === true,
  );
  const successCreate = useSelector(
    (item: IState) => item.customerObligation.successCreate,
  );

  const [typesWork, setTypesWork] = useState<Array<string>>([]);
  const [numberCpae, setNumberCpae] = useState('');
  const [typeCustomerObligation, setTypeCustomerObligation] = useState<
    'oca' | 'oce'
  >();

  useEffect(() => {
    errorValidate && dispatch(getErrorCloseValidateCustomerObligationRequest());
    errorCreate && dispatch(postErrorCloseCreateCustomerObligationRequest());
  }, []);
  useEffect(() => {
    if (successCreate) {
      setActualStep(2);
      dispatch(clearDataCreateCustomerObligation());
    }
  }, [successCreate]);

  useEffect(() => {
    if (data) {
      let array: Array<any> = [];

      if (data.oca) array.push({ value: 'oca', label: 'Água' });
      if (data.oce) array.push({ value: 'oce', label: 'Esgoto' });

      setTypeCustomerObligation(array[0].value);
      setTypesWork(array);
    } else {
      setTypesWork([]);
    }
  }, [data]);

  return (
    <>
      {!isRevision && (
        <>
          <Subtitle>
            Preencha e confira as informações abaixo para dar início ao processo.
          </Subtitle>
          <Margin height={64} />
        </>
      )}
      <Main>
        <BoxInputs>
          <Input
            styleInput={{ width: `40%` }}
            label="Número CPAE"
            value={numberCpae}
            onChange={(ev: any) => {
              setNumberCpae(formatter.formatarNumber(ev.target.value) || '');
              if (ev.target.value.length === 8) {
                dispatch(getErrorCloseValidateCustomerObligationRequest());
                dispatch(postErrorCloseCreateCustomerObligationRequest());
                dispatch(
                  getValidateCustomerObligationRequest({ case: ev.target.value }),
                );
              } else {
                dispatch(clearDataValidateCustomerObligation());
              }
            }}
            error={!!(errorValidate && errorValidate.message === 'CASE_NOT_FOUND')}
            errorText={
              errorValidate && errorValidate.message === 'CASE_NOT_FOUND'
                ? 'Não encontrado'
                : ''
            }
            errorPosition="bottom"
            maxLength={8}
          />
          <Margin width={40} />
          <InputSelect
            styleInput={{ width: `30%` }}
            label="Tipo de obra"
            values={typesWork}
            disabled={typesWork.length < 1}
            onChange={(ev: any) => setTypeCustomerObligation(ev.target.value)}
          />
        </BoxInputs>
        {data && !errorValidate && !errorCreate && (
          <BoxInfos>
            <p>
              NÚMERO DA CPAE: <span>{data?.case}</span>
            </p>
            <p>
              PROPRIETÁRIO: <span>{data?.owner}</span>{' '}
            </p>
            <p>
              ENDEREÇO: <span>{data?.address}</span>
            </p>
            <p>
              TIPO: <span>{data?.type}</span>
            </p>
          </BoxInfos>
        )}
      </Main>
      {errorValidate && errorValidate.message === 'OC_NOT_ALLOWED' && (
        <>
          <Margin height={40} />
          <StatusInfo status="warning">
            OCA/OCE referente ao CPAE {numberCpae} ainda não liberada.
            <br />
            Qualquer dúvida entre em contato com nossos canais de atendimento.
          </StatusInfo>
        </>
      )}
      {errorCreate && errorCreate.message === 'OC_ALREADY_REQUESTED' && (
        <>
          <Margin height={40} />
          <StatusInfo status="info">
            Você já solicitou OCA e OCE para o número CPAE {numberCpae}
          </StatusInfo>
        </>
      )}
      <Margin height={40} />
      <ContainerButton isRevision={!!isRevision}>
        <Button
          backIcon
          backgroundColor={Color.green}
          onClick={() =>
            Navigation.navigate(RouteName.ENTERPRISE_VIABILITY_NEW_PROCESS)
          }
        >
          Voltar
        </Button>
        <Button
          nextIcon
          backgroundColor={!data ? Color.grayLightSeconde : Color.blueSeconde}
          style={{ opacity: 1 }}
          onClick={() => {
            if (!idCreate) {
              dispatch(
                postCreateCustomerObligationRequest({
                  operation: data ? data?.operation : '',
                  cpaeCase: data ? data?.id : '',
                  type: typeCustomerObligation || 'oca',
                }),
              );
            } else {
              setActualStep(2);
            }
          }}
          loading={loadingCreate || loadingValidate}
          disabled={!data}
        >
          Continuar
        </Button>
      </ContainerButton>
    </>
  );
};

export default ProcessIdentification;
