import { Reducer } from 'redux';
import {
  RepositoriesTypesEnrollmentsCards,
  IRepositoriesStateEnrollmentsCards,
} from './types';

const INITIAL_STATE: IRepositoriesStateEnrollmentsCards = {
  data: null,
  loading: false,
  loadingCreateCard: false,
  loadingDeleteCard: false,
  loadingPatchCard: false,
  successCreateCard: false,
  error: null,
  errorCreateCard: null,
  errorDeleteCard: null,
  errorPatchCard: null,
};

const reducer: Reducer<IRepositoriesStateEnrollmentsCards, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesEnrollmentsCards.CARDS_GET_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesEnrollmentsCards.CARDS_GET_METHOD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: null,
      };
    case RepositoriesTypesEnrollmentsCards.CARDS_GET_METHOD_FAILURE:
      return {
        ...state,
        data: null,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
        loading: false,
      };
    case RepositoriesTypesEnrollmentsCards.CARDS_GET_METHOD_CLOSE:
      return {
        ...state,
        error: null,
      };

    case RepositoriesTypesEnrollmentsCards.CARDS_CREATE_POST_METHOD_REQUEST:
      return {
        ...state,
        loadingCreateCard: true,
      };
    case RepositoriesTypesEnrollmentsCards.CARDS_CREATE_POST_METHOD_SUCCESS:
      return {
        ...state,
        successCreateCard: true,
        loadingCreateCard: false,
        errorCreateCard: null,
      };
    case RepositoriesTypesEnrollmentsCards.CARDS_CREATE_POST_METHOD_SUCCESS_CLOSE:
      return {
        ...state,
        successCreateCard: false,
      };
    case RepositoriesTypesEnrollmentsCards.CARDS_CREATE_POST_METHOD_FAILURE:
      return {
        ...state,
        data: null,
        errorCreateCard: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
        loadingCreateCard: false,
      };
    case RepositoriesTypesEnrollmentsCards.CARDS_CREATE_POST_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorCreateCard: null,
      };

    case RepositoriesTypesEnrollmentsCards.CARDS_DELETE_METHOD_REQUEST:
      return {
        ...state,
        loadingDeleteCard: true,
      };
    case RepositoriesTypesEnrollmentsCards.CARDS_DELETE_METHOD_SUCCESS:
      return {
        ...state,
        loadingDeleteCard: false,
        errorDeleteCard: null,
      };
    case RepositoriesTypesEnrollmentsCards.CARDS_DELETE_METHOD_FAILURE:
      return {
        ...state,
        errorDeleteCard: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
        loadingDeleteCard: false,
      };
    case RepositoriesTypesEnrollmentsCards.CARDS_DELETE_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorDeleteCard: null,
      };

    case RepositoriesTypesEnrollmentsCards.CARDS_UPDATE_DEFAULT_PATCH_METHOD_REQUEST:
      return {
        ...state,
        loadingPatchCard: true,
      };
    case RepositoriesTypesEnrollmentsCards.CARDS_UPDATE_DEFAULT_PATCH_METHOD_SUCCESS:
      return {
        ...state,
        loadingPatchCard: false,
        errorPatchCard: null,
      };
    case RepositoriesTypesEnrollmentsCards.CARDS_UPDATE_DEFAULT_PATCH_METHOD_FAILURE:
      return {
        ...state,
        errorPatchCard: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
        loadingPatchCard: false,
      };
    case RepositoriesTypesEnrollmentsCards.CARDS_UPDATE_DEFAULT_PATCH_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorPatchCard: null,
      };

    default:
      return state;
  }
};

export default reducer;
