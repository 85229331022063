import React, { FC, useEffect, useRef, useState } from 'react';

import { Margin } from '~/components/Margin';

import {
  Main,
  Card,
  ContainerBorder,
  Border,
  Content,
  ContainerBreadCrumb,
  ContainerButtons,
  ContentProtocol,
  ContentProtocolButtons,
  BorderStatus,
} from './styles';
import { BreadCrumb } from '~/components/BreadCrumb';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { ButtonText } from '~/components/ButtonText';
import { Color } from '~/styles';
import StatusInfo from './StatusInfo';
import { IResponseGetSocialTariff } from '~/store/modules/socialTariff/types';
import { history } from '~/store';
import moment from 'moment';
import {
  socialTariffStatus,
  socialTariffStatusCuiaba,
} from '~/utils/socialTariffStatus';
import html2canvas from 'html2canvas';
import Protocol from './Protocol';
import LoadingComponent from '~/components/Loading';
import { BannerApp } from '~/components/BannerApp';

interface IPropsStatus {
  status: string;
}

export const Borders: FC<IPropsStatus> = ({ status }) => {
  switch (socialTariffStatus(status).labelRequest) {
    case 'Solicitação aberta':
      return (
        <>
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
          <Margin width={11} />
          <BorderStatus backgroundColor={Color.grayLightSeconde} />
          <Margin width={11} />
          <BorderStatus backgroundColor={Color.grayLightSeconde} />
        </>
      );
    case 'Em análise':
      return (
        <>
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
          <Margin width={11} />
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
          <Margin width={11} />
          <BorderStatus backgroundColor={Color.grayLightSeconde} />
        </>
      );
    case 'Revisar documentos':
      return (
        <>
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
          <Margin width={11} />
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
          <Margin width={11} />
          <BorderStatus backgroundColor={Color.grayLightSeconde} />
        </>
      );
    case 'Concluído':
      return (
        <>
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
          <Margin width={11} />
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
          <Margin width={11} />
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
        </>
      );

    default:
      return (
        <>
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
          <Margin width={11} />
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
          <Margin width={11} />
          <BorderStatus backgroundColor={socialTariffStatus(status).colorRequest} />
        </>
      );
  }
};

export const getDescription = ({ status }: IPropsStatus) => {
  switch (socialTariffStatus(status).labelRequest) {
    case 'Solicitação aberta':
      return 'Por favor, aguarde! Estamos avaliando sua solicitação.';
    case 'Em análise':
      return 'Por favor, aguarde! Estamos avaliando sua solicitação.';
    case 'Revisar documentos':
      return 'Documentação pendente! Entrar em contato através dos nossos canais de atendimento.';
    case 'Concluído':
      return 'Sua solicitação foi concluída com sucesso!';
    default:
      return 'Sua solicitação foi recusada, entrar em contato através dos nossos canais de atendimento.';
  }

  return '';
};

const SocialTariffRequestCuiaba: FC = () => {
  const data = history.location.state as IResponseGetSocialTariff;

  const printRef = useRef(null);

  const [copy, setCopy] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadProtocol, setDownloadProtocol] = useState<boolean>(false);

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 5000);
    }
  }, [copy]);

  const handleDownload = async () => {
    setLoading(true);
    const element = printRef.current;
    const canvas = element ? await html2canvas(element) : null;

    const data = canvas?.toDataURL('image/jpg');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      data ? (link.href = data) : null;
      link.download = 'protocolo.jpg';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }

    setDownloadProtocol(!downloadProtocol);
    setLoading(false);
  };

  return (
    <Main>
      {loading && <LoadingComponent labelWait="Baixando..." />}
      <ContainerBreadCrumb>
        <BreadCrumb
          links={[
            {
              label: 'Home',
              active: false,
              onClick: () => Navigation.navigate(RouteName.HOME),
            },
            {
              label: 'Tarifa social',
              active: false,
              onClick: () => Navigation.navigate(RouteName.SOCIALTARIFF),
            },
            {
              label: 'Solicitar tarifa social',
              active: true,
            },
          ]}
        />
      </ContainerBreadCrumb>
      <div>
        <BannerApp app />
      </div>
      <Card>
        <Content>
          <h1>Solicitação de tarifa social</h1>
          <Margin height={44} />
        </Content>
        <ContainerBorder>
          <Border />
          <h2>Solicitação aberta</h2>
          <Border />
        </ContainerBorder>
        <Content>
          <Margin height={24} />
          {downloadProtocol ? (
            <>
              <ContentProtocol ref={printRef}>
                <Protocol />
              </ContentProtocol>
              <ContentProtocolButtons>
                <ButtonText
                  text="Baixar protocolo"
                  typeBtn="content"
                  Color={Color.white}
                  backgroundColor={Color.blueSeconde}
                  onClick={handleDownload}
                />
                <Margin height={16} />
                <ButtonText
                  text="Voltar"
                  typeBtn="content"
                  Color={Color.white}
                  backgroundColor={Color.green}
                  onClick={() => setDownloadProtocol(!downloadProtocol)}
                />
              </ContentProtocolButtons>
            </>
          ) : (
            <>
              <StatusInfo
                status={
                  socialTariffStatusCuiaba(data.status).label ===
                  'Documentação aprovada'
                    ? 'success'
                    : 'wait'
                }
                onClick={() => setDownloadProtocol(!downloadProtocol)}
                labelButton="Baixar protocolo"
              >
                {socialTariffStatusCuiaba(data.status).label ===
                'Documentação aprovada'
                  ? 'Sua solicitação foi concluída com sucesso!'
                  : 'Favor, aguarde! Estamos avaliando sua solicitação.'}
              </StatusInfo>
              <Margin height={24} />
              <p className="protocol-text">
                NÚMERO DO PROTOCOLO: <span>{data.protocol}</span>
              </p>
              <Margin height={4} />
              <p className="protocol-text">
                DATA DE SOLICITAÇÃO:{' '}
                <span>{moment(data.createdDate).format('DD/MM/YYYY')}</span>
              </p>
              {data?.closedDate && (
                <>
                  <Margin height={4} />
                  <p className="protocol-text">
                    DATA DE CONCLUSÃO:{' '}
                    <span>{moment(data.closedDate).format('DD/MM/YYYY')}</span>
                  </p>
                </>
              )}
              <ContainerButtons>
                <ButtonText
                  text="Ir para home"
                  typeBtn="content"
                  nextIcon
                  backgroundColor={Color.green}
                  onClick={() => Navigation.navigate(RouteName.HOME)}
                />
              </ContainerButtons>
            </>
          )}
        </Content>
      </Card>
    </Main>
  );
};

export default SocialTariffRequestCuiaba;
