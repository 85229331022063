export enum RepositoriesTypesOrderServices {
  ORDER_SERVICES_GET_LIST_BY_CNP_METHOD_REQUEST = '@repositories/ORDER_SERVICES_GET_LIST_BY_CNP_METHOD_REQUEST',
  ORDER_SERVICES_GET_LIST_BY_CNP_METHOD_SUCCESS = '@repositories/ORDER_SERVICES_GET_LIST_BY_CNP_METHOD_SUCCESS',
  ORDER_SERVICES_GET_LIST_BY_CNP_METHOD_FAILURE = '@repositories/ORDER_SERVICES_GET_LIST_BY_CNP_METHOD_FAILURE',
  ORDER_SERVICES_GET_LIST_BY_CNP_METHOD_CLOSE = '@repositories/ORDER_SERVICES_GET_LIST_BY_CNP_METHOD_CLOSE',

  ORDER_SERVICES_GET_LIST_TYPE_METHOD_REQUEST = '@repositories/ORDER_SERVICES_GET_LIST_TYPE_METHOD_REQUEST',
  ORDER_SERVICES_GET_LIST_TYPE_METHOD_SUCCESS = '@repositories/ORDER_SERVICES_GET_LIST_TYPE_METHOD_SUCCESS',
  ORDER_SERVICES_GET_LIST_TYPE_METHOD_FAILURE = '@repositories/ORDER_SERVICES_GET_LIST_TYPE_METHOD_FAILURE',

  ORDER_SERVICES_POST_ATTACHMENTS_METHOD_REQUEST = '@repositories/ORDER_SERVICES_POST_ATTACHMENTS_METHOD_REQUEST',
  ORDER_SERVICES_POST_ATTACHMENTS_METHOD_SUCCESS = '@repositories/ORDER_SERVICES_POST_ATTACHMENTS_METHOD_SUCCESS',
  ORDER_SERVICES_POST_ATTACHMENTS_METHOD_FAILURE = '@repositories/ORDER_SERVICES_POST_ATTACHMENTS_METHOD_FAILURE',
  ORDER_SERVICES_POST_ATTACHMENTS_METHOD_CLOSE = '@repositories/ORDER_SERVICES_POST_ATTACHMENTS_METHOD_CLOSE',

  ORDER_SERVICES_GET_VALIDATE_LACKWATER_METHOD_REQUEST = '@repositories/ORDER_SERVICES_GET_VALIDATE_LACKWATER_METHOD_REQUEST',
  ORDER_SERVICES_GET_VALIDATE_LACKWATER_METHOD_SUCCESS = '@repositories/ORDER_SERVICES_GET_VALIDATE_LACKWATER_METHOD_SUCCESS',
  ORDER_SERVICES_GET_VALIDATE_LACKWATER_METHOD_FAILURE = '@repositories/ORDER_SERVICES_GET_VALIDATE_LACKWATER_METHOD_FAILURE',
  ORDER_SERVICES_GET_VALIDATE_LACKWATER_METHOD_CLOSE = '@repositories/ORDER_SERVICES_GET_VALIDATE_LACKWATER_METHOD_CLOSE',

  ORDER_SERVICES_POST_CREATE_LACKWATER_METHOD_REQUEST = '@repositories/ORDER_SERVICES_POST_CREATE_LACKWATER_METHOD_REQUEST',
  ORDER_SERVICES_POST_CREATE_LACKWATER_METHOD_SUCCESS = '@repositories/ORDER_SERVICES_POST_CREATE_LACKWATER_METHOD_SUCCESS',
  ORDER_SERVICES_POST_CREATE_LACKWATER_METHOD_FAILURE = '@repositories/ORDER_SERVICES_POST_CREATE_LACKWATER_METHOD_FAILURE',
  ORDER_SERVICES_POST_CREATE_LACKWATER_METHOD_CLOSE = '@repositories/ORDER_SERVICES_POST_CREATE_LACKWATER_METHOD_CLOSE',

  ORDER_SERVICES_GET_ADDRESS_LACKWATER_METHOD_REQUEST = '@repositories/ORDER_SERVICES_GET_ADDRESS_LACKWATER_METHOD_REQUEST',
  ORDER_SERVICES_GET_ADDRESS_LACKWATER_METHOD_SUCCESS = '@repositories/ORDER_SERVICES_GET_ADDRESS_LACKWATER_METHOD_SUCCESS',
  ORDER_SERVICES_GET_ADDRESS_LACKWATER_METHOD_FAILURE = '@repositories/ORDER_SERVICES_GET_ADDRESS_LACKWATER_METHOD_FAILURE',
  ORDER_SERVICES_GET_ADDRESS_LACKWATER_METHOD_CLOSE = '@repositories/ORDER_SERVICES_GET_ADDRESS_LACKWATER_METHOD_CLOSE',

  ORDER_SERVICES_GET_INPUTS_LACKWATER_METHOD_REQUEST = '@repositories/ORDER_SERVICES_GET_INPUTS_LACKWATER_METHOD_REQUEST',
  ORDER_SERVICES_GET_INPUTS_LACKWATER_METHOD_SUCCESS = '@repositories/ORDER_SERVICES_GET_INPUTS_LACKWATER_METHOD_SUCCESS',
  ORDER_SERVICES_GET_INPUTS_LACKWATER_METHOD_FAILURE = '@repositories/ORDER_SERVICES_GET_INPUTS_LACKWATER_METHOD_FAILURE',
  ORDER_SERVICES_GET_INPUTS_LACKWATER_METHOD_CLOSE = '@repositories/ORDER_SERVICES_GET_INPUTS_LACKWATER_METHOD_CLOSE',

  ORDER_SERVICES_GET_DOWNLOAD_LACKWATER_METHOD_REQUEST = '@repositories/ORDER_SERVICES_GET_DOWNLOAD_LACKWATER_METHOD_REQUEST',
  ORDER_SERVICES_GET_DOWNLOAD_LACKWATER_METHOD_SUCCESS = '@repositories/ORDER_SERVICES_GET_DOWNLOAD_LACKWATER_METHOD_SUCCESS',
  ORDER_SERVICES_GET_DOWNLOAD_LACKWATER_METHOD_FAILURE = '@repositories/ORDER_SERVICES_GET_DOWNLOAD_LACKWATER_METHOD_FAILURE',
  ORDER_SERVICES_GET_DOWNLOAD_LACKWATER_METHOD_CLOSE = '@repositories/ORDER_SERVICES_GET_DOWNLOAD_LACKWATER_METHOD_CLOSE',
}

export interface IRequestQueryOrders {
  page: number;
  size: number;
}

export interface Ibar {
  color: string;
  item: number;
}

interface Address {
  full_address: string;
  complement: any;
}
export interface IOrderServiceInput {
  nameForm: string;
  label: string;
  type: string;
  name: string;
  value: string;
}
interface Info {
  address: Address;
  inputs: IOrderServiceInput[];
}
export interface IOrderService {
  caseId: string;
  closedDate: string | null;
  createdDate: string;
  protocol: string;
  serviceName: string;
  status: {
    label: string;
    description: string;
    color: string;
    bar: Array<Ibar>;
  };
  info?: Info;
}
export interface IResponseGetListByCnp {
  data: IOrderService[] | [];
  pagination?: {
    totalItens: number;
    maxPages: number;
    page: number;
  };
}

export interface IResponseGetListType {
  id: string;
  name: string;
}

export interface IRequestPostAttachments {
  file: File;
}

export interface IResponsePostAttachments {
  fileTitle: string;
  base64: string;
  fileType: string;
}

export interface IRequestPostCreate {
  enrollment: string;
  name: string;
  street: string;
  number: string;
  complement: string;
  description: string;
  service_id: string;
  files: IRequestPostAttachments[] | IResponsePostAttachments[];
}

export interface IResponsePostCreate {
  enrollment: string;
  name: string;
  street: string;
  number: string;
  complement: string;
  description: string;
  service_id: string;
  files: IResponsePostAttachments[];
  protocol: string;
  closedDate: string | null;
}

export interface IResponseValidateLackWater {
  validate: boolean;
  type_info:
    | 'water_supply_interrupted'
    | 'reported_water_supply'
    | 'water_connection_inactive'
    | 'empty_registration';
}

export interface Option {
  id: number;
  input_fields_id: number;
  label: string;
  createdAt: string;
  updatedAt: string;
}
export interface IResponseInputsLackWater {
  [x: string]: any;
  id: number;
  nameForm: string;
  label: string;
  type: string;
  required: boolean;
  initialLabel?: string;
  maxLength?: number;
  name: string;
  allowedCharacters?: string;
  options: Option[];
}

export interface IRequestCreateLackWater {
  enrollment: string;
  [key: string]: any;
}

export interface IResponseCreateLackWater {
  caseNumber: string;
}

export interface IResponseAddressLackWater {
  name: string;
  complete_address: string;
  address: string;
  district: string;
  city: string;
  state: string;
  zip_code: string;
  complement: any;
  number: string;
}
export interface IRepositoriesStateOrderServices {
  readonly data: IResponseGetListByCnp | null;
  readonly dataTypes: IResponseGetListType[] | null;
  readonly dataAttachments: IResponseGetListType[] | null;
  readonly dataCreate: IResponsePostCreate | null;
  readonly dataValidateLackWater: IResponseValidateLackWater | null;
  readonly dataInputs: IResponseInputsLackWater[] | null;
  readonly dataCreateLackWater: IResponseCreateLackWater | null;
  readonly dataAddressLackWater: IResponseAddressLackWater | null;
  readonly error: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorTypes: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorAttachments: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorValidateLackWater: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorInputs: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorCreateLackWater: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorAddressLackWater: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorDownloadLackWater: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loading: boolean;
  readonly loadingTypes: boolean;
  readonly loadingAttachments: boolean;
  readonly loadingValidateLackWater: boolean;
  readonly loadingInputsLackWater: boolean;
  readonly loadingCreateLackWater: boolean;
  readonly loadingAddressLackWater: boolean;
  readonly loadingDownloadLackWater: boolean;
  readonly successCreate: boolean;
  readonly successCreateLackWater: boolean;
}
