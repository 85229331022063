import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import clipboardCopy from 'clipboard-copy';
import moment from 'moment';

import CopyIcon from '~/assets/Icons/Copy';
import CalendarSVG from '~/assets/Icons/calendar';

import { Margin } from '~/components/Margin';
import {
  AmountText,
  Container,
  ContainerInfo,
  DueDateText,
  Header,
  InfoPaymentText,
  DescriptionText,
  PaymentText,
  Divisor,
  ContainerCodeBarLabelInvoice,
  MiddleWrapper,
  MiddleLeft,
  ButtonCodeInvoice,
  Row,
} from './styles';
import { IDadosDebts } from '~/store/modules/outsides/types';
import { Breakpoints } from '~/styles';
import formatter from '~/utils/formatter';
import { statusColor } from '~/utils/invoiceStatus';
import { QRCode } from '~/components/QRCode';
import { useMediaQuery } from 'react-responsive';
import { metricRegisterCopyClipboard } from '~/services/pix';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import {
  activateModalDataUpdate,
  getInvoiceDownload,
} from '~/store/modules/outsides/actions';
import {
  CopySuccessIcon,
  DownloadIcon,
  PixCodeIcon,
  TicketCodeIcon,
} from '~/views/EasyDuplicate/Icons';
import { Link } from 'react-router-dom';
import { RouteName } from '~/routes/Routes.name';
import { checkToggle } from '~/utils/toggles';
import AlertPixFraud from '~/components/AlertPixFraud';

interface IProps {
  data: IDadosDebts;
  method?: 'pixCode' | 'barCode' | 'creditCard';
  cnp: string;
  setLoadingCopy: (loading: boolean) => void;
}

const InvoiceDetail: React.FC<IProps> = ({ data, method, cnp, setLoadingCopy }) => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });

  const dispatch = useDispatch();

  const dataRegistrationUpdate = useSelector(
    (item: IState) => item.outsides.dataRegistrationUpdate,
  );

  const toggles = useSelector((item: IState) => item.toggles.data);

  const [code, setCode] = useState<string>('');
  const [copied, setCopied] = useState<boolean>(false);
  const [downloaded, setDownloaded] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isClickedDownload, setIsClickedDownload] = useState(false);
  const [isVisibleButtonDownload, setIsVisibleButtonDownload] = useState(false);

  const hasAlert = !!(
    dataRegistrationUpdate &&
    dataRegistrationUpdate?.company_name &&
    dataRegistrationUpdate?.cnp_alert
  );

  const verifyToggles = async () => {
    setIsVisibleButtonDownload(
      await checkToggle(toggles, 'DOWNLOAD_INVOICE_OUTSIDE'),
    );
  };

  useEffect(() => {
    verifyToggles();
  }, []);
  useEffect(() => {
    setCopied(false);
    setDownloaded(false);
    method === 'barCode'
      ? setCode(data.bar_code_number ? data.bar_code_number.toString() : '')
      : setCode(data.pix_code || '');
  }, [method]);

  const registrationFormat = formatter.formatarNumber(data?.enrollment_id);

  const handleCopy = () => {
    if (isClicked || copied) return;

    const typeId = data?.type === 'INVOICE' ? 'invoice_id' : 'sequencial';
    if (method === 'barCode') {
      ReactGA.event({
        category: 'Pagamento',
        action: '[QD][C4][WEB] - Pg. de uma única dívida – Copia/Cola Código Boleto',
      });

      const request = {
        [typeId]: data?.id,
        amount: data?.amount,
        barcode_number: data?.bar_code_number,
        registration_id: registrationFormat?.substring(
          0,
          registrationFormat.length - 1,
        ),
        metric_type: 'BOLETO_NL_DEBTS_4',
      };

      metricRegisterCopyClipboard(request, cnp);
    } else {
      ReactGA.event({
        category: 'Pagamento',
        action: '[QD][C4][WEB] - Pg. de uma única dívida – Copia/Cola Código PIX',
      });

      const request = {
        [typeId]: data?.id,
        amount: data?.amount,
        qrcode_key: data?.pix_code,
        registration_id: registrationFormat?.substring(
          0,
          registrationFormat.length - 1,
        ),
        metric_type: 'PIX_NL_DEBTS_4',
      };

      metricRegisterCopyClipboard(request, cnp);
    }

    setIsClicked(true);
    setLoadingCopy(true);

    setTimeout(() => {
      clipboardCopy(code);
      setIsClicked(false);
      setDownloaded(false);
      setCopied(true);
      setLoadingCopy(false);

      const methodModal = method === 'pixCode' || method === 'barCode' ? method : '';

      if (dataRegistrationUpdate?.isUpdatable)
        dispatch(activateModalDataUpdate(methodModal));

      setTimeout(() => {
        setCopied(false);
      }, 5000);
    }, 2000);
  };

  const handleDownload = () => {
    if (isClickedDownload || downloaded) return;
    if (method === 'barCode') {
      ReactGA.event({
        category: 'Baixar Boleto',
        action: '[WEB][QD][Boleto] Baixar Boleto',
      });
    } else {
      ReactGA.event({
        category: 'Baixar Boleto',
        action: '[WEB][QD][PIX] Baixar Boleto',
      });
    }

    setIsClickedDownload(true);
    setLoadingCopy(true);

    setTimeout(() => {
      setIsClickedDownload(false);
      setDownloaded(true);
      setLoadingCopy(false);

      if (data?.type === 'INVOICE')
        dispatch(
          getInvoiceDownload(
            data?.id,
            'invoice',
            cnp,
            formatter.removeDigitEnrollment(data?.enrollment_id),
          ),
        );
      if (data?.type !== 'INVOICE')
        dispatch(getInvoiceDownload(data?.id, 'charge', cnp));

      if (dataRegistrationUpdate?.isUpdatable)
        dispatch(activateModalDataUpdate('download'));

      setTimeout(() => {
        setDownloaded(false);
      }, 5000);
    }, 2000);
  };

  return (
    <Container>
      <Header color={statusColor(data.status.label || '', true)}>
        {data.status.label}
      </Header>
      <ContainerInfo>
        <DescriptionText>{data.description}</DescriptionText>
        <Margin height={6} />
        <PaymentText>
          <h2 className={method === 'barCode' ? undefined : 'center-text'}>
            PAGAR COM {method === 'barCode' ? 'BOLETO BANCÁRIO' : 'PIX'}
          </h2>
        </PaymentText>
        <Margin height={8} />
        <MiddleWrapper isPix={method === 'pixCode'}>
          <MiddleLeft>
            <AmountText color={statusColor(data.status.label || '', true)}>
              {formatter.formatCurrency(data?.amount)}
            </AmountText>
            <DueDateText>
              <div>
                <CalendarSVG />
              </div>
              <p>
                Vencimento: <br />{' '}
                <span>{moment(data?.maturity_date).format('DD/MM/YY')}</span>
              </p>
            </DueDateText>
            {hasAlert && (
              <AlertPixFraud
                company={dataRegistrationUpdate?.company_name}
                cnpj={dataRegistrationUpdate?.cnp_alert || ''}
              />
            )}
            <Margin height={24} />
            <InfoPaymentText>
              {method === 'barCode' ? (
                <>
                  <div>
                    <TicketCodeIcon />
                  </div>
                  <span>
                    Para efetuar o pagamento, copie o código de barras. Para baixar a
                    segunda via, <Link to={RouteName.LOGIN}>faça login.</Link>
                  </span>
                </>
              ) : (
                <>
                  <div>
                    <PixCodeIcon />
                  </div>
                  <span style={{ color: '#000', width: '100%' }}>
                    Após pagamento pix, sua fatura constará como paga em até 24hrs.
                  </span>
                </>
              )}
            </InfoPaymentText>
          </MiddleLeft>
          {method === 'pixCode' && !isMobile && (
            <QRCode codeValue={data.pix_code || ''} />
          )}
        </MiddleWrapper>
        <Divisor />
        <ContainerCodeBarLabelInvoice>
          {method === 'barCode' ? (
            <span>Código de barras</span>
          ) : (
            <span>Código PIX</span>
          )}
          {method === 'barCode' ? (
            <p>{data.bar_code_number}</p>
          ) : (
            <p>{data.pix_code}</p>
          )}
        </ContainerCodeBarLabelInvoice>
        <Row>
          {isVisibleButtonDownload && (
            <ButtonCodeInvoice
              type="button"
              onClick={handleDownload}
              clicked={isClickedDownload}
              copied={downloaded}
              maxWidth={false}
            >
              {!isClickedDownload && (
                <>
                  {isMobile ? (
                    <p>{downloaded ? 'Baixando!' : 'Baixar'}</p>
                  ) : (
                    <p>{downloaded ? 'Baixando!' : `Baixar`}</p>
                  )}
                  <Margin width={8} />
                  <div>{downloaded ? <CopySuccessIcon /> : <DownloadIcon />}</div>
                </>
              )}
            </ButtonCodeInvoice>
          )}
          <ButtonCodeInvoice
            type="button"
            onClick={handleCopy}
            clicked={isClicked}
            copied={copied}
            maxWidth={!isVisibleButtonDownload}
          >
            {!isClicked && (
              <>
                {isMobile ? (
                  <p>{copied ? 'Copiado!' : 'Copiar'}</p>
                ) : (
                  <p>
                    {copied
                      ? 'Código copiado!'
                      : `Copiar código ${
                          method === 'pixCode' ? 'pix' : 'de barras'
                        }`}
                  </p>
                )}
                <Margin width={8} />
                <div>
                  {copied ? <CopySuccessIcon /> : <CopyIcon color="#000" v3 />}
                </div>
              </>
            )}
          </ButtonCodeInvoice>
        </Row>
        <Margin height={24} />
      </ContainerInfo>
    </Container>
  );
};

export default InvoiceDetail;
