import { Reducer } from 'redux';
import {
  RepositoriesTypesAutomaticDebits,
  IRepositoriesStateAutomaticDebits,
} from './types';

const INITIAL_STATE: IRepositoriesStateAutomaticDebits = {
  data: null,
  dataBanks: null,
  loading: false,
  loadingCreate: false,
  loadingDelete: false,
  successCreate: false,
  successDelete: false,
  error: null,
  errorCreate: null,
  errorDelete: null,
  checkModal: null,
};

const reducer: Reducer<IRepositoriesStateAutomaticDebits, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_BANKS_GET_METHOD_REQUEST:
      return {
        ...state,
      };
    case RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_BANKS_GET_METHOD_SUCCESS:
      return {
        ...state,
        dataBanks: action.payload.data,
      };
    case RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_BANKS_GET_METHOD_FAILURE:
      return {
        ...state,
        dataBanks: null,
      };

    case RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_GET_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_GET_METHOD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: null,
      };
    case RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_GET_METHOD_FAILURE:
      return {
        ...state,
        data: null,
        error: {
          status: action.payload.error.status,
          message: action.payload.error.message,
          state: true,
        },
        loading: false,
      };
    case RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_GET_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        error: null,
      };

    case RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_POST_METHOD_REQUEST:
      return {
        ...state,
        loadingCreate: true,
      };
    case RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_POST_METHOD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loadingCreate: false,
        successCreate: true,
        errorCreate: null,
      };
    case RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_POST_METHOD_SUCCESS_CLOSE:
      return {
        ...state,
        successCreate: false,
      };
    case RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_POST_METHOD_FAILURE:
      return {
        ...state,
        data: null,
        errorCreate: {
          status: action.payload.error.status,
          message: action.payload.error.message,
          state: true,
        },
        loadingCreate: false,
      };
    case RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_POST_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorCreate: null,
      };

    case RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_DELETE_METHOD_REQUEST:
      return {
        ...state,
        loadingDelete: true,
      };
    case RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_DELETE_METHOD_SUCCESS:
      return {
        ...state,
        successDelete: true,
        loadingDelete: false,
        errorDelete: null,
      };
    case RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_DELETE_METHOD_SUCCESS_CLOSE:
      return {
        ...state,
        successDelete: false,
      };
    case RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_DELETE_METHOD_FAILURE:
      return {
        ...state,
        errorDelete: {
          status: action.payload.error.status,
          message: action.payload.error.message,
          state: true,
        },
        loadingDelete: false,
      };
    case RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_DELETE_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorDelete: null,
      };

    case RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_ACTIVATE_MODAL:
      return {
        ...state,
        checkModal: action.payload.data,
      };
    case RepositoriesTypesAutomaticDebits.AUTOMATIC_DEBITS_CLEAR_MODAL:
      return {
        ...state,
        checkModal: null,
      };

    default:
      return state;
  }
};

export default reducer;
