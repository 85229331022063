import { Reducer } from 'redux';

import { RepositoriesTypesAuth, IRepositoriesStateEnrollmentsAuth } from './types';

const INITIAL_STATE: IRepositoriesStateEnrollmentsAuth = {
  token: '',
  IDSN: '',
  password: '',
  registrations: [],
  registrationCheck: null,
  firstAccess: false,
  loading: false,
  error: null,
  modalAuth: false,
  modalEnrollmentNotExists: false,
  routeAuthRedirect: '',
};

const reducer: Reducer<IRepositoriesStateEnrollmentsAuth, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesAuth.ENROLLMENTS_AUTH_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesAuth.ENROLLMENTS_AUTH_METHOD_SUCCESS:
      return {
        ...state,
        password: action.payload.data,
        isClient: action.payload.isClient,
        isManager: action.payload.isManager,
        isEntrepreneur: action.payload.isEntrepreneur,
        error: null,
      };

    case RepositoriesTypesAuth.ENROLLMENTS_AUTH_METHOD_FAILURE:
      return {
        ...state,
        data: null,
        error: {
          status: action.payload.error.status,
          message: action.payload.error.message,
          state: true,
        },
        loading: false,
      };

    case RepositoriesTypesAuth.ENROLLMENTS_AUTH_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        error: null,
      };

    case RepositoriesTypesAuth.ENROLLMENTS_SESSIONS_FIRST_ACCESS:
      return {
        ...state,
        firstAccess: true,
      };
    case RepositoriesTypesAuth.ENROLLMENTS_SESSIONS_FIRST_ACCESS_CLEAR:
      return {
        ...state,
        firstAccess: false,
      };

    case RepositoriesTypesAuth.ENROLLMENTS_GET_SESSIONS_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesAuth.ENROLLMENTS_GET_SESSIONS_METHOD_SUCCESS:
      return {
        ...state,
        token: action.payload.data,
        IDSN: action.payload.IDSN,
        error: null,
      };

    case RepositoriesTypesAuth.ENROLLMENTS_GET_SESSIONS_METHOD_FAILURE:
      return {
        ...state,
        registrations: [],
        error: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
        loading: false,
      };

    case RepositoriesTypesAuth.ENROLLMENTS_GET_ME_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesAuth.ENROLLMENTS_GET_ME_METHOD_SUCCESS:
      return {
        ...state,
        registrations: action.payload.data,
        loading: false,
        error: null,
      };

    case RepositoriesTypesAuth.ENROLLMENTS_GET_ME_METHOD_FAILURE:
      return {
        ...state,
        registrations: [],
        error: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
        loading: false,
      };

    case RepositoriesTypesAuth.ENROLLMENTS_DESTROY_SESSION_REQUEST:
      return {
        ...INITIAL_STATE,
      };

    case RepositoriesTypesAuth.ENROLLMENTS_REGISTRATION_CHECK_REQUEST:
      return {
        ...state,
        registrationCheck: action.payload.data,
      };

    case RepositoriesTypesAuth.ENROLLMENTS_AUTH_ACTIVATE_MODAL:
      return {
        ...state,
        modalAuth: true,
        routeAuthRedirect: action.payload.data,
      };
    case RepositoriesTypesAuth.ENROLLMENTS_AUTH_CLEAR_MODAL:
      return {
        ...state,
        modalAuth: false,
      };
    case RepositoriesTypesAuth.ENROLLMENTS_SET_AUTH_ROUTE_REDIRECT:
      return {
        ...state,
        routeAuthRedirect: action.payload.data,
      };
    case RepositoriesTypesAuth.ENROLLMENTS_AUTH_ROUTE_REDIRECT_CLEAR:
      return {
        ...state,
        routeAuthRedirect: '',
      };

    case RepositoriesTypesAuth.ENROLLMENTS_NOT_EXISTS_ACTIVATE_MODAL:
      return {
        ...state,
        modalEnrollmentNotExists: true,
      };
    case RepositoriesTypesAuth.ENROLLMENTS_NOT_EXISTS_CLEAR_MODAL:
      return {
        ...state,
        modalEnrollmentNotExists: false,
      };

    case RepositoriesTypesAuth.ENROLLMENTS_UPDATE_CURRENT_PASSWORD:
      return {
        ...state,
        password: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;
