/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/void-dom-elements-no-children */
import React, { FC, ReactNode, useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import ReactGA from 'react-ga4';

import {
  BorderBlur,
  ContainerBottom,
  ContainerHeader,
  ContainerLoginSession,
  ContainerMenu,
  ContainerTop,
  ContentHeader,
  Backdrop,
  ContainerDigitalClient,
} from './styles';
import { Margin } from '../Margin';
import LogoHeaderSVG from '~/assets/Icons/LogoHeaderSVG';
import ArrowUpSVG from '~/assets/Icons/arrowUp';
import { Breakpoints, Color } from '~/styles';
import MenuSVG from '~/assets/Icons/Menu';
import Dropdown from './Dropdown';
import ModalSession from './ModalSession';
import { DialogBalloon } from '../DialogBalloon';
import { IState } from '~/store/modules/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  activateModalAuth,
  clearModalAuth,
} from '~/store/modules/enrollmentsAuth/actions';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { checkToggle } from '~/utils/toggles';
import DigitalClientSVG from '~/assets/Icons/DigitalClient';
import { useMediaQuery } from 'react-responsive';
import ModalNotifications from './ModalNotifications';
import { NotificationIcon } from './icons';
import {
  activateModalNotifications,
  clearModalNotifications,
  getListNotificationsRequest,
  putUpdateAllNotificationsRequest,
} from '~/store/modules/notifications/actions';
import {
  activateRouteReportLackWater,
  clearRouteReportLackWater,
} from '~/store/modules/outsides/actions';
import { getOrderServicesValidateLackWaterRequest } from '~/store/modules/orderServices/actions';

interface IProps {
  fullHeader?: boolean;
}

interface DialogProps {
  children: ReactNode;
  open: boolean;
  onClose?: () => void;
}

interface IUser {
  firstName?: string;
  registrationNumber?: string;
  address?: string;
}

const CustomDialog = ({ children, open, onClose }: DialogProps) => {
  return ReactDom.createPortal(
    <Backdrop
      open={open}
      className="backdrop"
      onClick={(e) => {
        if ((e.target as Element).classList.contains('backdrop')) {
          onClose?.();
        }
      }}
    >
      {children}
    </Backdrop>,
    document.getElementById('modal-root')!,
  );
};

interface IGetDimensionType {
  isMobile?: boolean;
  isTablet?: boolean;
  isLaptop?: boolean;
  isDesktopLarge?: boolean;
}

interface IHandleMenuItemClick {
  action?: () => void;
  routeAuthRedirect?: string;
}

const dimensions = {
  isMobile: {
    right: 24,
    polygon: 140,
  },
  isTablet: {
    right: 24,
    polygon: 170,
  },
  isLaptop: {
    right: 24,
    polygon: 170,
  },
  isDesktopLarge: {
    right: 24,
    polygon: 180,
  },
};

const getDimensionType = (dimensionData: IGetDimensionType) => {
  if (dimensionData.isMobile) {
    return dimensions.isMobile;
  }
  if (dimensionData.isTablet) {
    return dimensions.isTablet;
  }
  if (dimensionData.isLaptop) {
    return dimensions.isLaptop;
  }

  return dimensions.isDesktopLarge;
};

const Header: FC<IProps> = ({ fullHeader = true }) => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });
  const isTablet = useMediaQuery({ maxWidth: Breakpoints.tablet.max });
  const isLaptop = useMediaQuery({ maxWidth: Breakpoints.laptop.max });
  const isDesktop = useMediaQuery({ minWidth: Breakpoints.laptop.min });
  const isDesktopLarge = useMediaQuery({ maxWidth: Breakpoints.desktopLarge.max });

  const dispatch = useDispatch();

  const [dropDownActive, setDropDownActive] = useState<boolean>(false);
  const [supportCenter, setSupportCenter] = useState<boolean>(false);
  // const [ownership, setOwnership] = useState<boolean>(false);

  const modalAuth = useSelector((item: IState) => item.enrollmentsAuth.modalAuth);
  const modalNotifications = useSelector(
    (item: IState) => item.notifications.modalNotifications,
  );
  const notifications = useSelector((state: IState) => state.notifications.data);
  const isReadNotification = notifications?.filter(
    (notification) => !notification.is_read,
  );
  const token = useSelector((state: IState) => state.enrollmentsAuth.token);
  const eletronic_exists = useSelector(
    (state: IState) => state.enrollmentsAuth.registrationCheck?.eletronic_exists,
  );

  const enrollment = useSelector(
    (state: IState) => state.enrollmentsAuth.registrationCheck?.enrollment,
  );
  
  const registrationCheck = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck,
  );
  const enrollmentsUserInfo = useSelector(
    (item: IState) => item.enrollmentsUsers.data,
  );
  const loadingAuth = useSelector((item: IState) => item.enrollmentsAuth.loading);

  const user: IUser = {
    firstName: enrollmentsUserInfo?.firstName
      ? `${enrollmentsUserInfo?.firstName}`
      : '',
    registrationNumber: registrationCheck?.code || '',
    address: registrationCheck?.address_connection || '',
  };
  const togglesMe = useSelector((item: IState) => item.togglesMe.data);
  const toggles = useSelector((item: IState) => item.toggles.data);
  const isClient = useSelector((state: IState) => state.enrollmentsAuth.isClient);
  const isEntrepreneur = useSelector(
    (state: IState) => state.enrollmentsAuth.isEntrepreneur,
  );
  const isManager = useSelector((state: IState) => state.enrollmentsAuth.isManager);
  const registrations = useSelector(
    (state: IState) => state.enrollmentsAuth.registrations,
  );
  const [reRegistration, setReRegistration] = useState<boolean>(false);
  const [notification, setNotification] = useState<boolean>(false);
  const [reportLackWater, setReportLackWater] = useState<boolean>(false);

  const actionLabel = {
    all_services: {
      category: 'Todos os serviços',
      action: '[Menu][Área Logada][Faixa Inicial] – Todos os serviços',
    },
    invoice_consultation: {
      category: 'Faturas',
      action: '[Menu][Área Logada][Faixa Inicial] – Consulta de faturas',
    },
    repair_center: {
      category: 'Minhas solicitações',
      action: '[Menu][Área Logada][Faixa Inicial] – Minhas solicitações',
    },
    consumption_history: {
      category: 'Histórico detalhado',
      action: '[Menu][Área Logada][Faixa Inicial] – Histórico detalhado',
    },
    negative_certificate: {
      category: 'Certidão negativa',
      action: '[Menu][Área Logada][Faixa Inicial] – Certidão negativa',
    },
    contact_us: {
      category: 'Fale conosco',
      action: '[Menu][Área Logada][Faixa Inicial] – Fale conosco',
    },
    lack_water: {
      category: 'Falta d’água',
      action: token
        ? '[WEB][AL][Menu][Falta d’água]'
        : '[WEB][NL][Menu][Falta d’água]',
    },
  };

  const verifyTogglesMe = async () => {
    // setOwnership(await checkToggle(togglesMe, 'OWNERSHIP_NEW'));
    setSupportCenter(await checkToggle(togglesMe, 'SUPPORT_CENTER_NEW'));
    setReRegistration(await checkToggle(togglesMe, 'RE_REGISTRATION_2'));
    setNotification(await checkToggle(togglesMe, 'NOTIFICATIONS'));
  };

  useEffect(() => {
    if (togglesMe && togglesMe.length > 0) verifyTogglesMe();
  }, [togglesMe]);

  const verifyToggles = async () => {
    setReportLackWater(await checkToggle(toggles, 'REPORT_LACK_WATER'));
  };

  useEffect(() => {
    if (toggles && toggles?.length > 0) verifyToggles();
  }, [toggles]);

  const handleMenuItemClick = ({
    action,
    routeAuthRedirect,
  }: IHandleMenuItemClick) => {
    if (token) {
      if (isClient && !enrollment) return;
      action && action();
    } else {
      action ? action() : dispatch(activateModalAuth(routeAuthRedirect));
      setDropDownActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', (event: any) => {
      if (!event.target.classList.contains('menu-dropdown')) {
        setDropDownActive(false);
      }
    });

    if (token) dispatch(getListNotificationsRequest());
  }, []);

  const openModalSession = () => {
    if (loadingAuth || (isClient && !registrationCheck?.code)) return;
    if (modalAuth) {
      dispatch(clearModalAuth());
    } else {
      setDropDownActive(false);
      dispatch(activateModalAuth(''));
    }
  };

  const handleGaEvent = (
    type:
      | 'all_services'
      | 'invoice_consultation'
      | 'repair_center'
      | 'consumption_history'
      | 'negative_certificate'
      | 'contact_us'
      | 'lack_water',
  ) => {
    ReactGA.event({
      category: actionLabel[type].category,
      action: actionLabel[type].action,
    });
  };

  const handleClearRouteReportLackWater = () => {
    dispatch(clearRouteReportLackWater());
  };

  return (
    <ContainerHeader>
      <ContainerTop>
        <ContentHeader fullHeader={fullHeader}>
          <button
            className="button-logo"
            type="button"
            onClick={() => {
              if ((token && isClient && !registrationCheck?.code) || loadingAuth)
                return;
              if (isEntrepreneur) {
                Navigation.navigate(RouteName.ENTERPRISE_VIABILITY);
                return;
              }

              Navigation.navigate(token ? RouteName.HOME : RouteName.LANDING);
            }}
          >
            <LogoHeaderSVG />
          </button>
          {isDesktop && token && eletronic_exists && (
            <ContainerDigitalClient>
              <DigitalClientSVG />
              <div />
              <span>Cliente digital</span>
            </ContainerDigitalClient>
          )}
          {fullHeader && (
            <>
              <ContainerLoginSession openSession={modalAuth} authenticated={!!token}>
                {notification && token && (
                  <>
                    <button
                      id="notifications-button"
                      type="button"
                      onClick={() =>
                        dispatch(
                          modalNotifications
                            ? clearModalNotifications()
                            : activateModalNotifications(),
                        )
                      }
                    >
                      {isReadNotification && isReadNotification.length > 0 && (
                        <span>
                          {isReadNotification.length > 9
                            ? '9+'
                            : isReadNotification.length}
                        </span>
                      )}
                      <NotificationIcon />
                    </button>
                    <span />
                  </>
                )}
                <div>
                  <p
                    style={{
                      display:
                        (token && loadingAuth) || (token && !user?.firstName)
                          ? 'none'
                          : 'inherit',

                      textTransform: token ? 'capitalize' : 'inherit',
                      cursor: 'pointer',
                    }}
                    onClick={openModalSession}
                  >
                    {token
                      ? `Olá, ${user?.firstName?.toLocaleLowerCase()}!`
                      : 'Olá, Faça seu login!'}
                  </p>
                  <p
                    style={{
                      display:
                        token && !registrationCheck?.code ? 'none' : 'inherit',
                      cursor: 'pointer',
                    }}
                    onClick={openModalSession}
                  >
                    {token
                      ? `Matrícula: ${user?.registrationNumber}`
                      : 'Ou cadastre - se'}
                  </p>
                  {token &&
                    reRegistration &&
                    !loadingAuth &&
                    !isClient &&
                    !isEntrepreneur &&
                    !isManager && (
                      <p
                        style={{
                          textDecoration: 'underline',
                          color: Color.blueSeconde,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          Navigation.navigate(RouteName.LINKENROLLMENT);
                          ReactGA.event({
                            category: 'Recadastramento',
                            action: `[Cliente Não Saneamento] – Vincular Matricula`,
                          });
                        }}
                      >
                        Vincular matrícula
                      </p>
                    )}
                </div>
                <button
                  className="button-icon"
                  type="button"
                  onClick={openModalSession}
                >
                  <ArrowUpSVG color={Color.blueSeconde} />
                </button>
              </ContainerLoginSession>
              <CustomDialog
                open={modalAuth}
                onClose={() => dispatch(clearModalAuth())}
              >
                <DialogBalloon className="ballon" open={modalAuth} top={64 + 17}>
                  <ModalSession />
                </DialogBalloon>
              </CustomDialog>
              <CustomDialog
                open={modalNotifications}
                onClose={() => {
                  if (isReadNotification && isReadNotification.length > 0) {
                    dispatch(putUpdateAllNotificationsRequest());
                  }
                  dispatch(clearModalNotifications());
                }}
              >
                <DialogBalloon
                  className="ballon"
                  open={modalNotifications}
                  top={64 + 17}
                  right={
                    getDimensionType({
                      isMobile,
                      isTablet,
                      isLaptop,
                      isDesktopLarge,
                    }).right
                  }
                  styleArrow={{
                    right: getDimensionType({
                      isMobile,
                      isTablet,
                      isLaptop,
                      isDesktopLarge,
                    }).polygon,
                  }}
                >
                  <ModalNotifications />
                </DialogBalloon>
              </CustomDialog>
            </>
          )}
        </ContentHeader>
      </ContainerTop>
      {((fullHeader && !token) ||
        (fullHeader && token && isClient && !isEntrepreneur) ||
        (fullHeader && token && !isEntrepreneur && reRegistration)) && (
        <>
          <ContainerBottom>
            <ContentHeader>
              <button
                className="menu-dropdown"
                type="button"
                onClick={() => {
                  if (modalAuth) dispatch(clearModalAuth());
                  setDropDownActive(!dropDownActive);
                }}
              >
                <div className="menu-dropdown">
                  <MenuSVG
                    color={dropDownActive ? Color.blueSeconde : Color.blueIntense}
                  />
                </div>
              </button>
              <Margin width={16} />
              <ContainerMenu>
                <p
                  className="menu-dropdown"
                  onClick={() => {
                    if (modalAuth) dispatch(clearModalAuth());
                    setDropDownActive(!dropDownActive);
                    token && handleGaEvent('all_services');
                  }}
                  style={{
                    color: dropDownActive ? Color.blueSeconde : Color.blueIntense,
                  }}
                >
                  Todos os serviços
                </p>
                <BorderBlur />
                <p
                  onClick={() => {
                    token && handleGaEvent('invoice_consultation');
                    handleMenuItemClick({
                      action: token
                        ? () => Navigation.navigate(RouteName.INVOICES)
                        : undefined,
                      routeAuthRedirect: RouteName.INVOICES,
                    });
                  }}
                >
                  Consulta de faturas
                </p>
                <BorderBlur />
                {/* {ownership && (
                  <>
                    <p
                      onClick={() => {
                        if (token && !isClient && !isEntrepreneur && !isManager) {
                          dispatch(activateModalNotClient());
                        } else {
                          handleMenuItemClick(
                            token &&
                              (() =>
                                Navigation.navigate(RouteName.OWNERSHIPMANAGEMENT)),
                          );
                        }
                      }}
                    >
                      Troca de titularidade
                    </p>
                    <BorderBlur />
                  </>
                )} */}
                {reportLackWater ? (
                  <p
                    onClick={() => {
                      handleGaEvent('lack_water');
                      if (token) {
                        if (isClient && !enrollment) return;
                        if (registrations?.length > 0) {
                          dispatch(
                            getOrderServicesValidateLackWaterRequest(
                              RouteName.REPORTLACKWATER,
                            ),
                          );
                        } else {
                          dispatch(activateRouteReportLackWater());
                        }
                      } else {
                        dispatch(activateRouteReportLackWater());
                        Navigation.navigate(RouteName.LOGIN);
                      }
                    }}
                    id={token ? 'AL-menu-falta-d’água' : 'NL-menu-falta-d’água'}
                  >
                    Reportar falta d’água
                  </p>
                ) : supportCenter ? (
                  <p
                    onClick={() => {
                      handleMenuItemClick({
                        action: token
                          ? () => Navigation.navigate(RouteName.SUPPORTCENTER)
                          : undefined,
                        routeAuthRedirect: RouteName.SUPPORTCENTER,
                      });
                      token && handleGaEvent('repair_center');
                    }}
                  >
                    Minhas solicitações
                  </p>
                ) : (
                  <p
                    onClick={() =>
                      token
                        ? handleMenuItemClick({
                            action: () =>
                              Navigation.navigate(RouteName.ENTERPRISE_VIABILITY),
                          })
                        : handleMenuItemClick({
                            action: () =>
                              Navigation.navigate(
                                RouteName.SUPPLYDEPLETIONPOSSIBILITYDECLARATIONLANDING,
                              ),
                          })
                    }
                    style={{ width: '162px' }}
                  >
                    DPA e DPE
                  </p>
                )}
                <BorderBlur />
                <p
                  onClick={() => {
                    token && handleGaEvent('consumption_history');
                    handleMenuItemClick({
                      action: token
                        ? () => Navigation.navigate(RouteName.DETAILEDHISTORY)
                        : undefined,
                      routeAuthRedirect: RouteName.DETAILEDHISTORY,
                    });
                  }}
                  style={{ width: '162px' }}
                >
                  Histórico detalhado
                </p>
                <BorderBlur />
                {token ? (
                  <p
                    onClick={() => {
                      token && handleGaEvent('negative_certificate');
                      handleMenuItemClick({
                        action: token
                          ? () => Navigation.navigate(RouteName.NEGATIVECERTIFICATE)
                          : undefined,
                        routeAuthRedirect: RouteName.NEGATIVECERTIFICATE,
                      });
                    }}
                    style={{ width: '162px' }}
                  >
                    Certidão negativa
                  </p>
                ) : (
                  <p
                    onClick={() => {
                      handleClearRouteReportLackWater();
                      Navigation.navigate(RouteName.SOCIALTARIFFOUTSIDE);
                    }}
                    style={{ width: '162px' }}
                  >
                    Tarifa social
                  </p>
                )}
                <BorderBlur />
                {!token && (
                  <p
                    onClick={() => {
                      handleClearRouteReportLackWater();
                      Navigation.navigate(RouteName.EASYDUPLICATE);
                    }}
                  >
                    Segunda via fácil
                  </p>
                )}
              </ContainerMenu>
            </ContentHeader>
          </ContainerBottom>
          <Dropdown active={dropDownActive} setDropDownActive={setDropDownActive} />
        </>
      )}
    </ContainerHeader>
  );
};

export default Header;
