type EnumResponseCodeKey = 'B999' | 'B021' | 'B022' | 'B023' | 'B024' | 'B025';

export const GeneralEnumResponseCodeTypes: Record<
  EnumResponseCodeKey,
  { type: string; message: string; code: number }
> = {
  B999: {
    type: 'B999',
    message: 'GENERIC',
    code: 400,
  },
  B021: {
    type: 'B021',
    message: 'TOKENS_RECAPTCHA_MISSING',
    code: 401,
  },
  B022: {
    type: 'B022',
    message: 'TOKEN_V3_RECAPTCHA_MISSING',
    code: 401,
  },
  B023: {
    type: 'B023',
    message: 'TOKEN_V3_RECAPTCHA_INVALID',
    code: 401,
  },
  B024: {
    type: 'B024',
    message: 'TOKEN_V2_RECAPTCHA_INVALID',
    code: 401,
  },
  B025: {
    type: 'B025',
    message: 'RECAPTCHA_GENERIC',
    code: 401,
  },
};
