import styled from 'styled-components';
import { Color } from '~/styles';

export const Container = styled.div`
  width: 334px;
  height: 442px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const ContainerDescription = styled.div`
  width: 100%;
  max-width: 300px;
  min-height: 128px;
  background-color: ${Color.grayLightSeconde};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 122.5%;
    text-align: center;
    color: ${Color.black};
  }
`;

export const Text = styled.p`
  font-weight: 300;
  font-size: 14px;
  color: #000000;
  text-align: center;
  b {
    font-size: 18px;
    font-weight: 400;
  }
`;

export const StatusText = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
`;

export const ContainerDates = styled.div`
  width: 100%;
  max-width: 328px;
  display: flex;
`;
