import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface ICard {
  miniCard?: boolean;
  hiddenBorder?: boolean;
}

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  padding: 0;
  margin: 0 auto;
  padding: 18px 8px 134px 8px;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: 100%;
    padding: 22px 0 64px 0;
    margin: 0 auto;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  width: calc(100% - 16px);

  @media (min-width: ${Breakpoints.laptop.max}px) {
    width: auto;
  }

  @media (min-width: ${Breakpoints.desktopLarge.max}px) {
    flex-direction: row;
    display: inherit;
  }
`;

export const ContainerBreadCrumb = styled.div`
  width: 100%;
  max-width: 1180px;
  margin: 0 auto 22px auto;
  display: none;

  @media (min-width: ${Breakpoints.laptop.min}px) {
    display: inherit;
    padding: 0 63px;
  }

  @media (min-width: ${Breakpoints.desktopLarge.min}px) {
    display: inherit;
    padding: 0;
  }
`;

export const Card = styled.div<ICard>`
  width: calc(100% - 28px);
  min-height: 350px;
  padding: 24px 14px 64px 14px;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${Color.white};
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.09);
  border-radius: 23px 23px 0 0;
  border-top: 24px solid ${Color.blueIntense};

  @media (min-width: ${Breakpoints.desktopExtraLarge.min}px) {
    max-width: calc(870px - 160px);
    padding: 24px 80px 40px 80px;
    align-self: flex-start;
  }

  h1 {
    width: 100%;
    font-weight: 400;
    font-size: 28px;
    line-height: 150%;
    color: ${Color.blueIntense};
    text-align: left;
    margin-bottom: 40px;
  }
`;

export const ContainerBorder = styled.div`
  width: calc(100% + 28px);
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  position: relative;

  @media screen and (min-width: ${Breakpoints.laptop.min}px) {
    width: calc(100% + 160px);
    margin-bottom: 25px;
  }
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background: #c7c9c7;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (min-width: ${Breakpoints.desktopExtraLarge.min}px) {
    flex-direction: row;
  }
`;

export const ContentDivider = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;

export const Divider = styled.div`
  background: #e9e9e9;
  width: 1px;
  height: 271px;
  margin: 0 30px 0 67px;
  display: none;

  @media screen and (min-width: ${Breakpoints.desktopExtraLarge.min}px) {
    display: inherit;
  }
`;

interface MiniBarProps {
  backgroundColor: string;
}

export const MiniBar = styled.div<MiniBarProps>`
  height: 8px;
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
`;

export const OrderStatus = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 31px;
`;

export const OrderTitle = styled.div<MiniBarProps>`
  height: 31px;
  color: ${({ backgroundColor }) => backgroundColor};
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.7px;
  display: flex;
  align-items: center;
  margin-top: 17px;
`;

export const OrderStatusBar = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
`;

export const OrderDate = styled.div`
  width: 100%;
  height: 87px;
  display: flex;
  align-items: center;
`;

export const DateBox = styled.div`
  text-align: center;
  p {
    font-size: 14px;
    margin-bottom: 4px;
  }
  div {
    font-weight: 500;
  }
`;

export const ProtocolInfo = styled.div`
  height: 91px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 8px;

  p {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    line-height: 122.5%;
  }
`;

export const StatusOpt = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  button {
    display: flex;
    max-width: 300px;
    height: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    background: ${Color.blueSeconde};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: ${Color.white};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    :last-of-type {
      border: 2px solid ${Color.blueSeconde};
      background: ${Color.white};
      color: ${Color.blueSeconde};
      box-shadow: none;
    }
  }
`;

export const ContainerProtocol = styled.div`
  width: 100%;
  max-width: 434px;
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
