import { Dispatch } from 'redux';
import {
  getEnrollmentsUsersNotClientValidateService,
  getEnrollmentsUsersNotClientVerifyService,
  getLocationsForReregistrationService,
  patchEnrollmentsUsersNotClientRegisterService,
} from '~/services/enrollmentsUsersNotClient';

import {
  RepositoriesTypesUsersNotClient,
  IRequestRegisterEnrollmentNotClientValidate,
  IResponseRegisterEnrollmentNotClientValidate,
  IRequestRegisterEnrollmentNotClient,
  IResponseRegisterEnrollmentNotClient,
  IResponseGetLocations,
} from './types';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';

const getEnrollmentsUsersNotClientValidate =
  ({ enrollment, location }: IRequestRegisterEnrollmentNotClientValidate) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_REQUEST,
      });

      await getEnrollmentsUsersNotClientVerifyService({
        enrollment,
        location,
      });

      dispatch({
        type: RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_SUCCESS,
        payload: {
          operationId: location,
        },
      });

      return Navigation.navigate(RouteName.LINKENROLLMENTFORM, { enrollment });
    } catch (error: any) {
      if (error.data.message === `Enrollment already has a client`) {
        dispatch({
          type: RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_BAD_REQUEST,
        });

        return dispatch({
          type: RepositoriesTypesUsersNotClient.ENROLLMENTS_NOT_CLIENT_ACTIVATE_MODAL,
        });
      }

      return dispatch({
        type: RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_FAILURE,
        payload: {
          error: {
            message: error?.response?.data.message,
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getEnrollmentsUsersNotClientValidateClear =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_FAILURE_CLOSE,
    });
  };

const patchEnrollmentsUsersNotClientRegister =
  (data: IRequestRegisterEnrollmentNotClient) => async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesUsersNotClient.ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_REQUEST,
      });

      const response = (await patchEnrollmentsUsersNotClientRegisterService({
        ...data,
      })) as IResponseRegisterEnrollmentNotClient;

      return dispatch({
        type: RepositoriesTypesUsersNotClient.ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_SUCCESS,
        payload: {
          data: { protocol: response.id },
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesUsersNotClient.ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const patchEnrollmentsUsersNotClientRegisterServiceSuccessClose =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesUsersNotClient.ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_SUCCESS_CLOSE,
    });
  };

const patchEnrollmentsUsersNotClientRegisterServiceErrorClose =
  () => async (dispatch: Dispatch) => {
    return dispatch({
      type: RepositoriesTypesUsersNotClient.ENROLLMENTS_PATCH_USERS_NOT_CLIENT_REGISTER_METHOD_FAILURE_CLOSE,
    });
  };

const activateModalNotClient = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesUsersNotClient.ENROLLMENTS_NOT_CLIENT_ACTIVATE_MODAL,
  });
};

const clearModalNotClient = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesUsersNotClient.ENROLLMENTS_NOT_CLIENT_CLEAR_MODAL,
  });
};

const getEnrollmentsUsersNotClientVerify =
  ({ enrollment, location }: IRequestRegisterEnrollmentNotClientValidate) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_REQUEST,
      });

      const response = (await getEnrollmentsUsersNotClientValidateService({
        enrollment,
        location,
      })) as IResponseRegisterEnrollmentNotClientValidate;

      if (response.totalSize > 0) {
        dispatch({
          type: RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_SUCCESS,
        });

        return dispatch({
          type: RepositoriesTypesUsersNotClient.ENROLLMENTS_NOT_CLIENT_ACTIVATE_MODAL,
        });
      }

      dispatch({
        type: RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_SUCCESS,
      });

      return Navigation.navigate(RouteName.LINKENROLLMENTFORM, { enrollment });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_USERS_NOT_CLIENT_VALIDATE_METHOD_FAILURE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
          },
        },
      });
    }
  };

const getLocationsForReregistration = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_LOCATIONS_REREGISTRATION_METHOD_REQUEST,
    });

    const locations =
      (await getLocationsForReregistrationService()) as IResponseGetLocations[];

    return dispatch({
      type: RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_LOCATIONS_REREGISTRATION_METHOD_SUCCESS,
      payload: {
        data: { locations },
      },
    });
  } catch (error: any) {
    return dispatch({
      type: RepositoriesTypesUsersNotClient.ENROLLMENTS_GET_LOCATIONS_REREGISTRATION_METHOD_FAILURE,
      payload: {
        error: {
          message: error?.response?.data.message,
          status: error?.response?.status,
          state: true,
        },
      },
    });
  }
};

export {
  getEnrollmentsUsersNotClientValidate,
  getEnrollmentsUsersNotClientValidateClear,
  patchEnrollmentsUsersNotClientRegister,
  patchEnrollmentsUsersNotClientRegisterServiceSuccessClose,
  patchEnrollmentsUsersNotClientRegisterServiceErrorClose,
  activateModalNotClient,
  clearModalNotClient,
  getEnrollmentsUsersNotClientVerify,
  getLocationsForReregistration,
};
