import { Dispatch } from 'redux';
import {
  RepositoriesTypesOwnershipOrders,
  IResponseGetOwnershipOperations,
} from './types';
import {
  deleteOwnershipOrderService,
  getOwnershipOperationsService,
  getOwnershipOrdersService,
} from '~/services/ownership';

import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { IState } from '../types';

const getOwnershipOrdersRequest = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesOwnershipOrders.OWNERSHIP_ORDERS_GET_METHOD_REQUEST,
    });
    const response = await getOwnershipOrdersService();

    return dispatch({
      type: RepositoriesTypesOwnershipOrders.OWNERSHIP_ORDERS_GET_METHOD_SUCCESS,
      payload: {
        data: response,
      },
    });
  } catch (error: any) {
    return dispatch({
      type: RepositoriesTypesOwnershipOrders.OWNERSHIP_ORDERS_GET_METHOD_FAILURE,
      payload: {
        error: {
          message: error.data.message,
          status: error.status,
        },
      },
    });
  }
};

const getOwnershipOperationsRequest = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesOwnershipOrders.OWNERSHIP_OPERATIONS_GET_METHOD_REQUEST,
    });
    const response =
      (await getOwnershipOperationsService()) as IResponseGetOwnershipOperations;

    return dispatch({
      type: RepositoriesTypesOwnershipOrders.OWNERSHIP_OPERATIONS_GET_METHOD_SUCCESS,
      payload: {
        data: response,
      },
    });
  } catch (error: any) {
    return dispatch({
      type: RepositoriesTypesOwnershipOrders.OWNERSHIP_OPERATIONS_GET_METHOD_FAILURE,
      payload: {
        error: {
          message: error.data.message,
          status: error.status,
        },
      },
    });
  }
};

const getOwnershipOrderDetailRequest =
  (id: string | number) => async (dispatch: Dispatch, getState: any) => {
    const list: IState = getState();
    const orders = list.ownership.data ? [...list.ownership.data] : [];

    dispatch({
      type: RepositoriesTypesOwnershipOrders.OWNERSHIP_ORDER_DETAIL_GET_METHOD_REQUEST,
    });

    if (orders.length) {
      const arr = orders.filter((item) => item.case_id === id);
      const detail = arr[0];

      return dispatch({
        type: RepositoriesTypesOwnershipOrders.OWNERSHIP_ORDER_DETAIL_GET_METHOD_SUCCESS,
        payload: {
          data: detail,
        },
      });
      return Navigation.navigate(RouteName.OWNERSHIPMANAGEMENTVIEWORDER);
    }

    return dispatch({
      type: RepositoriesTypesOwnershipOrders.OWNERSHIP_ORDER_DETAIL_GET_METHOD_FAILURE,
      payload: {
        error: {
          message: 'Caso não encontrado',
          status: null,
        },
      },
    });
  };

const deleteOwnershipOrderRequest = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesOwnershipOrders.OWNERSHIP_ORDER_DELETE_METHOD_REQUEST,
    });
    const response = await deleteOwnershipOrderService(id);

    dispatch({
      type: RepositoriesTypesOwnershipOrders.OWNERSHIP_ORDER_DELETE_METHOD_SUCCESS,
      payload: {
        data: response,
      },
    });

    return Navigation.navigate(RouteName.OWNERSHIPMANAGEMENT);
  } catch (error: any) {
    return dispatch({
      type: RepositoriesTypesOwnershipOrders.OWNERSHIP_ORDER_DELETE_METHOD_FAILURE,
      payload: {
        error: {
          message: error.data.message,
          status: error.status,
        },
      },
    });
  }
};

const setOwnershipErrorOpen = (data: any) => (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesOwnershipOrders.OWNERSHIP_ERROR_OPEN,
    payload: {
      error: {
        message: data.message,
        status: data.status,
      },
    },
  });
};

const setOwnershipErrorClose = () => (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesOwnershipOrders.OWNERSHIP_ERROR_CLOSE,
  });
};

export {
  getOwnershipOrdersRequest,
  getOwnershipOperationsRequest,
  getOwnershipOrderDetailRequest,
  setOwnershipErrorOpen,
  setOwnershipErrorClose,
  deleteOwnershipOrderRequest,
};
