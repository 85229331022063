/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
import React, { FC, useEffect, useState } from 'react';
import { ErrorsTextModal, Row } from './styles';
import TitleStep from '../TitleStep';
import { SubTitle } from '../SubTitle';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import FieldInput from '../FieldInput';
import { getCepService } from '~/services/cep';
import Modal from '~/components/Modal';
import { Button } from '~/components/ButtonV3';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import { Margin } from '~/components/Margin';
import { Color } from '~/styles';
import formatter from '~/utils/formatter';

interface Props {
  values?: any;
  setFieldValue?: any;
  isDisabled?: boolean;
}

const InitialStep: FC<Props> = ({ values, setFieldValue, isDisabled }) => {
  const emailConsumer = useSelector(
    (item: IState) => item.enrollmentsUsers.data?.email,
  );
  const [isVisibleModalErroCep, setIsVisibleModalErroCep] = useState(false);
  const [shouldBackPanel, setShouldBackPanel] = useState(false);
  const [messageModalErroCep, setMessageModalErroCep] = useState('');
  const [lockAddress, setLockAddress] = useState(true);

  const caseDetail = useSelector((item: IState) => item.dpadpe.caseDetail);

  useEffect(() => {
    if (emailConsumer) {
      setFieldValue('email', emailConsumer);
      setFieldValue('interestedEmail', emailConsumer);
    }
  }, [emailConsumer]);

  const getInfoCep = async (cep: string) => {
    try {
      const responseCep: any = await getCepService(cep);

      if (!responseCep.erro) {
        setFieldValue('street', responseCep?.logradouro);
        setFieldValue(
          'state',
          responseCep?.localidade + (responseCep?.uf ? `/${responseCep?.uf}` : ''),
        );
        setFieldValue('neighborhood', responseCep?.bairro);
        setFieldValue('complement', responseCep?.complemento);
        setFieldValue('city', responseCep?.localidade);

        if (
          !responseCep?.logradouro ||
          !responseCep?.localidade ||
          !responseCep?.uf ||
          !responseCep?.bairro
        )
          setLockAddress(false);
      }
    } catch (e: any) {
      setFieldValue('street', '');
      setFieldValue('state', '');
      setFieldValue('neighborhood', '');
      setFieldValue('complement', '');
      setFieldValue('city', '');

      if (e.data.type === 'NOT_ALLOWED_CEP') {
        setMessageModalErroCep(e.data.message);
        setIsVisibleModalErroCep(true);
        setShouldBackPanel(true);
        return;
      }

      if (e.data.type === 'LOCATION_INCONSISTENT_CEP') {
        setMessageModalErroCep(e.data.message);
        setIsVisibleModalErroCep(true);
        return;
      }

      setMessageModalErroCep(
        'Houve um erro ao consultar o CEP, por favor digite o endereço manualmente',
      );
      setIsVisibleModalErroCep(true);
      setLockAddress(false);
    }
  };

  useEffect(() => {
    if (values?.cep.length > 8) {
      getInfoCep(values?.cep);
    }
  }, [values?.cep]);

  return (
    <>
      <Modal v3 newStyle type="info" open={isVisibleModalErroCep} title="OPS!">
        <ErrorsTextModal>{messageModalErroCep}</ErrorsTextModal>
        <Margin height={32} />
        <Button
          backgroundColor={Color.blueIntense}
          onClick={() =>
            shouldBackPanel
              ? Navigation.navigate(RouteName.ENTERPRISE_VIABILITY)
              : setIsVisibleModalErroCep(false)
          }
        >
          Entendi
        </Button>
      </Modal>
      <Margin height={5} />
      <TitleStep title="Dados do empreendimento" />
      <Margin height={24} />

      <SubTitle style={{ marginTop: 20 }}>
        Nesta fase serão solicitados os dados do proprietário e do empreendimento.
        Note que todo campo com o sinal * (asterisco) deverá obrigatoriamente ser
        preenchido.
      </SubTitle>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          padding: '0 80px',
          marginTop: 20,
        }}
      >
        <Row>
          <div style={{ flex: 1 }}>
            <FieldInput
              disabled={isDisabled}
              label="Proprietário*"
              value={caseDetail?.selectedCase ? values.owner : undefined}
              name="owner"
              maxLength={40}
            />
          </div>

          <div style={{ width: 40 }} />

          <div style={{ flex: 1 }}>
            <FieldInput
              disabled={isDisabled}
              label="Email*"
              name="email"
              value={caseDetail?.selectedCase ? values?.email : undefined}
            />
          </div>
        </Row>
        <Row>
          <div style={{ flex: 1 }}>
            <FieldInput
              disabled={isDisabled}
              label="CPF/CNPJ*"
              name="cpf_cnpj"
              value={
                caseDetail?.selectedCase
                  ? formatter.cpfCnpjMask(values?.cpf_cnpj)
                  : undefined
              }
            />
          </div>

          <div style={{ width: 40 }} />

          <div style={{ flex: 1 }}>
            <FieldInput
              disabled={isDisabled}
              label="CEP*"
              name="cep"
              value={
                caseDetail?.selectedCase ? formatter.cepMask(values?.cep) : undefined
              }
              maxLength={9}
            />
          </div>
        </Row>
        <Row>
          <div style={{ flex: 1 }}>
            <FieldInput
              disabled={isDisabled || lockAddress}
              label="Logradouro*"
              name="street"
              value={caseDetail?.selectedCase ? values?.street : undefined}
              maxLength={30}
            />
          </div>
          <div style={{ width: 40 }} />
          <div style={{ flex: 1 }}>
            <FieldInput
              disabled={isDisabled || lockAddress}
              label="Localidade*"
              name="state"
              value={caseDetail?.selectedCase ? values?.state : undefined}
            />
          </div>
        </Row>

        <Row>
          <div style={{ flex: 1 }}>
            <FieldInput
              disabled={isDisabled || lockAddress}
              label="Município*"
              name="city"
              value={
                caseDetail?.selectedCase ? caseDetail?.enterprise?.city : undefined
              }
            />
          </div>
          <div style={{ width: 40 }} />
          <div style={{ flex: 1 }}>
            <FieldInput
              disabled={isDisabled || lockAddress}
              label="Bairro*"
              name="neighborhood"
              value={caseDetail?.selectedCase ? values?.neighborhood : undefined}
              maxLength={30}
            />
          </div>
        </Row>

        <Row>
          <div style={{ flex: 1 }}>
            <FieldInput
              disabled={isDisabled}
              label="Número*"
              name="number"
              value={caseDetail?.selectedCase ? values?.number : undefined}
              maxLength={9}
            />
          </div>

          <div style={{ width: 40 }} />

          <div style={{ flex: 1 }}>
            <FieldInput
              disabled={isDisabled}
              label="Complemento"
              name="complement"
              value={
                caseDetail?.selectedCase
                  ? caseDetail?.enterprise?.complement
                  : undefined
              }
              maxLength={30}
            />
          </div>
        </Row>
      </div>
    </>
  );
};

export default InitialStep;
