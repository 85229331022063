export enum RepositoriesTypesSocialTariff {
  SOCIAL_TARIFF_GET_METHOD_REQUEST = '@repositories/ORDER_SERVICES_GET_METHOD_REQUEST',
  SOCIAL_TARIFF_GET_METHOD_SUCCESS = '@repositories/SOCIAL_TARIFF_GET_METHOD_SUCCESS',
  SOCIAL_TARIFF_GET_METHOD_FAILURE = '@repositories/SOCIAL_TARIFF_GET_METHOD_FAILURE',

  SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_REQUEST = '@repositories/SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_REQUEST',
  SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_SUCCESS = '@repositories/SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_SUCCESS',
  SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_FAILURE = '@repositories/SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_FAILURE',
  SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_CLOSE = '@repositories/SOCIAL_TARIFF_POST_ATTACHMENTS_METHOD_CLOSE',

  SOCIAL_TARIFF_GET_RENOVATION_METHOD_REQUEST = '@repositories/SOCIAL_TARIFF_GET_RENOVATION_METHOD_REQUEST',
  SOCIAL_TARIFF_GET_RENOVATION_METHOD_SUCCESS = '@repositories/SOCIAL_TARIFF_GET_RENOVATION_METHOD_SUCCESS',

  SOCIAL_TARIFF_VIEWED_BANNER_METHOD_SUCCESS = '@repositories/SOCIAL_TARIFF_VIEWED_BANNER_METHOD_SUCCESS',
}

export interface IResponseGetSocialTariff {
  protocol: string;
  status: string;
  category: string;
  createdDate: string;
  closedDate: string | null;
}

export interface IResponseGetRenovationSocialTariff {
  needRenovation: boolean;
}

export interface IRequestPostAttachments {
  file: File;
  name: string;
}

export interface IResponsePostAttachments {
  url: string;
  key: string;
  label: string;
}

export interface IRequestPostCreate {
  enrollment: string;
  description: string;
  files: any;
}

export interface IResponsePostCreate {
  protocol: string;
}

export interface IRepositoriesStateSocialTariff {
  readonly data: IResponseGetSocialTariff | null;
  readonly dataAttachments: IResponsePostAttachments[] | null;
  readonly dataCreate: IResponsePostCreate | null;
  readonly error: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorTypes: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorAttachments: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loading: boolean;
  readonly loadingRenovation: boolean;
  readonly loadingTypes: boolean;
  readonly loadingAttachments: boolean;
  readonly successCreate: boolean;
  readonly needRenovation: boolean;
  readonly viewedBanner: boolean;
}
