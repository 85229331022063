/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';

import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Routes from '../routes';
import { history, store, persistor } from '../store';
import AlertCookies from '~/components/AlertCookies';

export default function () {
  const [showModalConsent, setShowModalConsent] = useState(false);

  useEffect(() => {
    const hasConsent = localStorage.getItem('cookieConsent');
    setShowModalConsent(!hasConsent);
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShowModalConsent(false);
  };

  const handleDecline = () => {
    localStorage.removeItem('cookieConsent');
    setShowModalConsent(false);
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <>
            <Routes />
            {showModalConsent && (
              <AlertCookies onAccept={handleAccept} onDecline={handleDecline} />
            )}
          </>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}
