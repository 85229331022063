/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import { useDispatch, useSelector } from 'react-redux';
import { Button } from '~/common/Button';
import { ButtonLink, InputToken } from '~/common';
import { Margin } from '~/components/Margin';
import { IState } from '~/store/modules/types';
import formatter from '~/utils/formatter';
import { Main, ResendTextStyled, ContainerInputs, NavWrapper, Text } from './styles';
import {
  postCloseErrorEnrollmentsValidateSmsRegister,
  postEnrollmentsUsersSendRecoveryRequest,
  postEnrollmentsUsersSendRecoveryRecoveryRequest,
  postCloseEnrollmentsUsersSendRecovery,
} from '~/store/modules/enrollmentsUsers/actions';
import { Card } from '~/common/Card';
import Navigation from '~/utils/Navigation';
import { history } from '~/store';
import { IRequestSendRecovery } from '~/store/modules/enrollmentsUsers/types';
import WarningNumberAttemptsExceeded from '~/components/WarningNumberAttemptsExceeded';
import Modal from '~/components/Modal';
import ModalError from '~/components/ModalError';

const ForgotPasswordCheckCode: React.FC = () => {
  const dataHistory = history.location.state as IRequestSendRecovery;

  const dispatch = useDispatch();

  const loading = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingValidateSmsRegister,
  );
  const loadingSendCodeRecovery = useSelector(
    (item: IState) => item.enrollmentsUsers.loadingSendCodeRecovery,
  );
  const dataSendCode = useSelector(
    (item: IState) => item.enrollmentsUsers.dataSendCode,
  );
  const errorSendCodeRecovery = useSelector(
    (item: IState) => item.enrollmentsUsers.errorSendCodeRecovery,
  );
  const error = useSelector(
    (item: IState) => item.enrollmentsUsers.errorValidateSmsRegister,
  );
  const [code, setCode] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const [tokenSent, setTokenSent] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(dataSendCode?.expiresDate || 60);
  const errorNumberAttemptsExceeded =
    error?.status && error?.message === 'EXCESS_ATTEMPTS';

  useEffect(() => {
    dispatch(postCloseErrorEnrollmentsValidateSmsRegister());
  }, []);

  useEffect(() => {
    if (code.length >= 6 && !errorNumberAttemptsExceeded) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [code, errorNumberAttemptsExceeded]);

  useEffect(() => {
    const interval: any = setInterval(() => {
      if (tokenSent && counter > 0) {
        return setCounter(counter - 1);
      }
      return setTokenSent(false);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [tokenSent, counter]);

  const handleClick = async () => {
    await dispatch(
      postEnrollmentsUsersSendRecoveryRequest({
        cnp: dataHistory.cnp || '',
        shippingChannel: dataHistory.shippingChannel,
      }),
    );

    setTokenSent(true);
    setCounter(dataSendCode?.expiresDate || 60);
  };

  return (
    <Main>
      <Modal open={!!errorSendCodeRecovery?.state} type="error" title="Ops!" v3>
        <ModalError
          onClose={() => dispatch(postCloseEnrollmentsUsersSendRecovery())}
        />
      </Modal>
      <Card
        title={
          dataHistory && dataHistory?.shippingChannel === 'email'
            ? 'Confirmação via e-mail'
            : 'Confirmação via SMS'
        }
      >
        <Text>
          {dataHistory && dataHistory?.shippingChannel === 'email'
            ? 'Para seguir, favor informar o código de confirmação enviado para seu email.'
            : 'Para seguir, favor informar o código de confirmação enviado para o número.'}
        </Text>
        <Margin height={40} />
        <ContainerInputs>
          <InputToken
            valueLength={6}
            value={code}
            handleChange={(e) => setCode(formatter.formatarNumber(e) || '')}
            error={error && error.state ? 'Código incorreto' : ''}
          />
        </ContainerInputs>
        <Margin height={38} />
        {errorNumberAttemptsExceeded ? (
          <WarningNumberAttemptsExceeded onClick={handleClick} />
        ) : (
          <ResendTextStyled>
            Caso não tenha recebido o{' '}
            {dataHistory && dataHistory?.shippingChannel === 'email'
              ? 'e-mail'
              : 'SMS'}
            , clique em: <br />
            <ButtonLink
              type="button"
              onClick={handleClick}
              disabled={tokenSent}
              style={{ textDecoration: tokenSent ? 'none' : 'underline' }}
            >
              {tokenSent ? `Tentar novamente em ${counter}s` : 'Reenviar código'}
            </ButtonLink>
          </ResendTextStyled>
        )}
        <Margin height={38} />
        <NavWrapper>
          <Button
            onClick={() => {
              if (disabled || loading) return;
              ReactGA.event({
                category: 'Expiração de senha',
                action: '[Expiração de Senha][WEB][Código de Confirmação] Continuar',
              });

              dispatch(
                postEnrollmentsUsersSendRecoveryRecoveryRequest(
                  {
                    cnp: dataHistory.cnp,
                    token: code,
                  },
                  true,
                ),
              );
            }}
            loading={!!(loading || loadingSendCodeRecovery)}
            disabled={disabled}
          >
            Continuar
          </Button>
          <ButtonLink type="button" color="gray" onClick={() => Navigation.goBack()}>
            Voltar
          </ButtonLink>
        </NavWrapper>
      </Card>
    </Main>
  );
};

export default ForgotPasswordCheckCode;
