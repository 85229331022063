import { IRequestCreateNotification } from '~/store/modules/notifications/types';
import { api } from './api';

const getListNotificationsService = () => {
  return new Promise((resolve, reject) => {
    try {
      const getListNotifications = async () => {
        const response = await api.get('v3/notifications');
        return response.data.reverse();
      };

      resolve(getListNotifications());
    } catch (error) {
      reject(error);
    }
  });
};

const postCreateNotificationService = (data: IRequestCreateNotification) => {
  return new Promise((resolve, reject) => {
    try {
      const postCreateNotification = async () => {
        const response = await api.post('v3/notifications', { ...data });
        return response.data;
      };

      resolve(postCreateNotification());
    } catch (error) {
      reject(error);
    }
  });
};

const putUpdateNotificationService = (id: number) => {
  return new Promise((resolve, reject) => {
    try {
      const putUpdateNotification = async () => {
        const response = await api.put(`v3/notifications/${id}`, { is_read: true });
        return response.data;
      };

      resolve(putUpdateNotification());
    } catch (error) {
      reject(error);
    }
  });
};

const putUpdateAllNotificationsService = () => {
  return new Promise((resolve, reject) => {
    try {
      const putUpdateAllNotifications = async () => {
        const response = await api.put(`v3/notifications`, { is_read: true });
        return response.data;
      };

      resolve(putUpdateAllNotifications());
    } catch (error) {
      reject(error);
    }
  });
};

const deleteNotificationService = (id: number) => {
  return new Promise((resolve, reject) => {
    try {
      const deleteNotification = async () => {
        const response = await api.delete(`v3/notifications/${id}`);
        return response.data;
      };

      resolve(deleteNotification());
    } catch (error) {
      reject(error);
    }
  });
};

export {
  getListNotificationsService,
  postCreateNotificationService,
  putUpdateNotificationService,
  putUpdateAllNotificationsService,
  deleteNotificationService,
};
