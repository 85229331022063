import moment from 'moment';
import React, { FC } from 'react';
import { Margin } from '~/components/Margin';
import { IOrderService } from '~/store/modules/orderServices/types';
import { Container, ContainerDates, Text } from './styles';
import { OrderStatusSection } from '..';

const Protocol: FC<IOrderService> = ({
  status,
  createdDate,
  closedDate,
  protocol,
}) => {
  return (
    <Container>
      <Text>
        O número do seu protocolo é: <br /> <b>{protocol}</b>
      </Text>
      <Margin height={24} />
      <OrderStatusSection status={status} />
      <Margin height={17} />
      <Margin height={24} />
      <ContainerDates
        style={{ justifyContent: closedDate ? 'space-between' : 'center' }}
      >
        <Text>
          Data de solicitação
          <br /> <b>{moment(createdDate).format('DD/MM/YYYY')}</b>
        </Text>
        {closedDate && (
          <Text>
            Data de {status.label === 'Encerrado' ? 'encerramento' : 'conclusão'}{' '}
            <br /> <b>{moment(closedDate).format('DD/MM/YYYY')}</b>
          </Text>
        )}
      </ContainerDates>
    </Container>
  );
};

export default Protocol;
