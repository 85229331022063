/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */
import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import creditCardType from 'credit-card-type';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import {
  Main,
  Card,
  ContainerBreadCrumb,
  ContainerButtons,
  Border,
  ContainerBorder,
  ContainerAmount,
  ContainerPayment,
  BoxDivider,
  ContainerCardTemplate,
  LabelPaymentMethods,
  ContainerPix,
  ContentSinglePayment,
  AlertFraudBox,
} from './styles';
import CreditCardTemplate from './CardTemplate';

import { ButtonText } from '~/components/ButtonText';
import { BreadCrumb } from '~/components/BreadCrumb';
import { Input } from '~/components/Input';
import { InputSelect } from '~/components/InputSelect';
import { Margin } from '~/components/Margin';
import Modal from '~/components/Modal';
import { Breakpoints, Color } from '~/styles';
import Navigation from '~/utils/Navigation';
import { RouteName } from '~/routes/Routes.name';
import formatter from '~/utils/formatter';
import { history } from '~/store';

import { IState } from '~/store/modules/types';
import { CopyCode } from './CopyCode';
import { DescriptionPayment } from './DescriptionPayment';
import {
  getEnrollmentsInvoicesDetailRequest,
  getEnrollmentsInvoicesDownloadRequest,
  getEnrollmentsInvoicesGroupRequest,
  getErrorCloseEnrollmentsInvoicesDownload,
} from '~/store/modules/enrollmentsInvoices/actions';
import LoadingComponent from '~/components/Loading';
import { PaymentMethods } from './PaymentMethods';
import { QrCode } from './QrCode';
import ModalError from './ModalError';
import {
  postEnrollmentsPaymentRequest,
  postErrorCloseEnrollmentsPayment,
} from '~/store/modules/enrollmentsPayment/actions';
import { checkToggle } from '~/utils/toggles';
import { ContainerSinglePayment, PaymentButton } from '../Invoices/styles';
import { PaymentIcon } from '../Invoices/icons';
import AlertPixFraud from '../../components/AlertPixFraud';
import WarningBox from '~/components/WarningBox';

interface IInstallment {
  label?: string;
  value?: number;
}

interface IError {
  message: string;
  error: boolean;
}

interface IErrors {
  number: IError;
  validity: IError;
}

const SimplifiedInvoicePayments: FC = () => {
  const invoiceDetail = history.location.state as any;

  const isMobile = useMediaQuery({ maxWidth: Breakpoints.mobile.max });
  const isMobileTablet = useMediaQuery({ maxWidth: Breakpoints.tablet.max });

  const dispatch = useDispatch();

  const loading = useSelector((item: IState) => item?.enrollmentsPayment?.loading);
  const loadingDownload = useSelector(
    (item: IState) => item.enrollmentsInvoices.loadingDownload,
  );
  const loadingDetailInvoice = useSelector(
    (item: IState) => item.enrollmentsInvoices.loadingDetail,
  );
  const error = useSelector((item: IState) => item.enrollmentsPayment.error);
  const toggles = useSelector((item: IState) => item.togglesEnrollment.data);
  const registration = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck?.enrollment,
  );
  const infoGroup = useSelector(
    (item: IState) => item?.enrollmentsInvoices?.data?.info,
  );
  const registrationCheck = useSelector(
    (item: IState) => item.enrollmentsAuth.registrationCheck,
  );

  const [installments, setInstallments] = useState<IInstallment[]>([]);
  const [dataPayment, setDataPayment] = useState<any>({
    installment: '',
    cardNumber: '',
    cardHolder: '',
    validity: '',
    securityCode: '',
  });
  const [brandCard, setBrandCard] = useState<string>('');
  const [errors, setErrors] = useState<IErrors>({
    number: {
      error: false,
      message: 'Bandeira não aceita',
    },
    validity: {
      error: false,
      message: 'Validade do cartão inválida',
    },
  });
  const [disabled, setDisabled] = useState<boolean>(true);
  const [codeVisible, setCodeVisible] = useState<boolean>(false);
  const [typePayment, setTypePayment] = useState<'credit-card' | 'pix' | 'billet'>(
    'pix',
  );
  const [open, setOpen] = useState<boolean>(false);
  const [braspagExists, setBraspagExists] = useState<boolean>(false);
  const [groupPayment, setGroupPayment] = useState<boolean>(true);

  const hasAlert = !!(
    registrationCheck &&
    registrationCheck?.operation?.company_name &&
    registrationCheck?.operation?.cnp
  );

  const verifyToggles = async () => {
    setBraspagExists(await checkToggle(toggles, 'BRASPAG'));
    setGroupPayment(await checkToggle(toggles, 'PAGAMENTO_AGRUPADO'));
  };

  useEffect(() => {
    verifyToggles();
  }, [toggles]);

  useEffect(() => {
    const array: IInstallment[] = [
      {
        label: 'Selecione',
        value: undefined,
      },
    ];

    for (
      let index = 0;
      index < invoiceDetail?.possible_quantity_installments;
      index++
    ) {
      array.push({
        label: `${index + 1} x sem juros`,
        value: index + 1,
      });
    }
    setInstallments(array || []);
    dispatch(postErrorCloseEnrollmentsPayment());
    dispatch(getErrorCloseEnrollmentsInvoicesDownload());

    ReactGA.event({
      category: 'Pagamento de fatura',
      action: '“[AL][Web][Pagamento de Fatura]Escolher Pix”',
    });
  }, []);

  const ALLOWED_CARDS = [
    {
      brand: null,
      issuer: 'default',
    },
    {
      brand: 'Master',
      issuer: 'mastercard',
    },
    {
      brand: 'Visa',
      issuer: 'visa',
    },
    {
      brand: 'Hiper',
      issuer: 'hipercard',
    },
    {
      brand: 'Elo',
      issuer: 'elo',
    },
  ];

  const findBrand = () => {
    let brandType = creditCardType(
      (dataPayment.cardNumber || '').replace(/\D/g, ''),
    ) as any;

    if (brandType.length > 1) brandType = [{ type: 'undefined' }];
    else if (brandType.length >= 0) brandType = brandType[0];

    const brand = ALLOWED_CARDS.find(
      (itemBrand) => itemBrand.issuer === brandType?.type,
    );

    if (brand?.brand) setBrandCard(brand.issuer);
    else setBrandCard('');
  };

  useEffect(() => {
    findBrand();
  }, [dataPayment.cardNumber]);

  useEffect(() => {
    if (
      dataPayment.installment &&
      dataPayment.cardNumber &&
      dataPayment.cardHolder &&
      dataPayment.validity &&
      dataPayment.securityCode &&
      !errors.number.error &&
      !errors.validity.error
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [dataPayment, errors]);

  const validateCardNumber = () => {
    if (
      (dataPayment.cardNumber && dataPayment.cardNumber.length < 19) ||
      (dataPayment.cardNumber && dataPayment.cardNumber.length > 0 && !brandCard)
    ) {
      setErrors(({ number, validity }: any) => ({
        number: { ...number, error: true },
        validity,
      }));

      return false;
    }
    setErrors(({ number, validity }: any) => ({
      number: { ...number, error: false },
      validity,
    }));

    return true;
  };

  const validateCardValidity = () => {
    if (dataPayment.validity && dataPayment.validity.length < 7) {
      setErrors(({ number, validity }: any) => ({
        number,
        validity: { ...validity, error: true },
      }));

      return false;
    }
    setErrors(({ number, validity }: any) => ({
      number,
      validity: { ...validity, error: false },
    }));

    return true;
  };

  useEffect(() => {
    if (error) {
      if (invoiceDetail?.typeView === 'detail') {
        ReactGA.event({
          category: 'Pagamento',
          action:
            '[QD][C4][WEB] – Cartão – Pg. de uma única dívida - Recusa no Pagamento',
        });
      } else {
        ReactGA.event({
          category: 'Pagamento Único',
          action: '[QD][C4][WEB] – Cartão – PG. Único - Recusa no Pagamento',
        });
      }
    }
  }, [error]);

  const backNavigation = () => Navigation.goBack();

  const links = [
    {
      label: 'Home',
      active: false,
      onClick: () => Navigation.navigate(RouteName.HOME),
    },
    {
      label: 'Faturas',
      active: false,
      onClick: () => Navigation.navigate(RouteName.INVOICES),
    },
    {
      label: 'Fatura completa',
      active: false,
      onClick: () =>
        dispatch(getEnrollmentsInvoicesDetailRequest(invoiceDetail?.invoice_id)),
    },
    {
      label: 'Pagamento de fatura',
      active: true,
    },
  ];

  const errorMessage = () => {
    switch (error?.message) {
      case 'INVALID_DATA':
        return 'Dados inválidos';
      case 'UNAUTHORIZED_TRANSACTION':
        return 'Transação não autorizada';
      case 'UNAUTHORIZED_TRANSACTION_TRY_AGAIN_LATER':
        return 'Transação não concluída';
      case 'UNAUTHORIZED_TRANSACTION_CONTACT_THE_OPERATOR':
        return 'Transação não concluída';
      default:
        return 'Transação não concluída';
    }
  };

  useEffect(() => {
    if (error && error?.state) {
      ReactGA.event({
        category: 'Pagamento de fatura',
        action: '[AL][Web][Fatura Detalhada][Pgto Cartão]Pagamento sem Sucesso',
      });

      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [error]);

  return (
    <Main>
      {open && (
        <Modal open={open} type="error" title={errorMessage()}>
          <ModalError />
        </Modal>
      )}
      {loading && <LoadingComponent labelWait="Processando..." />}
      {loadingDownload && <LoadingComponent labelWait="Baixando..." />}
      {loadingDetailInvoice && <LoadingComponent labelWait="Carregando..." />}
      <ContainerBreadCrumb>
        <BreadCrumb links={links} />
      </ContainerBreadCrumb>
      <Card>
        <h1>Pagamento de fatura</h1>
        <Margin height={24} />
        <ContainerBorder>
          <Border />
          <h2>Dados de pagamento</h2>
          <Border />
        </ContainerBorder>
        <Margin height={24} />
        <LabelPaymentMethods>Selecione a forma de pagamento:</LabelPaymentMethods>
        <PaymentMethods type={typePayment} setType={setTypePayment} />
        <ContainerAmount>
          <p>Valor total: {formatter.formatCurrency(invoiceDetail?.amount)}</p>
        </ContainerAmount>
        <Margin height={24} />
        {typePayment === 'credit-card' && (
          <>
            {infoGroup && infoGroup?.opened > 1 && groupPayment && (
              <ContentSinglePayment>
                <ContainerSinglePayment>
                  <div>
                    <p>Não perca essa chance!</p>
                    <p>
                      Você ainda pode quitar todos seus débitos em apenas uma
                      transação e <br /> até 12X sem juros no cartão de crédito.
                    </p>
                  </div>
                  <PaymentButton
                    type="button"
                    onClick={() => {
                      ReactGA.event({
                        category: 'Pagamento Único',
                        action: '[AL][Web][Home][Pagamento de Fatura]Pg.Único',
                      });
                      dispatch(getEnrollmentsInvoicesGroupRequest());
                    }}
                  >
                    Pagamento único
                    <PaymentIcon />
                  </PaymentButton>
                </ContainerSinglePayment>
              </ContentSinglePayment>
            )}
            <ContainerPayment>
              <BoxDivider>
                <ContainerCardTemplate>
                  <CreditCardTemplate
                    holder={dataPayment.cardHolder}
                    cardNumber={dataPayment.cardNumber}
                    securityCode={dataPayment.securityCode}
                    validity={dataPayment.validity}
                    issuer={brandCard}
                  />
                </ContainerCardTemplate>
              </BoxDivider>
              <BoxDivider>
                <div className="container-inputs">
                  <InputSelect
                    label="Parcelas"
                    name="installment"
                    values={installments}
                    onChange={(ev) => {
                      setDataPayment({
                        ...dataPayment,
                        installment: ev.target.value,
                      });
                    }}
                    errorPosition="bottom"
                  />
                </div>
                <div className="container-inputs">
                  <Input
                    label="Número do cartão"
                    name="cardNumber"
                    value={dataPayment.cardNumber}
                    onChange={(ev) =>
                      setDataPayment({
                        ...dataPayment,
                        cardNumber: formatter.creditCard(ev.target.value) || '',
                      })
                    }
                    onBlur={validateCardNumber}
                    errorText={errors.number.message}
                    error={errors.number.error}
                    errorPosition="bottom"
                    maxLength={19}
                  />
                </div>
                <div className="container-inputs">
                  <Input
                    label="Nome do titular"
                    name="cardHolder"
                    value={dataPayment.cardHolder}
                    onChange={(ev) =>
                      setDataPayment({
                        ...dataPayment,
                        cardHolder: ev.target.value,
                      })
                    }
                  />
                </div>
                <div className="container-inputs container-inputs-box">
                  <Input
                    label="Validade"
                    name="validity"
                    value={dataPayment.validity}
                    onChange={(ev) =>
                      setDataPayment({
                        ...dataPayment,
                        validity: formatter.creditCardValidity(ev.target.value),
                      })
                    }
                    onBlur={validateCardValidity}
                    errorText={errors.validity.message}
                    error={errors.validity.error}
                    errorPosition="bottom"
                    placeholder="mm/aaaa"
                  />
                  <Input
                    label={isMobile ? 'Cód. de segurança' : 'Código de segurança'}
                    name="securityCode"
                    value={dataPayment.securityCode}
                    onChange={(ev) =>
                      setDataPayment({
                        ...dataPayment,
                        securityCode: formatter.formatarNumber(ev.target.value),
                      })
                    }
                    errorPosition="bottom"
                    maxLength={3}
                    placeholder="000"
                    type={!codeVisible ? 'password' : 'text'}
                    iconPassword
                    passwordVisible={codeVisible}
                    setPasswordVisible={setCodeVisible}
                  />
                </div>
              </BoxDivider>
            </ContainerPayment>
          </>
        )}
        {typePayment === 'pix' && (
          <>
            <ContainerPix>
              <QrCode qr_code_key={invoiceDetail?.qr_code_key} />
              {isMobileTablet && hasAlert && (
                <AlertFraudBox>
                  <AlertPixFraud
                    company={registrationCheck?.operation?.company_name}
                    cnpj={registrationCheck?.operation?.cnp}
                  />
                </AlertFraudBox>
              )}
              <DescriptionPayment type={typePayment} />
            </ContainerPix>
            {!isMobileTablet && hasAlert && (
              <AlertFraudBox>
                <AlertPixFraud
                  company={registrationCheck?.operation?.company_name}
                  cnpj={registrationCheck?.operation?.cnp}
                />
              </AlertFraudBox>
            )}
            <CopyCode code_copy={invoiceDetail?.qr_code_key} type={typePayment} />
          </>
        )}
        {typePayment === 'billet' && (
          <>
            <DescriptionPayment type={typePayment} />
            <CopyCode
              code_copy={invoiceDetail?.bar_code_number}
              type={typePayment}
            />
          </>
        )}
        <Margin height={24} />
        <WarningBox label="Ao realizar o pagamento de faturas vencidas, os juros e multa serão calculados e cobrados na sua próxima fatura." />
        <ContainerButtons>
          <ButtonText text="Voltar" typeBtn="content" onClick={backNavigation} />
          {typePayment === 'credit-card' ? (
            <ButtonText
              typeBtn="content"
              backgroundColor={disabled ? Color.gray : Color.blueSeconde}
              onClick={() => {
                if (disabled || loading) return;

                ReactGA.event({
                  category: 'Pagamento de fatura',
                  action:
                    '[AL][Web][Home][Pagamento de Fatura][Pgto Cartão]Confirmar pagamento',
                });

                dispatch(
                  postEnrollmentsPaymentRequest(
                    {
                      enrollmentId: registration || '',
                      invoiceId: invoiceDetail?.invoice_id.toString(),
                      paymentCardNumber: dataPayment?.cardNumber,
                      paymentCardHolder: dataPayment?.cardHolder,
                      paymentExpirationDate: dataPayment?.validity,
                      paymentSecurityCode: dataPayment?.securityCode,
                      paymentBrand: brandCard,
                      installments: dataPayment?.installment,
                    },
                    braspagExists,
                  ),
                );
              }}
              loading={loading}
              nextIcon
            >
              Confirmar pagamento
            </ButtonText>
          ) : (
            <ButtonText
              typeBtn="content"
              backgroundColor={Color.blueSeconde}
              onClick={() => {
                ReactGA.event({
                  category: 'Pagamento de fatura',
                  action:
                    '[AL][Web][Home][Pagamento de Fatura]Baixar boleto simplificado',
                });

                dispatch(
                  getEnrollmentsInvoicesDownloadRequest(invoiceDetail?.invoice_id),
                );
              }}
              loading={loading}
            >
              Baixar boleto de pagamento
            </ButtonText>
          )}
        </ContainerButtons>
      </Card>
    </Main>
  );
};

export default SimplifiedInvoicePayments;
