export enum RepositoriesTypesOutsides {
  OUTSIDES_EASY_INVOICES_GET_METHOD_REQUEST = '@repositories/OUTSIDES_EASY_INVOICES_GET_METHOD_REQUEST',
  OUTSIDES_EASY_INVOICES_GET_METHOD_SUCCESS = '@repositories/OUTSIDES_EASY_INVOICES_GET_METHOD_SUCCESS',
  OUTSIDES_EASY_INVOICES_GET_METHOD_FAILURE = '@repositories/OUTSIDES_EASY_INVOICES_GET_METHOD_FAILURE',
  OUTSIDES_EASY_INVOICES_GET_METHOD_FAILURE_CLOSE = '@repositories/OUTSIDES_EASY_INVOICES_GET_METHOD_FAILURE_CLOSE',
  OUTSIDES_EASY_INVOICES_CLEAR = '@repositories/OUTSIDES_EASY_INVOICES_CLEAR',

  OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_REQUEST = '@repositories/OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_REQUEST',
  OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_SUCCESS = '@repositories/OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_SUCCESS',
  OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_FAILURE = '@repositories/OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_FAILURE',
  OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_FAILURE_CLOSE = '@repositories/OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_FAILURE_CLOSE',

  OUTSIDES_POST_INVOICE_PAYMENT_METHOD_REQUEST = '@repositories/OUTSIDES_POST_INVOICE_PAYMENT_METHOD_REQUEST',
  OUTSIDES_POST_INVOICE_PAYMENT_METHOD_SUCCESS = '@repositories/OUTSIDES_POST_INVOICE_PAYMENT_METHOD_SUCCESS',
  OUTSIDES_POST_INVOICE_PAYMENT_METHOD_FAILURE = '@repositories/OUTSIDES_POST_INVOICE_PAYMENT_METHOD_FAILURE',
  OUTSIDES_POST_INVOICE_PAYMENT_METHOD_FAILURE_CLOSE = '@repositories/OUTSIDES_POST_INVOICE_PAYMENT_METHOD_FAILURE_CLOSE',

  OUTSIDES_POST_INVOICE_VOUCHER_METHOD_REQUEST = '@repositories/OUTSIDES_POST_INVOICE_VOUCHER_METHOD_REQUEST',
  OUTSIDES_POST_INVOICE_VOUCHER_METHOD_SUCCESS = '@repositories/OUTSIDES_POST_INVOICE_VOUCHER_METHOD_SUCCESS',
  OUTSIDES_POST_INVOICE_VOUCHER_METHOD_FAILURE = '@repositories/OUTSIDES_POST_INVOICE_VOUCHER_METHOD_FAILURE',
  OUTSIDES_POST_INVOICE_VOUCHER_METHOD_FAILURE_CLOSE = '@repositories/OUTSIDES_POST_INVOICE_VOUCHER_METHOD_FAILURE_CLOSE',

  OUTSIDES_EASY_ACTIVATE_MODAL = '@repositories/OUTSIDES_EASY_ACTIVATE_MODAL',
  OUTSIDES_EASY_CLEAR_MODAL = '@repositories/OUTSIDES_EASY_CLEAR_MODAL',

  OUTSIDES_SALESFORCE_SATISFACTION_ACTIVATE_MODAL = '@repositories/OUTSIDES_SALESFORCE_SATISFACTION_ACTIVATE_MODAL',
  OUTSIDES_SALESFORCE_SATISFACTION_CLEAR_MODAL = '@repositories/OUTSIDES_SALESFORCE_SATISFACTION_CLEAR_MODAL',

  OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_REQUEST = '@repositories/OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_REQUEST',
  OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_SUCCESS = '@repositories/OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_SUCCESS',
  OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_FAILURE = '@repositories/OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_FAILURE',
  OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_FAILURE_CLOSE = '@repositories/OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_FAILURE_CLOSE',
  OUTSIDES_DISCHARGE_DEBTS_CLEAR = '@repositories/OUTSIDES_DISCHARGE_DEBTS_CLEAR',

  OUTSIDES_POST_DISCHARGE_DEBTS_PAYMENT_METHOD_REQUEST = '@repositories/OUTSIDES_POST_DISCHARGE_DEBTS_PAYMENT_METHOD_REQUEST',
  OUTSIDES_POST_DISCHARGE_DEBTS_PAYMENT_METHOD_SUCCESS = '@repositories/OUTSIDES_POST_DISCHARGE_DEBTS_PAYMENT_METHOD_SUCCESS',
  OUTSIDES_POST_DISCHARGE_DEBTS_PAYMENT_METHOD_FAILURE = '@repositories/OUTSIDES_POST_DISCHARGE_DEBTS_PAYMENT_METHOD_FAILURE',
  OUTSIDES_POST_DISCHARGE_DEBTS_PAYMENT_METHOD_FAILURE_CLOSE = '@repositories/OUTSIDES_POST_DISCHARGE_DEBTS_PAYMENT_METHOD_FAILURE_CLOSE',

  OUTSIDES_POST_DISCHARGE_DEBTS_VOUCHER_METHOD_REQUEST = '@repositories/OUTSIDES_POST_DISCHARGE_DEBTS_VOUCHER_METHOD_REQUEST',
  OUTSIDES_POST_DISCHARGE_DEBTS_VOUCHER_METHOD_SUCCESS = '@repositories/OUTSIDES_POST_DISCHARGE_DEBTS_VOUCHER_METHOD_SUCCESS',
  OUTSIDES_POST_DISCHARGE_DEBTS_VOUCHER_METHOD_FAILURE = '@repositories/OUTSIDES_POST_DISCHARGE_DEBTS_VOUCHER_METHOD_FAILURE',
  OUTSIDES_POST_DISCHARGE_DEBTS_VOUCHER_METHOD_FAILURE_CLOSE = '@repositories/OUTSIDES_POST_DISCHARGE_DEBTS_VOUCHER_METHOD_FAILURE_CLOSE',

  OUTSIDES_GET_DATA_UPDATE_METHOD_REQUEST = '@repositories/OUTSIDES_GET_DATA_UPDATE_METHOD_REQUEST',
  OUTSIDES_GET_DATA_UPDATE_METHOD_SUCCESS = '@repositories/OUTSIDES_GET_DATA_UPDATE_METHOD_SUCCESS',
  OUTSIDES_GET_DATA_UPDATE_METHOD_FAILURE = '@repositories/OUTSIDES_GET_DATA_UPDATE_METHOD_FAILURE',
  OUTSIDES_GET_DATA_UPDATE_METHOD_FAILURE_CLOSE = '@repositories/OUTSIDES_GET_DATA_UPDATE_METHOD_FAILURE_CLOSE',

  OUTSIDES_POST_DATA_UPDATE_METHOD_REQUEST = '@repositories/OUTSIDES_POST_DATA_UPDATE_METHOD_REQUEST',
  OUTSIDES_POST_DATA_UPDATE_METHOD_SUCCESS = '@repositories/OUTSIDES_POST_DATA_UPDATE_METHOD_SUCCESS',
  OUTSIDES_POST_DATA_UPDATE_METHOD_FAILURE = '@repositories/OUTSIDES_POST_DATA_UPDATE_METHOD_FAILURE',
  OUTSIDES_POST_DATA_UPDATE_METHOD_FAILURE_CLOSE = '@repositories/OUTSIDES_POST_DATA_UPDATE_METHOD_FAILURE_CLOSE',

  OUTSIDES_EASY_INVOICES_CREDENTIALS_BY_TOKEN_REQUEST = '@repositories/OUTSIDES_EASY_INVOICES_CREDENTIALS_BY_TOKEN_REQUEST',
  OUTSIDES_EASY_INVOICES_CREDENTIALS_BY_TOKEN_SUCCESS = '@repositories/OUTSIDES_EASY_INVOICES_CREDENTIALS_BY_TOKEN_SUCCESS',
  OUTSIDES_EASY_INVOICES_CREDENTIALS_BY_TOKEN_FAILURE = '@repositories/OUTSIDES_EASY_INVOICES_CREDENTIALS_BY_TOKEN_FAILURE',

  OUTSIDES_GET_SOCIAL_TARIFF_ENROLLMENTS_REQUEST = '@repositories/OUTSIDES_GET_SOCIAL_TARIFF_ENROLLMENTS_REQUEST',
  OUTSIDES_GET_SOCIAL_TARIFF_ENROLLMENTS_SUCCESS = '@repositories/OUTSIDES_GET_SOCIAL_TARIFF_ENROLLMENTS_SUCCESS',
  OUTSIDES_GET_SOCIAL_TARIFF_ENROLLMENTS_FAILURE = '@repositories/OUTSIDES_GET_SOCIAL_TARIFF_ENROLLMENTS_FAILURE',
  OUTSIDES_GET_SOCIAL_TARIFF_ENROLLMENTS_FAILURE_CLOSE = '@repositories/OUTSIDES_GET_SOCIAL_TARIFF_ENROLLMENTS_FAILURE_CLOSE',

  OUTSIDES_GET_SOCIAL_TARIFF_VALID_ENROLLMENT_REQUEST = '@repositories/OUTSIDES_GET_SOCIAL_TARIFF_VALID_ENROLLMENT_REQUEST',
  OUTSIDES_GET_SOCIAL_TARIFF_VALID_ENROLLMENT_SUCCESS = '@repositories/OUTSIDES_GET_SOCIAL_TARIFF_VALID_ENROLLMENT_SUCCESS',
  OUTSIDES_GET_SOCIAL_TARIFF_VALID_ENROLLMENT_FAILURE = '@repositories/OUTSIDES_GET_SOCIAL_TARIFF_VALID_ENROLLMENT_FAILURE',
  OUTSIDES_GET_SOCIAL_TARIFF_VALID_ENROLLMENT_FAILURE_CLOSE = '@repositories/OUTSIDES_GET_SOCIAL_TARIFF_VALID_ENROLLMENT_FAILURE_CLOSE',

  OUTSIDES_POST_SOCIAL_TARIFF_REQUEST = '@repositories/OUTSIDES_POST_SOCIAL_TARIFF_REQUEST',
  OUTSIDES_POST_SOCIAL_TARIFF_SUCCESS = '@repositories/OUTSIDES_POST_SOCIAL_TARIFF_SUCCESS',
  OUTSIDES_POST_SOCIAL_TARIFF_FAILURE = '@repositories/OUTSIDES_POST_SOCIAL_TARIFF_FAILURE',
  OUTSIDES_POST_SOCIAL_TARIFF_FAILURE_CLOSE = '@repositories/OUTSIDES_POST_SOCIAL_TARIFF_FAILURE_CLOSE',

  OUTSIDES_DATA_UPDATE_ACTIVATE_MODAL = '@repositories/OUTSIDES_DATA_UPDATE_ACTIVATE_MODAL',
  OUTSIDES_DATA_UPDATE_CLEAR_MODAL = '@repositories/OUTSIDES_DATA_UPDATE_CLEAR_MODAL',

  OUTSIDES_DOWNLOAD_METHOD_REQUEST = '@repositories/OUTSIDES_DOWNLOAD_METHOD_REQUEST',
  OUTSIDES_DOWNLOAD_METHOD_SUCCESS = '@repositories/OUTSIDES_DOWNLOAD_METHOD_SUCCESS',
  OUTSIDES_DOWNLOAD_METHOD_FAILURE = '@repositories/OUTSIDES_DOWNLOAD_METHOD_FAILURE',

  OUTSIDES_ROUTE_REPORT_LACK_WATER_ACTIVATE = '@repositories/OUTSIDES_ROUTE_REPORT_LACK_WATER_ACTIVATE',
  OUTSIDES_ROUTE_REPORT_LACK_WATER_CLEAR = '@repositories/OUTSIDES_ROUTE_REPORT_LACK_WATER_CLEAR',
}

export interface IRequestQueryInvoices {
  page: number;
  size: number;
}

export interface IRequestDischargeDebtsRequest {
  cnp: string;
  recaptchaTokenV3: string;
  recaptchaTokenV2: string;
  params?: IRequestQueryInvoices;
}

export interface IRequestEasyInvoices {
  cnp: string;
  recaptchaTokenV3: string;
  recaptchaTokenV2: string;
  enrollmentId: string;
  params?: IRequestQueryInvoices;
  routeData?: any;
  paramsDischargeDebts?: IRequestQueryInvoices;
}
export interface IEasyInvoices {
  reference: string;
  amount: number;
  invoice_id: number;
  issue_date_hour: string;
  maturity_date: string;
  bar_code_number: number | null;
  pix_code: string | null;
  status: {
    id: number;
    label: string;
  };
}

export interface IResponseInvoicesEasyInvoices {
  invoices: IEasyInvoices[] | [];
  pagination: {
    page: number;
    maxPages: number;
    totalItens: number;
  };
}
export interface IResponseEasyInvoices {
  invoices: IResponseInvoicesEasyInvoices;
  token: string;
}

export interface IResponseInvoiceInstallments {
  totalValue: number;
  installments: number;
  installmentValue: number;
}

export interface IRequestInvoicePayment {
  paymentCardNumber: string;
  paymentCardHolder: string;
  paymentExpirationDate: string;
  paymentSecurityCode: number;
  paymentBrand: string;
  installments: number;
  invoices_ids: Array<number>;
}

export interface IResponseInvoicePayment {
  code: string;
  message: string;
  invoice_id?: number;
  invoice_group_id?: number;
}
interface IInvoiceVoucherDetailItem {
  description: string;
  amount: number;
}

interface IInvoiceVoucherDetail {
  reference: string;
  invoice_id: number;
  maturity_date: string;
  issue_date_hour: string;
  amount: number;
  items: Array<IInvoiceVoucherDetailItem>;
}

export interface IResponseInvoiceVoucher {
  header: {
    name: string;
    cnp: string;
    adrress: string;
    amount_total: number;
    payment_id: string;
    received_date: string;
    type: string;
  };
  detail?: {
    reference: string;
    invoice_id: number;
    maturity_date: string;
    issue_date_hour: string;
    amount: number;
    items: Array<IInvoiceVoucherDetailItem>;
  };
  details?: Array<IInvoiceVoucherDetail>;
}

export interface DebtsStatus {
  id: number;
  label: string;
}

export interface IDadosDebts {
  id: number;
  type: string;
  description: string;
  reference: string;
  amount: number;
  issue_date_hour: string;
  maturity_date: string;
  bar_code_number: string;
  pix_code: any;
  enrollment_id: string;
  status: DebtsStatus;
}

export interface IResponseInvoicesDebts {
  dados: IDadosDebts[];
  pagination: {
    totalItens: number;
    maxPages: number;
    page: number;
  };
}
export interface IResponseDebts {
  invoices: IResponseInvoicesDebts;
  token: string;
}

export interface IRequesDebtsPayment {
  paymentCardNumber: string;
  paymentCardHolder: string;
  paymentExpirationDate: string;
  paymentSecurityCode: number;
  paymentBrand: string;
  installments: number;
  invoices_ids: Array<number>;
  enrollment_id: string;
}

export interface IRegistrationUpdate {
  phone: string;
  email: string;
  company_name: string;
  cnp: string;
  cnp_alert?: string;
}
export interface IDataRegistrationUpdate extends IRegistrationUpdate {
  cnp: string;
  phone: string;
  email: string;
  isUpdatable: boolean;
}
export interface IDataCredentials {
  cnp: string;
  enrollment: string;
}

export interface IResponseSocialTariffEnrollments {
  result: [
    {
      code: string;
      enrollment: string;
      consumer: string;
      address_connection: string;
      address: {
        street: string;
        number: number;
        neighborhood: string;
        zipCode: string;
        complement: string;
        city: string;
        uf: string;
      };
      operation: {
        id: number;
        name: string;
      };
    },
  ];
  totalItens: 1;
}

export interface IRequestSocialTariffValidEnrollment {
  cnp: string;
  enrollment: string;
}

export interface IResponseSocialTariffValidEnrollment {
  result: string;
  done: boolean;
  operation: string;
  renovation: boolean;
}

export interface Holder {
  fullName: string;
  document: string;
  birthDate: string;
  email: string;
  cellphone: string;
}

export interface Residence {
  type: string;
  state: string;
  city: string;
  bond: string;
  zipCode: string;
  neighborhood: string;
  street: string;
  number: string;
  complement?: string;
}
interface File {
  filename: string;
  filedata: string;
}

interface RenovacaoDoBenefcio {
  identification_document: File;
}

interface HabitaoEmFavela {
  identification_document: File;
  housing_declaration: File;
  residents_association_statement: File;
}

interface AreaDeInteresseSocial {
  funding_body_document: File;
  iptu: File;
  identification_document: File;
  housing_declaration: File;
  condominium_act: File;
}

interface ConjuntoHabitacional {
  localization_inspection: File;
  iptu: File;
  identification_document: File;
  housing_declaration: File;
}

interface HabitacaoPopular {
  ceasing_land_document: File;
  housing_declaration: File;
  identification_document: File;
}

interface SolicitaoDeTarifaSocialCuiaba {
  identification_document: File;
  cadunico_updated: File;
  water_bill: File;
  energy_bill: File;
}

export interface IRequestSocialTariff {
  cnp: string;
  enrollment: string;
  holder: Holder;
  residence: Residence;
  files:
    | RenovacaoDoBenefcio
    | HabitaoEmFavela
    | AreaDeInteresseSocial
    | ConjuntoHabitacional
    | HabitacaoPopular
    | SolicitaoDeTarifaSocialCuiaba;
  terms: {
    termsOfResponsibility: boolean;
    termsOfVeracity: boolean;
  };
}

export interface IResponseSocialTariff {
  protocol: string;
  protocolReRegistration?: string;
  protocolExchange?: string;
}

export interface IDataSocialTariffValidEnrollment {
  typeSolicitation: 'default' | 'changeOwnership' | 're-registration';
  renovation: boolean;
  dataEnrollment: any;
}

export interface IRequestQueryaSocialTariff {
  active?: string;
  state?: string;
  state_id?: string;
}

export interface IRepositoriesStateOutsides {
  readonly easyInvoices: IResponseInvoicesEasyInvoices | null;
  readonly dataCredentials: IDataCredentials | null;
  readonly dataInvoiceInstallments: IResponseInvoiceInstallments[] | [];
  readonly dataInvoiceVoucher: IResponseInvoiceVoucher | null;
  readonly dataDischargeDebts: IResponseInvoicesDebts | null;
  readonly dataRegistrationUpdate: IDataRegistrationUpdate | null;
  readonly dataSocialTariffEnrollments: IResponseSocialTariffEnrollments | null;
  readonly dataSocialTariffValidEnrollment: IDataSocialTariffValidEnrollment | null;
  readonly dataSocialTariff: IResponseSocialTariff | null;
  readonly warningSocialTariffValidEnrollment: boolean;
  readonly fromTypeRegistrationUpdate: 'pixCode' | 'barCode' | 'download' | '';
  readonly successRegistrationUpdate: boolean;
  readonly successSocialTariff: boolean;
  readonly loading: boolean;
  readonly loadingInstallments: boolean;
  readonly loadingPayment: boolean;
  readonly loadingDownload: boolean;
  readonly loadingPaymentVoucher: boolean;
  readonly loadingDischargeDebts: boolean;
  readonly loadingRegistrationUpdate: boolean;
  readonly loadingSocialTariffEnrollments: boolean;
  readonly loadingSocialTariffValidEnrollment: boolean;
  readonly loadingSocialTariff: boolean;
  readonly error: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorInstallments: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorPayment: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorDischargeDebts: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorRegistrationUpdate: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorSocialTariffEnrollments: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorSocialTariffValidEnrollment: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorSocialTariff: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly modal: boolean;
  readonly modalSalesforceSatisfaction: boolean;
  readonly modalDataUpdate: boolean;
  readonly reportLackWaterRoute: boolean;
}
