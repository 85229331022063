import styled from 'styled-components/';
import { Breakpoints, Color } from '~/styles';

interface IProps {
  v3?: boolean;
}

export const Main = styled.div<IProps>`
  width: calc(100% - 52px);
  min-height: calc(106px - 40px);
  max-height: calc(106px - 40px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 18px 24px;
  flex: 1;
  border: 2px solid ${Color.yellow};
  border-radius: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 122.5%;
  text-align: left;
  background: ${({ v3 }) => (v3 ? '#f2f2f2' : '#f2f2f2')};

  div:last-of-type {
    margin-top: 0;
    margin-left: 24px;

    p {
      color: #000000;
    }

    p:first-of-type {
      color: ${Color.yellow};
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 122.5%;
      letter-spacing: -0.4px;
    }

    p:last-of-type {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 122.5%;
      letter-spacing: -0.35px;
    }
  }

  @media screen and (max-width: ${Breakpoints.mobile.max}px) {
    div:last-of-type {
      p:last-of-type {
        font-size: 12px;
      }
    }
  }
`;
