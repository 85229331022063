import { GeneralEnumResponseCodeTypes } from '~/store/modules/enumGeneralResponseCode';

interface IError {
  message?: string;
  status: number;
  state: boolean;
}

export const showRecaptchaV2 = (error?: IError) => {
  const showRecaptchaV2 =
    error?.message === GeneralEnumResponseCodeTypes.B021.type ||
    error?.message === GeneralEnumResponseCodeTypes.B022.type ||
    error?.message === GeneralEnumResponseCodeTypes.B023.type ||
    error?.message === GeneralEnumResponseCodeTypes.B024.type ||
    error?.message === GeneralEnumResponseCodeTypes.B025.type;

  return showRecaptchaV2;
};
