import { Reducer } from 'redux';
import {
  RepositoriesTypesEnrollmentsToggles,
  IRepositoriesStateEnrollmentsToggles,
} from './types';

const INITIAL_STATE: IRepositoriesStateEnrollmentsToggles = {
  data: [
    {
      featureToggle: 'LAYOUT_V3_WEB',
      toggle: true,
      appVersion: '1.0.903',
      scope: 'front',
      logged: false,
    },
  ],
  loading: false,
  error: null,
};

const reducer: Reducer<IRepositoriesStateEnrollmentsToggles, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesEnrollmentsToggles.TOGGLES_GET_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesEnrollmentsToggles.TOGGLES_GET_METHOD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: null,
      };
    case RepositoriesTypesEnrollmentsToggles.TOGGLES_GET_METHOD_FAILURE:
      return {
        ...state,
        data: [],
        error: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
        loading: false,
      };

    default:
      return state;
  }
};

export default reducer;
