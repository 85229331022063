import React, { FC } from 'react';

import {
  Container,
  Content,
  ContainerNotification,
  CardNotification,
  ContainerEmptyMessage,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { Margin } from '~/components/Margin';
import { putUpdateNotificationRequest } from '~/store/modules/notifications/actions';
import moment from 'moment';
import EmptyMessage from '~/components/EmptyMessage';

const ModalNotifications: FC = () => {
  const dispatch = useDispatch();

  const code = useSelector(
    (state: IState) => state.enrollmentsAuth.registrationCheck?.code,
  );
  const data = useSelector((state: IState) => state.notifications.data);

  return (
    <Container>
      <Content>
        <h1>Notificações</h1>
        <Margin height={32} />
        <span>
          Matrícula {code} <div id="border" />
        </span>
        {data && data.length > 0 && (
          <ContainerNotification
            style={{ overflowY: data.length > 5 ? 'scroll' : undefined }}
          >
            {data?.map((notification) => (
              <CardNotification
                key={notification.id}
                onClick={() => {
                  if (notification.is_read) return;
                  dispatch(putUpdateNotificationRequest(notification.id));
                }}
                type="button"
                is_read={notification.is_read}
              >
                <div>
                  <p>{notification.is_read ? 'Lida' : 'Nova'}</p>
                  <p>{notification.body}</p>
                </div>
                <p>
                  {moment(notification.createdAt).format('D/MMM')}
                  <br />
                  {moment(notification.createdAt).format('ddd')}
                </p>
              </CardNotification>
            ))}
          </ContainerNotification>
        )}
        <ContainerEmptyMessage>
          {data && data.length > 0 ? (
            <p>
              Essas foram todas as notificações <br /> dos últimos 12 meses
            </p>
          ) : (
            <>
              <Margin height={70} />

              <EmptyMessage marginHeight={15}>
                <p>
                  Você ainda não possui <br /> notificações
                </p>
              </EmptyMessage>
              <Margin height={70} />
            </>
          )}
        </ContainerEmptyMessage>
      </Content>
    </Container>
  );
};

export default ModalNotifications;
