/* eslint-disable prefer-const */
import { Dispatch } from 'redux';
import {
  IResponseListNotifications,
  IResponseUpdateAllNotification,
  IResponseUpdateNotification,
  RepositoriesTypesNotifications,
} from './types';
import {
  getListNotificationsService,
  putUpdateAllNotificationsService,
  putUpdateNotificationService,
} from '~/services/notifications';
import { IState } from '../types';

const activateModalNotifications = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesNotifications.NOTIFICATIONS_ACTIVATE_MODAL,
  });
};

const clearModalNotifications = () => async (dispatch: Dispatch) => {
  return dispatch({
    type: RepositoriesTypesNotifications.NOTIFICATIONS_CLEAR_MODAL,
  });
};

const getListNotificationsRequest = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesNotifications.NOTIFICATIONS_GET_LIST_METHOD_REQUEST,
    });

    const response =
      (await getListNotificationsService()) as IResponseListNotifications;

    return dispatch({
      type: RepositoriesTypesNotifications.NOTIFICATIONS_GET_LIST_METHOD_SUCCESS,
      payload: {
        data: response,
      },
    });
  } catch (error: any) {
    return dispatch({
      type: RepositoriesTypesNotifications.NOTIFICATIONS_GET_LIST_METHOD_FAILURE,
      payload: {
        error: {
          status: error?.response?.status,
          state: true,
          message: error?.response?.data?.type,
        },
      },
    });
  }
};

const getErrorCloseListNotifications = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesNotifications.NOTIFICATIONS_GET_LIST_METHOD_FAILURE_CLOSE,
  });
};

const putUpdateNotificationRequest =
  (id: number) => async (dispatch: Dispatch, getState: any) => {
    try {
      dispatch({
        type: RepositoriesTypesNotifications.NOTIFICATIONS_PUT_ID_METHOD_REQUEST,
      });

      const { notifications } = getState() as IState;

      let array = [...notifications.data];

      const indexPut = array.findIndex((item) => item.id === id);

      const response = (await putUpdateNotificationService(
        id,
      )) as IResponseUpdateNotification;

      array[indexPut] = {
        ...response.data,
      };

      return dispatch({
        type: RepositoriesTypesNotifications.NOTIFICATIONS_PUT_ID_METHOD_SUCCESS,
        payload: {
          data: array,
        },
      });
    } catch (error: any) {
      return dispatch({
        type: RepositoriesTypesNotifications.NOTIFICATIONS_PUT_ID_METHOD_FAILURE_CLOSE,
        payload: {
          error: {
            status: error?.response?.status,
            state: true,
            message: error?.response?.data?.type,
          },
        },
      });
    }
  };

const putErrorCloseUpdateNotification = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesNotifications.NOTIFICATIONS_PUT_ID_METHOD_FAILURE_CLOSE,
  });
};

const putUpdateAllNotificationsRequest = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: RepositoriesTypesNotifications.NOTIFICATIONS_PUT_ALL_METHOD_REQUEST,
    });

    const response =
      (await putUpdateAllNotificationsService()) as IResponseUpdateAllNotification;

    return dispatch({
      type: RepositoriesTypesNotifications.NOTIFICATIONS_PUT_ALL_METHOD_SUCCESS,
      payload: {
        data: response.data.reverse(),
      },
    });
  } catch (error: any) {
    return dispatch({
      type: RepositoriesTypesNotifications.NOTIFICATIONS_PUT_ALL_METHOD_FAILURE,
      payload: {
        error: {
          status: error?.response?.status,
          state: true,
          message: error?.response?.data?.type,
        },
      },
    });
  }
};

const putErrorCloseUpdateAllNotifications = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RepositoriesTypesNotifications.NOTIFICATIONS_PUT_ALL_METHOD_FAILURE_CLOSE,
  });
};

export {
  activateModalNotifications,
  clearModalNotifications,
  getListNotificationsRequest,
  getErrorCloseListNotifications,
  putUpdateNotificationRequest,
  putErrorCloseUpdateNotification,
  putUpdateAllNotificationsRequest,
  putErrorCloseUpdateAllNotifications,
};
