import { Reducer } from 'redux';
import { RepositoriesTypesOutsides, IRepositoriesStateOutsides } from './types';

const INITIAL_STATE: IRepositoriesStateOutsides = {
  easyInvoices: {
    invoices: [],
    pagination: {
      page: 0,
      maxPages: 0,
      totalItens: 0,
    },
  },
  dataInvoiceInstallments: [],
  dataInvoiceVoucher: null,
  dataCredentials: null,
  dataDischargeDebts: {
    dados: [],
    pagination: {
      page: 0,
      maxPages: 0,
      totalItens: 0,
    },
  },
  dataRegistrationUpdate: null,
  dataSocialTariffEnrollments: null,
  dataSocialTariffValidEnrollment: null,
  dataSocialTariff: null,
  warningSocialTariffValidEnrollment: false,
  fromTypeRegistrationUpdate: '',
  successRegistrationUpdate: false,
  successSocialTariff: false,
  loading: false,
  loadingInstallments: false,
  loadingPayment: false,
  loadingDownload: false,
  loadingPaymentVoucher: false,
  loadingDischargeDebts: false,
  loadingRegistrationUpdate: false,
  loadingSocialTariffEnrollments: false,
  loadingSocialTariffValidEnrollment: false,
  loadingSocialTariff: false,
  error: null,
  errorInstallments: null,
  errorPayment: null,
  errorDischargeDebts: null,
  errorRegistrationUpdate: null,
  errorSocialTariffEnrollments: null,
  errorSocialTariffValidEnrollment: null,
  errorSocialTariff: null,
  modal: false,
  modalSalesforceSatisfaction: false,
  modalDataUpdate: false,
  reportLackWaterRoute: false,
};

const reducer: Reducer<IRepositoriesStateOutsides, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_GET_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_GET_METHOD_SUCCESS:
      return {
        ...state,
        easyInvoices: action.payload.data || state.easyInvoices,
        loading: false,
      };
    case RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_GET_METHOD_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_GET_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        error: null,
      };
    case RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_CLEAR:
      return {
        ...state,
        easyInvoices: null,
        loading: false,
      };

    case RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_REQUEST:
      return {
        ...state,
        loadingInstallments: true,
      };
    case RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_SUCCESS:
      return {
        ...state,
        loadingInstallments: false,
        dataInvoiceInstallments: action.payload.data,
      };
    case RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_FAILURE:
      return {
        ...state,
        loadingInstallments: false,
        errorInstallments: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
      };
    case RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_INSTALLMENTS_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        loadingInstallments: false,
        errorInstallments: null,
      };

    case RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        loadingPayment: true,
      };
    case RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loadingPayment: false,
      };
    case RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        loadingPayment: false,
        loadingPaymentVoucher: false,
        errorPayment: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
      };
    case RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_PAYMENT_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        loadingPayment: false,
        loadingPaymentVoucher: false,
        errorPayment: null,
      };

    case RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_VOUCHER_METHOD_REQUEST:
      return {
        ...state,
        loadingPaymentVoucher: true,
      };
    case RepositoriesTypesOutsides.OUTSIDES_POST_INVOICE_VOUCHER_METHOD_SUCCESS:
      return {
        ...state,
        loadingPaymentVoucher: false,
        dataInvoiceVoucher: { ...action.payload.data },
      };

    case RepositoriesTypesOutsides.OUTSIDES_EASY_ACTIVATE_MODAL:
      return {
        ...state,
        modal: true,
      };
    case RepositoriesTypesOutsides.OUTSIDES_EASY_CLEAR_MODAL:
      return {
        ...state,
        modal: false,
      };

    case RepositoriesTypesOutsides.OUTSIDES_SALESFORCE_SATISFACTION_ACTIVATE_MODAL:
      return {
        ...state,
        modalSalesforceSatisfaction: true,
      };
    case RepositoriesTypesOutsides.OUTSIDES_SALESFORCE_SATISFACTION_CLEAR_MODAL:
      return {
        ...state,
        modalSalesforceSatisfaction: false,
      };

    case RepositoriesTypesOutsides.OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_REQUEST:
      return {
        ...state,
        loadingDischargeDebts: true,
      };
    case RepositoriesTypesOutsides.OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_SUCCESS:
      return {
        ...state,
        dataDischargeDebts: action.payload.data || state.easyInvoices,
        loadingDischargeDebts: false,
      };
    case RepositoriesTypesOutsides.OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_FAILURE:
      return {
        ...state,
        errorDischargeDebts: action.payload.error,
        loadingDischargeDebts: false,
      };
    case RepositoriesTypesOutsides.OUTSIDES_DISCHARGE_DEBTS_GET_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorDischargeDebts: null,
      };
    case RepositoriesTypesOutsides.OUTSIDES_DISCHARGE_DEBTS_CLEAR:
      return {
        ...state,
        dataDischargeDebts: null,
        loadingDischargeDebts: false,
      };

    case RepositoriesTypesOutsides.OUTSIDES_POST_DISCHARGE_DEBTS_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        loadingPayment: true,
      };
    case RepositoriesTypesOutsides.OUTSIDES_POST_DISCHARGE_DEBTS_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loadingPayment: false,
      };
    case RepositoriesTypesOutsides.OUTSIDES_POST_DISCHARGE_DEBTS_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        loadingPayment: false,
        loadingPaymentVoucher: false,
        errorPayment: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
      };
    case RepositoriesTypesOutsides.OUTSIDES_POST_DISCHARGE_DEBTS_PAYMENT_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        loadingPayment: false,
        loadingPaymentVoucher: false,
        errorPayment: null,
      };

    case RepositoriesTypesOutsides.OUTSIDES_POST_DISCHARGE_DEBTS_VOUCHER_METHOD_REQUEST:
      return {
        ...state,
        loadingPaymentVoucher: true,
      };
    case RepositoriesTypesOutsides.OUTSIDES_POST_DISCHARGE_DEBTS_VOUCHER_METHOD_SUCCESS:
      return {
        ...state,
        loadingPaymentVoucher: false,
        dataInvoiceVoucher: { ...action.payload.data },
      };

    case RepositoriesTypesOutsides.OUTSIDES_GET_DATA_UPDATE_METHOD_REQUEST:
      return {
        ...state,
      };
    case RepositoriesTypesOutsides.OUTSIDES_GET_DATA_UPDATE_METHOD_SUCCESS:
      return {
        ...state,
        dataRegistrationUpdate: { ...action.payload.data },
      };
    case RepositoriesTypesOutsides.OUTSIDES_GET_DATA_UPDATE_METHOD_FAILURE:
      return {
        ...state,
        dataRegistrationUpdate: null,
      };

    case RepositoriesTypesOutsides.OUTSIDES_POST_DATA_UPDATE_METHOD_REQUEST:
      return {
        ...state,
        loadingRegistrationUpdate: true,
      };
    case RepositoriesTypesOutsides.OUTSIDES_POST_DATA_UPDATE_METHOD_SUCCESS:
      return {
        ...state,
        dataRegistrationUpdate: null,
        loadingRegistrationUpdate: false,
        successRegistrationUpdate: true,
      };
    case RepositoriesTypesOutsides.OUTSIDES_POST_DATA_UPDATE_METHOD_FAILURE:
      return {
        ...state,
        loadingRegistrationUpdate: false,
        errorRegistrationUpdate: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
      };
    case RepositoriesTypesOutsides.OUTSIDES_POST_DATA_UPDATE_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        successRegistrationUpdate: false,
        fromTypeRegistrationUpdate: '',
        errorRegistrationUpdate: null,
      };

    case RepositoriesTypesOutsides.OUTSIDES_DATA_UPDATE_ACTIVATE_MODAL:
      return {
        ...state,
        modalDataUpdate: true,
        fromTypeRegistrationUpdate: action.payload.fromTypeRegistrationUpdate,
      };
    case RepositoriesTypesOutsides.OUTSIDES_DATA_UPDATE_CLEAR_MODAL:
      return {
        ...state,
        dataRegistrationUpdate: null,
        modalDataUpdate: false,
      };
    case RepositoriesTypesOutsides.OUTSIDES_DOWNLOAD_METHOD_REQUEST:
      return {
        ...state,
        loadingDownload: true,
      };
    case RepositoriesTypesOutsides.OUTSIDES_DOWNLOAD_METHOD_SUCCESS:
      return {
        ...state,
        loadingDownload: false,
      };
    case RepositoriesTypesOutsides.OUTSIDES_DOWNLOAD_METHOD_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loadingDownload: false,
      };
    case RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_CREDENTIALS_BY_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_CREDENTIALS_BY_TOKEN_SUCCESS:
      return {
        ...state,
        dataCredentials: { ...action.payload },
        loading: false,
      };
    case RepositoriesTypesOutsides.OUTSIDES_EASY_INVOICES_CREDENTIALS_BY_TOKEN_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case RepositoriesTypesOutsides.OUTSIDES_GET_SOCIAL_TARIFF_ENROLLMENTS_REQUEST:
      return {
        ...state,
        loadingSocialTariffEnrollments: true,
      };
    case RepositoriesTypesOutsides.OUTSIDES_GET_SOCIAL_TARIFF_ENROLLMENTS_SUCCESS:
      return {
        ...state,
        loadingSocialTariffEnrollments: false,
        dataSocialTariffEnrollments: { ...action.payload },
      };
    case RepositoriesTypesOutsides.OUTSIDES_GET_SOCIAL_TARIFF_ENROLLMENTS_FAILURE:
      return {
        ...state,
        dataSocialTariffEnrollments: null,
        errorSocialTariffEnrollments: action.payload.error,
        loadingSocialTariffEnrollments: false,
      };
    case RepositoriesTypesOutsides.OUTSIDES_GET_SOCIAL_TARIFF_ENROLLMENTS_FAILURE_CLOSE:
      return {
        ...state,
        dataSocialTariffEnrollments: null,
        errorSocialTariffEnrollments: null,
        loadingSocialTariffEnrollments: false,
      };

    case RepositoriesTypesOutsides.OUTSIDES_GET_SOCIAL_TARIFF_VALID_ENROLLMENT_REQUEST:
      return {
        ...state,
        loadingSocialTariffValidEnrollment: true,
      };
    case RepositoriesTypesOutsides.OUTSIDES_GET_SOCIAL_TARIFF_VALID_ENROLLMENT_SUCCESS:
      return {
        ...state,
        loadingSocialTariffValidEnrollment: false,
        dataSocialTariffValidEnrollment: { ...action.payload },
      };
    case RepositoriesTypesOutsides.OUTSIDES_GET_SOCIAL_TARIFF_VALID_ENROLLMENT_FAILURE:
      return {
        ...state,
        warningSocialTariffValidEnrollment: action.payload.warning,
        dataSocialTariffValidEnrollment: null,
        errorSocialTariffValidEnrollment: action.payload.error,
        loadingSocialTariffValidEnrollment: false,
      };
    case RepositoriesTypesOutsides.OUTSIDES_GET_SOCIAL_TARIFF_VALID_ENROLLMENT_FAILURE_CLOSE:
      return {
        ...state,
        warningSocialTariffValidEnrollment: false,
        errorSocialTariffValidEnrollment: null,
        loadingSocialTariffValidEnrollment: false,
        dataSocialTariffValidEnrollment: null,
      };

    case RepositoriesTypesOutsides.OUTSIDES_POST_SOCIAL_TARIFF_REQUEST:
      return {
        ...state,
        loadingSocialTariff: true,
      };
    case RepositoriesTypesOutsides.OUTSIDES_POST_SOCIAL_TARIFF_SUCCESS:
      return {
        ...state,
        dataSocialTariff: { ...action.payload },
        loadingSocialTariff: false,
        successSocialTariff: true,
      };
    case RepositoriesTypesOutsides.OUTSIDES_POST_SOCIAL_TARIFF_FAILURE:
      return {
        ...state,
        errorSocialTariff: action.payload.error,
        loadingSocialTariff: false,
      };
    case RepositoriesTypesOutsides.OUTSIDES_POST_SOCIAL_TARIFF_FAILURE_CLOSE:
      return {
        ...state,
        dataSocialTariff: null,
        errorSocialTariff: null,
        loadingSocialTariff: false,
        successSocialTariff: false,
      };

    case RepositoriesTypesOutsides.OUTSIDES_ROUTE_REPORT_LACK_WATER_ACTIVATE:
      return {
        ...state,
        reportLackWaterRoute: true,
      };
    case RepositoriesTypesOutsides.OUTSIDES_ROUTE_REPORT_LACK_WATER_CLEAR:
      return {
        ...state,
        reportLackWaterRoute: false,
      };

    default:
      return state;
  }
};

export default reducer;
