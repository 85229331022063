import { stepStatusEnum } from '~/enums/customerObligationEnum';

export enum RepositoriesTypesCustomerObligation {
  CUSTOMER_OBLIGATION_GET_LIST_METHOD_REQUEST = '@repositories/CUSTOMER_OBLIGATION_GET_LIST_METHOD_REQUEST',
  CUSTOMER_OBLIGATION_GET_LIST_METHOD_SUCCESS = '@repositories/CUSTOMER_OBLIGATION_GET_LIST_METHOD_SUCCESS',
  CUSTOMER_OBLIGATION_GET_LIST_METHOD_FAILURE = '@repositories/CUSTOMER_OBLIGATION_GET_LIST_METHOD_FAILURE',
  CUSTOMER_OBLIGATION_GET_LIST_METHOD_FAILURE_CLOSE = '@repositories/CUSTOMER_OBLIGATION_GET_LIST_METHOD_FAILURE_CLOSE',

  CUSTOMER_OBLIGATION_GET_ITEM_METHOD_REQUEST = '@repositories/CUSTOMER_OBLIGATION_GET_ITEM_METHOD_REQUEST',
  CUSTOMER_OBLIGATION_GET_ITEM_METHOD_SUCCESS = '@repositories/CUSTOMER_OBLIGATION_GET_ITEM_METHOD_SUCCESS',
  CUSTOMER_OBLIGATION_GET_ITEM_METHOD_FAILURE = '@repositories/CUSTOMER_OBLIGATION_GET_ITEM_METHOD_FAILURE',
  CUSTOMER_OBLIGATION_GET_ITEM_METHOD_CLEAR = '@repositories/CUSTOMER_OBLIGATION_GET_ITEM_METHOD_CLEAR',

  CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_REQUEST = '@repositories/CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_REQUEST',
  CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_SUCCESS = '@repositories/CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_SUCCESS',
  CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_FAILURE = '@repositories/CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_FAILURE',
  CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_FAILURE_CLOSE = '@repositories/CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_FAILURE_CLOSE',
  CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_CLEAR = '@repositories/CUSTOMER_OBLIGATION_GET_VALIDATE_METHOD_CLEAR',

  CUSTOMER_OBLIGATION_POST_CREATE_METHOD_REQUEST = '@repositories/CUSTOMER_OBLIGATION_POST_CREATE_METHOD_REQUEST',
  CUSTOMER_OBLIGATION_POST_CREATE_METHOD_SUCCESS = '@repositories/CUSTOMER_OBLIGATION_POST_CREATE_METHOD_SUCCESS',
  CUSTOMER_OBLIGATION_POST_CREATE_METHOD_FAILURE = '@repositories/CUSTOMER_OBLIGATION_POST_CREATE_METHOD_FAILURE',
  CUSTOMER_OBLIGATION_POST_CREATE_METHOD_FAILURE_CLOSE = '@repositories/CUSTOMER_OBLIGATION_POST_CREATE_METHOD_FAILURE_CLOSE',
  CUSTOMER_OBLIGATION_POST_CREATE_METHOD_CLEAR = '@repositories/CUSTOMER_OBLIGATION_POST_CREATE_METHOD_CLEAR',

  CUSTOMER_OBLIGATION_POST_UPDALOD_FILE_METHOD_REQUEST = '@repositories/CUSTOMER_OBLIGATION_POST_UPDALOD_FILE_METHOD_REQUEST',
  CUSTOMER_OBLIGATION_POST_UPDALOD_FILE_METHOD_SUCCESS = '@repositories/CUSTOMER_OBLIGATION_POST_UPDALOD_FILE_METHOD_SUCCESS',
  CUSTOMER_OBLIGATION_POST_UPDALOD_FILE_METHOD_FAILURE = '@repositories/CUSTOMER_OBLIGATION_POST_UPDALOD_FILE_METHOD_FAILURE',
  CUSTOMER_OBLIGATION_POST_UPDALOD_FILE_METHOD_FAILURE_CLOSE = '@repositories/CUSTOMER_OBLIGATION_POST_UPDALOD_FILE_METHOD_FAILURE_CLOSE',

  CUSTOMER_OBLIGATION_PATCH_UPDALOD_FILE_METHOD_REQUEST = '@repositories/CUSTOMER_OBLIGATION_PATCH_UPDALOD_FILE_METHOD_REQUEST',
  CUSTOMER_OBLIGATION_PATCH_UPDALOD_FILE_METHOD_SUCCESS = '@repositories/CUSTOMER_OBLIGATION_PATCH_UPDALOD_FILE_METHOD_SUCCESS',
  CUSTOMER_OBLIGATION_PATCH_UPDALOD_FILE_METHOD_FAILURE = '@repositories/CUSTOMER_OBLIGATION_PATCH_UPDALOD_FILE_METHOD_FAILURE',
  CUSTOMER_OBLIGATION_PATCH_UPDALOD_FILE_METHOD_FAILURE_CLOSE = '@repositories/CUSTOMER_OBLIGATION_PATCH_UPDALOD_FILE_METHOD_FAILURE_CLOSE',

  CUSTOMER_OBLIGATION_DELETE_UPDALOD_FILE_METHOD_REQUEST = '@repositories/CUSTOMER_OBLIGATION_DELETE_UPDALOD_FILE_METHOD_REQUEST',
  CUSTOMER_OBLIGATION_DELETE_UPDALOD_FILE_METHOD_SUCCESS = '@repositories/CUSTOMER_OBLIGATION_DELETE_UPDALOD_FILE_METHOD_SUCCESS',
  CUSTOMER_OBLIGATION_DELETE_UPDALOD_FILE_METHOD_FAILURE = '@repositories/CUSTOMER_OBLIGATION_DELETE_UPDALOD_FILE_METHOD_FAILURE',
  CUSTOMER_OBLIGATION_DELETE_UPDALOD_FILE_METHOD_FAILURE_CLOSE = '@repositories/CUSTOMER_OBLIGATION_DELETE_UPDALOD_FILE_METHOD_FAILURE_CLOSE',

  CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_REQUEST = '@repositories/CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_REQUEST',
  CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_SUCCESS = '@repositories/CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_SUCCESS',
  CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_FAILURE = '@repositories/CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_FAILURE',
  CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_FAILURE_CLOSE = '@repositories/CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_FAILURE_CLOSE',
  CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_CLEAR = '@repositories/CUSTOMER_OBLIGATION_PATCH_ATTACH_FILES_METHOD_CLEAR',

  CUSTOMER_OBLIGATION_PATCH_STEP_METHOD_REQUEST = '@repositories/CUSTOMER_OBLIGATION_PATCH_STEP_METHOD_REQUEST',
  CUSTOMER_OBLIGATION_PATCH_STEP_METHOD_SUCCESS = '@repositories/CUSTOMER_OBLIGATION_PATCH_STEP_METHOD_SUCCESS',
  CUSTOMER_OBLIGATION_PATCH_STEP_METHOD_FAILURE = '@repositories/CUSTOMER_OBLIGATION_PATCH_STEP_METHOD_FAILURE',
  CUSTOMER_OBLIGATION_PATCH_CLEAR_STEP_METHOD = '@repositories/CUSTOMER_OBLIGATION_PATCH_CLEAR_STEP_METHOD',

  CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_REQUEST = '@repositories/CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_REQUEST',
  CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_SUCCESS = '@repositories/CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_SUCCESS',
  CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_FAILURE = '@repositories/CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_FAILURE',
  CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_FAILURE_CLOSE = '@repositories/CUSTOMER_OBLIGATION_GET_FILE_CODES_METHOD_FAILURE_CLOSE',

  CUSTOMER_OBLIGATION_POST_FILE_CODES_METHOD_REQUEST = '@repositories/CUSTOMER_OBLIGATION_POST_FILE_CODES_METHOD_REQUEST',
  CUSTOMER_OBLIGATION_POST_FILE_CODES_METHOD_SUCCESS = '@repositories/CUSTOMER_OBLIGATION_POST_FILE_CODES_METHOD_SUCCESS',
  CUSTOMER_OBLIGATION_POST_FILE_CODES_METHOD_FAILURE = '@repositories/CUSTOMER_OBLIGATION_POST_FILE_CODES_METHOD_FAILURE',
  CUSTOMER_OBLIGATION_POST_FILE_CODES_METHOD_FAILURE_CLOSE = '@repositories/CUSTOMER_OBLIGATION_POST_FILE_CODES_METHOD_FAILURE_CLOSE',

  CUSTOMER_OBLIGATION_GET_DOWNLOAD_FILE_METHOD_REQUEST = '@repositories/CUSTOMER_OBLIGATION_GET_DOWNLOAD_FILE_METHOD_REQUEST',
  CUSTOMER_OBLIGATION_GET_DOWNLOAD_FILE_METHOD_SUCCESS = '@repositories/CUSTOMER_OBLIGATION_GET_DOWNLOAD_FILE_METHOD_SUCCESS',
  CUSTOMER_OBLIGATION_GET_DOWNLOAD_FILE_METHOD_FAILURE = '@repositories/CUSTOMER_OBLIGATION_GET_DOWNLOAD_FILE_METHOD_FAILURE',
  CUSTOMER_OBLIGATION_GET_DOWNLOAD_FILE_METHOD_FAILURE_CLOSE = '@repositories/CUSTOMER_OBLIGATION_GET_DOWNLOAD_FILE_METHOD_FAILURE_CLOSE',

  CUSTOMER_OBLIGATION_PATCH_PROTOCOL_METHOD_REQUEST = '@repositories/CUSTOMER_OBLIGATION_PATCH_PROTOCOL_REQUEST',
  CUSTOMER_OBLIGATION_PATCH_PROTOCOL_METHOD_SUCCESS = '@repositories/CUSTOMER_OBLIGATION_PATCH_PROTOCOL_SUCCESS',
  CUSTOMER_OBLIGATION_PATCH_PROTOCOL_METHOD_FAILURE = '@repositories/CUSTOMER_OBLIGATION_PATCH_PROTOCOL_FAILURE',
  CUSTOMER_OBLIGATION_PATCH_PROTOCOL_METHOD_CLEAR = '@repositories/CUSTOMER_OBLIGATION_PATCH_PROTOCOL_CLEAR',

  CUSTOMER_OBLIGATION_PATCH_STATUS_STEP_METHOD_REQUEST = '@repositories/CUSTOMER_OBLIGATION_PATCH_STATUS_STEP_REQUEST',
  CUSTOMER_OBLIGATION_PATCH_STATUS_STEP_METHOD_SUCCESS = '@repositories/CUSTOMER_OBLIGATION_PATCH_STATUS_STEP_SUCCESS',
  CUSTOMER_OBLIGATION_PATCH_STATUS_STEP_METHOD_FAILURE = '@repositories/CUSTOMER_OBLIGATION_PATCH_STATUS_STEP_FAILURE',
  CUSTOMER_OBLIGATION_PATCH_STATUS_STEP_METHOD_CLEAR = '@repositories/CUSTOMER_OBLIGATION_PATCH_STATUS_STEP_CLEAR',

  CUSTOMER_OBLIGATION_METHOD_CLEAR = '@repositories/CUSTOMER_OBLIGATION_CLEAR',
}

export interface IResponseAttachment {
  id: string;
  url: string;
  key: string;
  type: string;
  review: boolean;
  step: string;
  review_description: string;
}

export interface IResponseStep {
  name: string;
  number: 1 | 2 | 3 | 4 | 5;
  substep: string;
  status: stepStatusEnum;
}

export interface IApprovedDocuments {
  OCA?: string;
  LICENSING?: string;
  DARM?: string;
  LETTER_SIGNED?: string;
  AIO?: string;
}

export interface IResponseGetListCasesOC {
  id: string;
  caseNumber: string;
  step: IResponseStep;
  realStep: IResponseStep;
  status: string | null;
  operation: string;
  type?: 'oca' | 'oce';
  caseCpae: {
    id: string;
    caseNumber: string;
  };
  details: {
    cityHallCaseNumber: string | null;
    plenaryApprovalDate: string | null;
    startWorkDates: {
      initial?: string;
      final?: string;
      chosen?: string;
    };
    materialInspectionDates: {
      initial?: string;
      final?: string;
      chosen?: string;
    };
  };
  attachments: IResponseAttachment[] | [];
  approvedDocuments: IApprovedDocuments;
}

export interface IRequestGetValidateCustomerObligation {
  case: string;
}

export interface IRequestGetCustomerObligation {
  caseID: string;
}
export interface IResponseGetValidateCustomerObligation {
  id: string;
  case: string;
  owner: string;
  address: string;
  type: string;
  operation: string;
  oca: boolean;
  oce: boolean;
}

export interface IRequestPostCreateCustomerObligation {
  operation: string;
  cpaeCase: string;
  type: 'oca' | 'oce';
}

export interface IResponsePostCreateCustomerObligation {
  id: string;
}

export interface IRequestPostUploadFileCustomerObligation {
  caseIdSales: string;
  type: string;
  step: string;
  filename: string;
  filedata: string;
}

export interface IRequestPatchUploadFileCustomerObligation {
  idFile: string;
  caseIdSales: string;
  attachmentKey: string;
  type: string;
  step: string;
  filename: string;
  filedata: string;
}

export interface IRequestDeleteUploadFileCustomerObligation {
  id: string;
}

export interface IRequestPostAttachFilesCustomerObligation {
  case: string;
  step: string;
}

export interface IRequestPatchProtocolCustomerObligation {
  case: string;
  cityHallCaseNumber?: string;
  plenaryApprovalDate?: string;
  startWorkDate?: string;
  materialInspectionDate?: string;
}

export interface IRequestGetFileCodesCustomerObligation {
  case: string;
  type: string;
  documentType:
    | 'executed_work_descriptive_memorial'
    | 'executed_work_calculation_memorial'
    | 'executed_work_executive_project';
}

export interface IResponseGetFileCodesCustomerObligation {
  id: string;
  caseIdSales: string;
  type: 'oca' | 'oce';
  documentType:
    | 'executed_work_descriptive_memorial'
    | 'executed_work_calculation_memorial'
    | 'executed_work_executive_project';
  code: string;
  createdAt: string;
  updatedAt: string;
}

export interface IFileCodesCustomerObligation {
  executed_work_descriptive_memorial: IResponseGetFileCodesCustomerObligation[] | [];
  executed_work_calculation_memorial: IResponseGetFileCodesCustomerObligation[] | [];
  executed_work_executive_project: IResponseGetFileCodesCustomerObligation[] | [];
}

export interface IRequestPostFileCodesCustomerObligation {
  caseIdSales: string;
  type: 'oca' | 'oce' | string;
  documentType:
    | 'executed_work_descriptive_memorial'
    | 'executed_work_calculation_memorial'
    | 'executed_work_executive_project';
  processNumber: string;
}

export interface IRequestGetDownloadFileCustomerObligation {
  idFile: string;
}

export interface IResponseGetDownloadFileCustomerObligation {
  file: string;
}

export interface IRepositoriesStateCustomerObligation {
  readonly dataList: IResponseGetListCasesOC[] | null;
  readonly dataItem: IResponseGetListCasesOC | null;
  readonly data: IResponseGetValidateCustomerObligation | null;
  readonly idCreate: string;
  readonly fileCodes: IFileCodesCustomerObligation;
  readonly errorList: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loadingList: boolean;
  readonly errorItem: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loadingItem: boolean;
  readonly error: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loading: boolean;
  readonly errorCreate: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loadingCreate: boolean;
  readonly successCreate: boolean;
  readonly errorUploadFile: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loadingUploadFile: boolean;
  readonly errorUploadUpdateFile: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loadingUploadUpdateFile: boolean;
  readonly errorAttachFiles: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loadingAttachFiles: boolean;
  readonly successAttachFiles: boolean;
  readonly errorFileCodes: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loadingFileCodes: boolean;
  readonly errorCreateFileCode: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loadingCreateFileCode: boolean;
  readonly errorAttachFilesCode: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly successAttachFilesCode: boolean;
  readonly errorDownloadFile: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loadingDownloadFile: boolean;
  readonly newActualStep: null | IResponseStep;
  readonly loadingDarmCode: boolean;
  readonly successDarmCode: boolean | null;
}
