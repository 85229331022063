import { Reducer } from 'redux';
import {
  RepositoriesTypesEnrollmentsSubscribes,
  IRepositoriesStateEnrollmentsEnrollmentsSubscribes,
} from './types';

const INITIAL_STATE: IRepositoriesStateEnrollmentsEnrollmentsSubscribes = {
  data: [],
  loading: false,
  error: null,
  dataEnrollment: null,
  loadingEnrollment: false,
  errorEnrollment: null,
  dataPlans: null,
  loadingPlans: false,
  errorPlans: null,
  loadingCreate: false,
  successCreate: false,
  errorCreate: null,
  loadingDelete: false,
  errorDelete: null,
};

const reducer: Reducer<IRepositoriesStateEnrollmentsEnrollmentsSubscribes, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_METHOD_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
      };
    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        error: null,
      };

    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_ENROLLMENT_METHOD_REQUEST:
      return {
        ...state,
        loadingEnrollment: true,
      };
    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_ENROLLMENT_METHOD_SUCCESS:
      return {
        ...state,
        loadingEnrollment: false,
        dataEnrollment: action.payload.data,
      };
    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_ENROLLMENT_METHOD_FAILURE:
      return {
        ...state,
        dataEnrollment: null,
        loadingEnrollment: false,
        errorEnrollment: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
      };
    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_SUBSCRIBE_ENROLLMENT_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorEnrollment: null,
      };

    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_PLANS_METHOD_REQUEST:
      return {
        ...state,
        loadingPlans: true,
      };
    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_PLANS_METHOD_SUCCESS:
      return {
        ...state,
        loadingPlans: false,
        dataPlans: action.payload.data,
      };
    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_PLANS_METHOD_FAILURE:
      return {
        ...state,
        loadingPlans: false,
        errorPlans: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
      };
    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_GET_PLANS_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorPlans: null,
      };

    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_POST_SUBSCRIBE_METHOD_REQUEST:
      return {
        ...state,
        loadingCreate: true,
      };
    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_POST_SUBSCRIBE_METHOD_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        successCreate: true,
      };
    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_POST_SUBSCRIBE_METHOD_FAILURE:
      return {
        ...state,
        loadingCreate: false,
        errorCreate: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
      };
    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_POST_SUBSCRIBE_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorCreate: null,
        successCreate: false,
      };

    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_DELETE_SUBSCRIBE_METHOD_REQUEST:
      return {
        ...state,
        loadingDelete: true,
      };
    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_DELETE_SUBSCRIBE_METHOD_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
      };
    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_DELETE_SUBSCRIBE_METHOD_FAILURE:
      return {
        ...state,
        loadingDelete: false,
        errorDelete: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
      };
    case RepositoriesTypesEnrollmentsSubscribes.ENROLLMENTS_SUBSCRIBES_DELETE_SUBSCRIBE_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorDelete: null,
      };

    default:
      return state;
  }
};

export default reducer;
