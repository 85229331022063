import { Reducer } from 'redux';
import {
  RepositoriesTypesOrderServices,
  IRepositoriesStateOrderServices,
} from './types';

const INITIAL_STATE: IRepositoriesStateOrderServices = {
  data: null,
  dataTypes: null,
  dataAttachments: null,
  dataCreate: null,
  dataValidateLackWater: null,
  dataInputs: null,
  dataCreateLackWater: null,
  dataAddressLackWater: null,
  error: null,
  errorTypes: null,
  errorAttachments: null,
  errorValidateLackWater: null,
  errorInputs: null,
  errorCreateLackWater: null,
  errorAddressLackWater: null,
  errorDownloadLackWater: null,
  loading: false,
  loadingTypes: false,
  loadingAttachments: false,
  loadingValidateLackWater: false,
  loadingInputsLackWater: false,
  loadingCreateLackWater: false,
  loadingAddressLackWater: false,
  loadingDownloadLackWater: false,
  successCreate: false,
  successCreateLackWater: false,
};

const reducer: Reducer<IRepositoriesStateOrderServices, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_LIST_BY_CNP_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
        successCreate: false,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_LIST_BY_CNP_METHOD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: null,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_LIST_BY_CNP_METHOD_FAILURE:
      return {
        ...state,
        data: null,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
        loading: false,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_LIST_BY_CNP_METHOD_CLOSE:
      return {
        ...state,
        error: null,
      };

    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_LIST_TYPE_METHOD_REQUEST:
      return {
        ...state,
        loadingTypes: true,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_LIST_TYPE_METHOD_SUCCESS:
      return {
        ...state,
        dataTypes: action.payload.data,
        loadingTypes: false,
        errorTypes: null,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_LIST_TYPE_METHOD_FAILURE:
      return {
        ...state,
        dataTypes: null,
        errorTypes: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
        loadingTypes: false,
      };

    case RepositoriesTypesOrderServices.ORDER_SERVICES_POST_ATTACHMENTS_METHOD_REQUEST:
      return {
        ...state,
        loadingAttachments: true,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_POST_ATTACHMENTS_METHOD_SUCCESS:
      return {
        ...state,
        dataCreate: action.payload.data,
        successCreate: true,
        loadingAttachments: false,
        errorAttachments: null,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_POST_ATTACHMENTS_METHOD_FAILURE:
      return {
        ...state,
        errorAttachments: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
        loadingAttachments: false,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_POST_ATTACHMENTS_METHOD_CLOSE:
      return {
        ...state,
        errorAttachments: null,
      };

    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_VALIDATE_LACKWATER_METHOD_REQUEST:
      return {
        ...state,
        loadingValidateLackWater: true,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_VALIDATE_LACKWATER_METHOD_SUCCESS:
      return {
        ...state,
        dataValidateLackWater: action.payload.data,
        loadingValidateLackWater: false,
        errorValidateLackWater: null,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_VALIDATE_LACKWATER_METHOD_FAILURE:
      return {
        ...state,
        errorValidateLackWater: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
        loadingValidateLackWater: false,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_VALIDATE_LACKWATER_METHOD_CLOSE:
      return {
        ...state,
        dataValidateLackWater: null,
        errorValidateLackWater: null,
      };

    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_INPUTS_LACKWATER_METHOD_REQUEST:
      return {
        ...state,
        loadingInputsLackWater: true,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_INPUTS_LACKWATER_METHOD_SUCCESS:
      return {
        ...state,
        dataInputs: action.payload.data,
        loadingInputsLackWater: false,
        errorInputs: null,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_INPUTS_LACKWATER_METHOD_FAILURE:
      return {
        ...state,
        errorInputs: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
        loadingInputsLackWater: false,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_INPUTS_LACKWATER_METHOD_CLOSE:
      return {
        ...state,
        errorInputs: null,
      };

    case RepositoriesTypesOrderServices.ORDER_SERVICES_POST_CREATE_LACKWATER_METHOD_REQUEST:
      return {
        ...state,
        loadingCreateLackWater: true,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_POST_CREATE_LACKWATER_METHOD_SUCCESS:
      return {
        ...state,
        dataCreateLackWater: action.payload.data,
        successCreateLackWater: true,
        loadingCreateLackWater: false,
        errorCreateLackWater: null,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_POST_CREATE_LACKWATER_METHOD_FAILURE:
      return {
        ...state,
        errorCreateLackWater: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
        loadingCreateLackWater: false,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_POST_CREATE_LACKWATER_METHOD_CLOSE:
      return {
        ...state,
        errorCreateLackWater: null,
        successCreateLackWater: false,
      };

    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_ADDRESS_LACKWATER_METHOD_REQUEST:
      return {
        ...state,
        loadingAddressLackWater: true,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_ADDRESS_LACKWATER_METHOD_SUCCESS:
      return {
        ...state,
        dataAddressLackWater: action.payload.data,
        loadingAddressLackWater: false,
        errorAddressLackWater: null,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_ADDRESS_LACKWATER_METHOD_FAILURE:
      return {
        ...state,
        errorAddressLackWater: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
        loadingAddressLackWater: false,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_ADDRESS_LACKWATER_METHOD_CLOSE:
      return {
        ...state,
        errorAddressLackWater: null,
        successCreateLackWater: false,
      };

    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_DOWNLOAD_LACKWATER_METHOD_REQUEST:
      return {
        ...state,
        loadingDownloadLackWater: true,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_DOWNLOAD_LACKWATER_METHOD_SUCCESS:
      return {
        ...state,
        loadingDownloadLackWater: false,
        errorDownloadLackWater: null,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_DOWNLOAD_LACKWATER_METHOD_FAILURE:
      return {
        ...state,
        errorDownloadLackWater: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
        loadingDownloadLackWater: false,
      };
    case RepositoriesTypesOrderServices.ORDER_SERVICES_GET_DOWNLOAD_LACKWATER_METHOD_CLOSE:
      return {
        ...state,
        errorDownloadLackWater: null,
        successCreateLackWater: false,
      };

    default:
      return state;
  }
};

export default reducer;
