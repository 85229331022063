import React, { FC } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Button } from '~/components/Button';
import { Margin } from '~/components/Margin';

import { Main } from './styles';

import { IState } from '~/store/modules/types';
import { postErrorCloseEnrollmentsPayment } from '~/store/modules/enrollmentsPayment/actions';
import { getErrorCloseEnrollmentsInvoicesDownload } from '~/store/modules/enrollmentsInvoices/actions';

const ModalError: FC = () => {
  const dispatch = useDispatch();

  const error = useSelector((item: IState) => item.enrollmentsPayment.error);

  const errorMessage = () => {
    switch (error?.message) {
      case 'INVALID_DATA':
        return 'Favor, tente novamente mais tarde.';
      case 'UNAUTHORIZED_TRANSACTION':
        return 'Favor entrar em contato com a operadora do cartão.';
      case 'UNAUTHORIZED_TRANSACTION_TRY_AGAIN_LATER':
        return 'Tente novamente mais tarde.';
      case 'UNAUTHORIZED_TRANSACTION_CONTACT_THE_OPERATOR':
        return 'Favor entrar em contato com a operadora do cartão.';
      default:
        return 'Favor, tente novamente mais tarde.';
    }
  };

  return (
    <Main>
      <p>{errorMessage()}</p>
      <Margin height={39} />
      <Button
        modalType="error"
        text="Entendi"
        type="button"
        onClick={() => {
          dispatch(postErrorCloseEnrollmentsPayment());
          dispatch(getErrorCloseEnrollmentsInvoicesDownload());
        }}
      />
    </Main>
  );
};

export default ModalError;
