import React from 'react';
import moment from 'moment';
import ReactGA from 'react-ga4';

import { Margin } from '~/components/Margin';
import {
  AmountText,
  Border,
  ButtonPayment,
  Container,
  ContainerInfo,
  DueDateText,
  DescriptionText,
  PaymentText,
  ButtonWrap,
  BoxHeader,
} from './styles';
import { IDadosDebts } from '~/store/modules/outsides/types';
import formatter from '~/utils/formatter';
import { statusColor } from '~/utils/invoiceStatus';
import ArrowSVG from '~/assets/Icons/Arrow';

interface IProps {
  data: IDadosDebts;
  setSelectedInvoice: (invoice: IDadosDebts) => void;
  setMethod: (state: 'pixCode' | 'barCode') => void;
}

const Invoice: React.FC<IProps> = ({ data, setSelectedInvoice, setMethod }) => {
  return (
    <Container>
      <Border color={statusColor(data?.status?.label || '', true)} />
      <ContainerInfo>
        <BoxHeader>
          <DescriptionText>{data.description}</DescriptionText>
          <DueDateText color={statusColor(data?.status?.label || '', true)}>
            {data.status.label === 'VENCIDA' ? 'Vencida ' : 'Vence '} em{' '}
            {moment(data?.maturity_date).format('DD/MM/YY')}
          </DueDateText>
        </BoxHeader>
        <Margin height={16} />
        <PaymentText />
        <Margin height={6} />
        <AmountText color={statusColor(data?.status?.label || '', true)}>
          {formatter.formatCurrency(data?.amount)}
        </AmountText>
        <Margin height={6} />
        <ButtonWrap>
          <ButtonPayment
            type="button"
            onClick={() => {
              if (data.pix_code) {
                setMethod('pixCode');
              } else {
                setMethod('barCode');
              }

              ReactGA.event({
                category: 'Pagamento Único',
                action: '[QD][C4][WEB] – Pagamento de uma única dívida',
              });
              setSelectedInvoice(data);
            }}
          >
            <p>Pagar</p>
            <ArrowSVG />
          </ButtonPayment>
        </ButtonWrap>
      </ContainerInfo>
    </Container>
  );
};

export default Invoice;
