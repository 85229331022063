import { Reducer } from 'redux';
import {
  IRepositoriesStateSubscription,
  RepositoriesTypesSubscription,
} from './types';

const INITIAL_STATE: IRepositoriesStateSubscription = {
  data: null,
  successPostSubscription: false,
  error: null,
  errorPostSubscription: null,
  loading: false,
  loadingPostSubscription: false,
};

const reducer: Reducer<IRepositoriesStateSubscription, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesSubscription.SUBSCRIPTION_GET_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RepositoriesTypesSubscription.SUBSCRIPTION_GET_METHOD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case RepositoriesTypesSubscription.SUBSCRIPTION_GET_METHOD_FAILURE:
      return {
        ...state,
        data: null,
        loading: false,
        error: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
      };
    case RepositoriesTypesSubscription.SUBSCRIPTION_POST_METHOD_REQUEST:
      return {
        ...state,
        loadingPostSubscription: true,
      };
    case RepositoriesTypesSubscription.SUBSCRIPTION_POST_METHOD_SUCCESS:
      return {
        ...state,
        successPostSubscription: true,
        loadingPostSubscription: false,
        errorPostSubscription: null,
        data: action.payload,
      };
    case RepositoriesTypesSubscription.SUBSCRIPTION_POST_METHOD_SUCCESS_CLOSE:
      return {
        ...state,
        successPostSubscription: false,
      };
    case RepositoriesTypesSubscription.SUBSCRIPTION_POST_METHOD_FAILURE:
      return {
        ...state,
        data: null,
        loadingPostSubscription: false,
        errorPostSubscription: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
      };
    case RepositoriesTypesSubscription.SUBSCRIPTION_POST_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorPostSubscription: null,
      };
    case RepositoriesTypesSubscription.SUBSCRIPTION_DELETE_METHOD_REQUEST:
      return {
        ...state,
        loadingDeleteSubscription: true,
      };
    case RepositoriesTypesSubscription.SUBSCRIPTION_DELETE_METHOD_SUCCESS:
      return {
        ...state,
        // successDeleteSubscription: true,
        loadingDeleteSubscription: false,
        errorDeleteSubscription: null,
        // data: action.payload,
      };
    case RepositoriesTypesSubscription.SUBSCRIPTION_DELETE_METHOD_FAILURE:
      return {
        ...state,
        // data: null,
        loadingDeleteSubscription: false,
        errorDeleteSubscription: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
      };
    case RepositoriesTypesSubscription.SUBSCRIPTION_DELETE_METHOD_FAILURE_CLOSE:
      return {
        ...state,
        errorDeleteSubscription: null,
      };
    default:
      return state;
  }
};

export default reducer;
