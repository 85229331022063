import { Color } from '~/styles/colors';

export const statusColor = (statusLabel: string, v3?: boolean): string => {
  switch (statusLabel) {
    case 'QUITADA':
      return Color.green;
    case 'PENDENTE':
      return Color.yellow;
    case 'PROCESSANDO':
      return Color.yellow;

    default:
      return v3 ? Color.red : Color.redAscendet;
  }
};
