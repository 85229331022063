import React, { FC, ReactNode } from 'react';

import { Main } from './styles';
import { Color } from '~/styles';

interface IProps {
  title?: string;
  label: string;
  children?: ReactNode;
}

const SuccessBox: FC<IProps> = ({ title, label, children }) => {
  return (
    <Main>
      <div>
        <svg
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="44" height="44" rx="8" fill={Color.green} />
          <path
            d="M17 22L20.75 25.75L27 19.5M32 22C32 27.5228 27.5228 32 22 32C16.4772 32 12 27.5228 12 22C12 16.4772 16.4772 12 22 12C25.158 12 27.9741 13.4638 29.8067 15.75C31.1791 17.462 32 19.6351 32 22Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div>
        <p>{title || 'Sucesso'}</p>
        <p>
          {label}
          {children && children}
        </p>
      </div>
    </Main>
  );
};

export default SuccessBox;
