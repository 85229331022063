import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// import configsAppJsonImport from '~/configs/app.config';

import formatter from '~/utils/formatter';
import { checkCnp } from '~/utils/validation';

import { Button } from '~/common/Button';
import { Card } from '~/common/Card';
import { Input } from '~/common/InputV3';
import { InputChecked } from '~/common/InputChecked';
import { Margin } from '~/components/Margin';
import { RouteName } from '../../routes/Routes.name';
import { IState } from '~/store/modules/types';

import {
  Main,
  TextRegisterStyled,
  ContainerForgotPassword,
  ContainerForm,
} from './styles';

import {
  getEnrollmentsAuthCloseRequest,
  getEnrollmentsAuthRequest,
} from '~/store/modules/enrollmentsAuth/actions';
import {
  getEnrollmentsUsersRequestClear,
  updateEnrollmentsCreateClientRequest,
} from '~/store/modules/enrollmentsUsers/actions';
import { saveLoginCredentials } from '~/store/modules/enrollmentsLoginCredentials/actions';
import { MessageBox } from '~/common';
import { Dialog } from '~/components/Dialog';
import ModalPasswordExpiration from './ModalPasswordExpiration';
import { Recaptcha } from '~/components/Recaptcha';
import { showRecaptchaV2 } from '~/utils/showRecaptcha';

// const configsAppJson = configsAppJsonImport as any;

const LoginVersionTwo: React.FC = () => {
  const dispatch = useDispatch();

  const cnpInputRef = useRef<HTMLInputElement>(null);

  const loginValues = useSelector(
    (item: IState) => item.enrollmentsLoginCredentials.data,
  );

  const loading = useSelector((item: IState) => item.enrollmentsAuth.loading);
  const error = useSelector((item: IState) => item.enrollmentsAuth.error);

  const [cnp, setCnp] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [rememberChecked, setRememberChecked] = useState<boolean>(
    !!(
      loginValues &&
      loginValues.cnp &&
      loginValues.password &&
      loginValues.remember
    ),
  );
  const [cnpError, setCnpError] = useState<string>('');
  const [isNewClient, setIsNewClient] = useState<boolean>(false);
  const [openPasswordExpiration, setOpenPasswordExpiration] =
    useState<boolean>(false);
  const [recaptchaTokenV3, setRecaptchaTokenV3] = useState<string | null>(null);
  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState<string | null>(null);
  const showRecaptcha = showRecaptchaV2(error || undefined);

  const hasCnpRoute = cnpError === 'Usuário ou senha inválidos';
  const state = { cnp: hasCnpRoute ? cnp : '' };

  useEffect(() => {
    if (error) {
      if (error?.message === 'USER_NOT_REGISTERED') {
        setIsNewClient(true);
      } else if (error && error.message === 'USER_NOT_FOUND_IN_PARTNES') {
        setIsNewClient(true);
      } else if (error && error.message === 'EXPIRED_PASSWORD') {
        setOpenPasswordExpiration(true);
      } else {
        setCnpError('Usuário ou senha inválidos');
      }
    } else {
      setCnpError('');
    }
  }, [error]);

  useEffect(() => {
    dispatch(getEnrollmentsAuthCloseRequest());
    setIsNewClient(false);
    setOpenPasswordExpiration(false);
    dispatch(updateEnrollmentsCreateClientRequest());
    setCnp(
      (loginValues &&
        loginValues.remember &&
        loginValues.cnp &&
        loginValues.password &&
        loginValues.cnp) ||
        '',
    );
    setPassword(
      (loginValues &&
        loginValues.remember &&
        loginValues.cnp &&
        loginValues.password &&
        loginValues.password) ||
        '',
    );
  }, []);

  useEffect(() => {
    const cnpIsvalid = checkCnp(cnp);

    if (cnp && !cnpIsvalid) {
      setCnpError('CPF ou CNPJ inválido');
    } else {
      setCnpError('');
    }
  }, [cnp]);

  useEffect(() => {
    setCnpError('');
  }, [password]);

  useEffect(() => {
    if (!cnp || !password || cnpError) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [cnp, password, cnpError]);

  const validateCnp = () => {
    if (!cnp) return setCnpError('');

    const cnpIsvalid = checkCnp(cnp);

    if (!cnpIsvalid) {
      return setCnpError('CPF ou CNPJ inválido');
    }

    return setCnpError('');
  };

  return (
    <Main>
      {openPasswordExpiration && (
        <Dialog
          open={openPasswordExpiration}
          onClose={() => setOpenPasswordExpiration(false)}
          fullScreen
        >
          <ModalPasswordExpiration cnp={cnp} />
        </Dialog>
      )}
      <ContainerForm>
        <Card
          title="Login do cliente"
          label="Acesse nossos serviços de forma fácil e tenha uma experiência personalizada!"
          iconType="login"
        >
          <Margin height={24} />
          {isNewClient && (
            <>
              <MessageBox title="Atenção!" variant="warning" icon="warning">
                <Margin height={4} />
                Olá cliente! Identificamos que é o seu primeiro acesso, faça{' '}
                <Link to={RouteName.REGISTERPROFILE}>seu cadastro</Link>!
              </MessageBox>
              <Margin height={18} />
            </>
          )}
          <Input
            value={cnp}
            onChange={(ev) => setCnp(formatter.cpfCnpjMask(ev.target.value))}
            onBlur={validateCnp}
            label="CPF ou CNPJ"
            name="cnp"
            refEl={cnpInputRef}
            error={cnpError === 'USER_NOT_REGISTERED' ? false : !!cnpError}
            errorText={cnpError === 'USER_NOT_REGISTERED' ? '' : cnpError}
          />
          <Margin height={12} />
          <Input
            value={password}
            onChange={(ev) => setPassword(ev.target.value)}
            label="Senha"
            name="password"
            type={!passwordVisible ? 'password' : 'text'}
            iconPassword
            passwordVisible={passwordVisible}
            setPasswordVisible={setPasswordVisible}
          />
          <Margin height={24} />
          <ContainerForgotPassword>
            <InputChecked
              checked={rememberChecked}
              onChange={(ev) => setRememberChecked(ev.target.checked)}
              label="Lembrar-me"
            />
            <Link
              to={{
                pathname: RouteName.FORGOTPASSWORD,
                state,
              }}
            >
              Esqueci minha senha
            </Link>
          </ContainerForgotPassword>
          <Margin height={21} />
          <Recaptcha
            setRecaptchaTokenV3={setRecaptchaTokenV3}
            setRecaptchaTokenV2={setRecaptchaTokenV2}
            showRecaptchaV2={showRecaptcha}
            onClose={() => {
              dispatch(getEnrollmentsAuthCloseRequest());
            }}
          />
          <Button
            onClick={() => {
              dispatch(getEnrollmentsUsersRequestClear());
              dispatch(
                getEnrollmentsAuthRequest({
                  cnp,
                  password,
                  recaptchaTokenV3: recaptchaTokenV3 || '',
                  recaptchaTokenV2: recaptchaTokenV2 || '',
                }),
              );
              dispatch(
                saveLoginCredentials({ remember: rememberChecked, cnp, password }),
              );
            }}
            loading={loading}
            disabled={disabled}
            id="login-button"
          >
            Continuar
          </Button>
          <Margin height={32} />
          <TextRegisterStyled>
            Primeiro acesso? &nbsp;
            <Link
              to={{
                pathname: RouteName.REGISTERPROFILE,
                state,
              }}
            >
              Cadastre-se aqui
            </Link>
          </TextRegisterStyled>
          <Margin height={40} />
        </Card>
      </ContainerForm>
    </Main>
  );
};

export default LoginVersionTwo;
