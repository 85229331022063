import { Reducer } from 'redux';
import {
  IRepositoriesStatePropertyRegistration,
  RepositoriesTypesPropertyRegistration,
} from './types';

const INITIAL_STATE: IRepositoriesStatePropertyRegistration = {
  dataList: null,
  loadingList: false,
  erroList: null,
  data: null,
  erroData: null,
  loadingData: false,
  dataValidate: null,
  loadingValidate: false,
  erroValidate: null,
  loadingUploadFile: false,
  errorUploadFile: null,
  dataItem: null,
  loadingItem: false,
  loadingPatch: false,
  errorPatch: null,
  fileCodes: {
    CARRIED_OUT_DEPLETION_WORK_DESCRIPTIVE_MEMORIAL: [],
    CARRIED_OUT_DEPLETION_WORK_CALCULATION_MEMORIAL: [],
    DEFINITIVE_INTERNAL_EXHAUSTION_PROJECT: [],

    CARRIED_OUT_SUPPLY_WORK_CALCULATION_MEMORIAL: [],
    CARRIED_OUT_SUPPLY_WORK_MEMORIAL_DESCRIPTIVE: [],
    FINAL_INTERNAL_SUPPLY_PROJECT: [],
  },
  errorFileCodes: null,
  loadingFileCodes: false,
  errorDownloadFile: null,
  loadingDownloadFile: false,
};

const reducer: Reducer<IRepositoriesStatePropertyRegistration, any> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_LIST_METHOD_REQUEST:
      return {
        ...state,
        loadingList: true,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_LIST_METHOD_SUCCESS:
      return {
        ...state,
        dataList: action.payload.data,
        loadingList: false,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_LIST_METHOD_FAILURE:
      return {
        ...state,
        loadingList: false,
      };

    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_ITEM_METHOD_REQUEST:
      return {
        ...state,
        loadingItem: true,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_ITEM_METHOD_SUCCESS:
      return {
        ...state,
        dataItem: {
          ...action.payload.data,
          realStep: action.payload.data.step,
        },
        loadingItem: false,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_ITEM_METHOD_FAILURE:
      return {
        ...state,
        loadingItem: false,
      };

    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_VALIDATE_METHOD_REQUEST:
      return {
        ...state,
        erroValidate: null,
        erroData: null,
        loadingValidate: true,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_VALIDATE_METHOD_SUCCESS:
      return {
        ...state,
        dataValidate: action.payload.data,
        loadingValidate: false,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_VALIDATE_METHOD_FAILURE:
      return {
        ...state,
        erroValidate: action.payload.error,
        loadingValidate: false,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_VALIDATE_METHOD_CLEAR:
      return {
        ...state,
        dataValidate: null,
        loadingValidate: false,
        erroValidate: null,
      };

    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_CREATE_METHOD_REQUEST:
      return {
        ...state,
        loadingData: true,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_CREATE_METHOD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        dataItem: {
          ...action.payload.dataItem,
          realStep: action.payload.dataItem.step,
        },
        loadingData: false,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_CREATE_METHOD_FAILURE:
      return {
        ...state,
        erroData: action.payload.error,
        loadingData: false,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_CREATE_METHOD_CLEAR:
      return {
        ...state,
        data: null,
        erroData: null,
        loadingData: false,
      };

    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_POST_UPLOAD_FILE_METHOD_REQUEST:
      return {
        ...state,
        loadingUploadFile: true,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_POST_UPLOAD_FILE_METHOD_SUCCESS:
      return {
        ...state,
        loadingUploadFile: false,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_POST_UPLOAD_FILE_METHOD_FAILURE:
      return {
        ...state,
        errorUploadFile: action.payload.error,
        loadingUploadFile: false,
      };

    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_INFORMATION_METHOD_REQUEST:
      return {
        ...state,
        loadingPatch: true,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_INFORMATION_METHOD_SUCCESS:
      return {
        ...state,
        loadingPatch: false,
        dataItem: {
          ...state.dataItem,
          step: action.payload,
          realStep: action.payload,
        },
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_INFORMATION_METHOD_FAILURE:
      return {
        ...state,
        errorPatch: action.payload.error,
        loadingPatch: false,
      };

    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_ATTACHMENT_METHOD_REQUEST:
      return {
        ...state,
        loadingItem: true,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_ATTACHMENT_METHOD_SUCCESS:
      return {
        ...state,
        dataItem: {
          ...state.dataItem,
          step: action.payload,
          realStep: action.payload,
        },
        loadingItem: false,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_ATTACHMENT_METHOD_FAILURE:
      return {
        ...state,
        erroData: action.payload.error,
        loadingItem: true,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_FILE_CODES_METHOD_REQUEST:
      return {
        ...state,
        loadingFileCodes: true,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_FILE_CODES_METHOD_SUCCESS:
      return {
        ...state,
        fileCodes: {
          ...state.fileCodes,
          [action.payload.documentType]: action.payload.data,
        },
        loadingFileCodes: false,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_FILE_CODES_METHOD_FAILURE:
      return {
        ...state,
        errorFileCodes: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
        loadingFileCodes: false,
      };

    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_DOWNLOAD_FILE_METHOD_REQUEST:
      return {
        ...state,
        loadingDownloadFile: true,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_DOWNLOAD_FILE_METHOD_SUCCESS:
      return {
        ...state,
        loadingDownloadFile: false,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_GET_DOWNLOAD_FILE_METHOD_FAILURE:
      return {
        ...state,
        errorDownloadFile: {
          status: action.payload.status,
          message: action.payload.message,
          state: true,
        },
        loadingDownloadFile: false,
      };

    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_PROTOCOL_METHOD_REQUEST:
      return {
        ...state,
        loadingPatch: true,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_PROTOCOL_METHOD_SUCCESS:
      return {
        ...state,
        loadingPatch: false,
        dataItem: {
          ...state.dataItem,
          step: action.payload.step,
          realStep: action.payload.step,
        },
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_PROTOCOL_METHOD_FAILURE:
      return {
        ...state,
        errorPatch: action.payload.error,
        loadingPatch: false,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_STEP_LOCAL_METHOD_REQUEST:
      return {
        ...state,
        dataItem: {
          ...state.dataItem,
          step: action.payload,
        },
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_STEP_METHOD_REQUEST:
      return {
        ...state,
        loadingPatch: true,
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_STEP_METHOD_SUCCESS:
      return {
        ...state,
        loadingPatch: false,
        dataItem: {
          ...state.dataItem,
          step: action.payload,
          realStep: action.payload,
        },
      };
    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_PATCH_STEP_METHOD_FAILURE:
      return {
        ...state,
        errorPatch: action.payload.error,
        loadingPatch: false,
      };

    case RepositoriesTypesPropertyRegistration.PROPERTY_REGISTRATION_METHOD_CLEAR:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
