import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 270px;
  height: 461px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  > p {
    margin-top: 56px;
  }
`;

export const Text = styled.p`
  font-weight: 300;
  font-size: 14px;
  color: #000000;
  text-align: center;
  line-height: 122.5%;

  b {
    font-size: 18px;
    font-weight: 400;
  }
`;

export const ContainerDates = styled.div`
  width: 100%;
  max-width: 328px;
  display: flex;
`;
