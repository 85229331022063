import styled from 'styled-components';
import { Breakpoints, Color } from '~/styles';

interface IPropsContainerNotification {
  is_read?: boolean;
}

export const Container = styled.div`
  min-width: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 31px 17px 0 17px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    padding: 31px 29px;
    min-width: calc(488px - 58px);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h1 {
    color: ${Color.blueIntense};
    font-size: 28px;
    font-weight: 400;
  }

  span {
    color: ${Color.blueSeconde};
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid ${Color.grayLightSeconde};
    position: relative;
    height: 24px;
    display: flex;
    align-items: center;

    #border {
      width: 110px;
      height: 2px;
      background: ${Color.blueSeconde};
      position: absolute;
      bottom: -1px;
      left: 0;
      margin: 0;
      z-index: 9999;
    }
  }
`;

export const ContainerNotification = styled.div`
  width: 100%;
  height: auto;
  max-height: 260px;

  @media (min-width: ${Breakpoints.tablet.min}px) {
    max-height: 390px;
  }
`;

export const CardNotification = styled.button<IPropsContainerNotification>`
  width: 100%;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #02505b;
  border: none;
  background: none;
  border-bottom: 1px solid ${Color.grayLightSeconde};
  cursor: pointer;
  background: ${({ is_read }) =>
    is_read ? Color.white : 'rgba(52, 187, 218, 0.15)'};
  padding: 0 13px;

  div {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 18px;

    p {
      color: ${({ is_read }) =>
        is_read ? Color.grayLightSeconde : Color.blueSeconde};
      font-size: 10px;
      font-weight: 500;

      :last-of-type {
        color: ${Color.blueIntense};
        font-size: 12px;
        text-align: left;
      }
    }
  }

  p {
    color: ${Color.grayLightSeconde};
    font-size: 10px;
    font-weight: 400;
    text-align: right;
  }
`;

export const ContainerEmptyMessage = styled.div`
  margin: 28px auto 14px auto;

  svg {
    width: 41px;
    height: 51px;
  }

  p {
    color: ${Color.grayLightSeconde};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
`;
