export enum RepositoriesTypesAutomaticDebits {
  AUTOMATIC_DEBITS_BANKS_GET_METHOD_REQUEST = '@repositories/AUTOMATIC_DEBITS_BANKS_GET_METHOD_REQUEST',
  AUTOMATIC_DEBITS_BANKS_GET_METHOD_SUCCESS = '@repositories/AUTOMATIC_DEBITS_BANKS_GET_METHOD_SUCCESS',
  AUTOMATIC_DEBITS_BANKS_GET_METHOD_FAILURE = '@repositories/AUTOMATIC_DEBITS_BANKS_GET_METHOD_FAILURE',

  AUTOMATIC_DEBITS_GET_METHOD_REQUEST = '@repositories/AUTOMATIC_DEBITS_GET_METHOD_REQUEST',
  AUTOMATIC_DEBITS_GET_METHOD_SUCCESS = '@repositories/AUTOMATIC_DEBITS_GET_METHOD_SUCCESS',
  AUTOMATIC_DEBITS_GET_METHOD_FAILURE = '@repositories/AUTOMATIC_DEBITS_GET_METHOD_FAILURE',
  AUTOMATIC_DEBITS_GET_METHOD_FAILURE_CLOSE = '@repositories/AUTOMATIC_DEBITS_GET_METHOD_FAILURE_CLOSE',

  AUTOMATIC_DEBITS_POST_METHOD_REQUEST = '@repositories/AUTOMATIC_DEBITS_POST_METHOD_REQUEST',
  AUTOMATIC_DEBITS_POST_METHOD_SUCCESS = '@repositories/AUTOMATIC_DEBITS_POST_METHOD_SUCCESS',
  AUTOMATIC_DEBITS_POST_METHOD_SUCCESS_CLOSE = '@repositories/AUTOMATIC_DEBITS_POST_METHOD_SUCCESS_CLOSE',
  AUTOMATIC_DEBITS_POST_METHOD_FAILURE = '@repositories/AUTOMATIC_DEBITS_POST_METHOD_FAILURE',
  AUTOMATIC_DEBITS_POST_METHOD_FAILURE_CLOSE = '@repositories/AUTOMATIC_DEBITS_POST_METHOD_FAILURE_CLOSE',

  AUTOMATIC_DEBITS_DELETE_METHOD_REQUEST = '@repositories/AUTOMATIC_DEBITS_DELETE_METHOD_REQUEST',
  AUTOMATIC_DEBITS_DELETE_METHOD_SUCCESS = '@repositories/AUTOMATIC_DEBITS_DELETE_METHOD_SUCCESS',
  AUTOMATIC_DEBITS_DELETE_METHOD_SUCCESS_CLOSE = '@repositories/AUTOMATIC_DEBITS_DELETE_METHOD_SUCCESS_CLOSE',
  AUTOMATIC_DEBITS_DELETE_METHOD_FAILURE = '@repositories/AUTOMATIC_DEBITS_DELETE_METHOD_FAILURE',
  AUTOMATIC_DEBITS_DELETE_METHOD_FAILURE_CLOSE = '@repositories/AUTOMATIC_DEBITS_DELETE_METHOD_FAILURE_CLOSE',

  AUTOMATIC_DEBITS_ACTIVATE_MODAL = '@repositories/AUTOMATIC_DEBITS_ACTIVATE_MODAL',
  AUTOMATIC_DEBITS_CLEAR_MODAL = '@repositories/AUTOMATIC_DEBITS_CLEAR_MODAL',
}

export interface IResponseGetBanksAutomaticDebits {
  id: number;
  name: string;
  cod_bank: number;
}

export interface SituationAccount {
  situation:
    | 'Cadastrado'
    | 'Aguardando Confirmação'
    | 'Confirmado'
    | 'Recusado'
    | 'Excluído'
    | 'Enviado Exclusão';
}

export interface IResponseGetAutomaticDebits {
  enrollment_id: number;
  bank: string;
  agency_number: number;
  account_number: number;
  situation:
    | 'Cadastrado'
    | 'Aguardando Confirmação'
    | 'Confirmado'
    | 'Recusado'
    | 'Excluído'
    | 'Enviado Exclusão';
  inclusion_date: string;
  deletion_date: string;
}

export interface IRequestCreateAutomaticDebits {
  account_number: number;
  agency_number: number;
  bank_id: number;
}

export interface ICheckModalAutomaticDebits {
  invoice_id: number;
}

export interface IRepositoriesStateAutomaticDebits {
  readonly data: IResponseGetAutomaticDebits | null;
  readonly dataBanks: any;
  readonly successCreate: boolean;
  readonly successDelete: boolean;
  readonly error: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorCreate: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly errorDelete: {
    message?: string;
    status: number;
    state: boolean;
  } | null;
  readonly loading: boolean;
  readonly loadingCreate: boolean;
  readonly loadingDelete: boolean;
  readonly checkModal: ICheckModalAutomaticDebits | null;
}
